import { Component, Input, OnInit } from '@angular/core'
import { format, parse } from 'date-fns'

@Component({
  selector: 'ngx-event-times',
  templateUrl: './event-times.component.html',
  styleUrls: ['./event-times.component.scss']
})
export class EventTimesComponent implements OnInit {
  @Input() startDate: string
  @Input() endDate: string
  times: string = ''

  constructor() {}

  ngOnInit(): void {
    this.formatDates(this.startDate, this.endDate)
  }

  formatDates(startDate: string, endDate: string) {
    let start = parse(startDate, 'dd MMM yyyy', new Date())
    let formattedStart = format(start, 'dd MMM').toUpperCase()

    let end = parse(endDate, 'dd MMM yyyy', new Date())
    let formattedEnd = format(end, 'dd MMM').toUpperCase()

    this.times = formattedStart + ' / ' + formattedEnd
  }
}
