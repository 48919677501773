import { AuthService } from '@auth/services/auth.service'
import { Component, OnInit, Input, OnDestroy, TemplateRef } from '@angular/core'
import { WorkspaceService } from '@workspaces/services/workspace.service'
import { forkJoin, Observable, Subject } from 'rxjs'
import { NbDialogRef, NbDialogService } from '@nebular/theme'
import { ToastrService } from '@app/services/toastr.service'
import { Workspace } from '@workspaces/interfaces/workspace.interface'
import { FormArray, FormGroup } from '@angular/forms'
import { Assignee, AssigneeFactory } from '@workspaces/models/assignee.interface'
import { InvoicePlansComponent } from '@auth/components/invoice-plans/invoice-plans.component'
import { takeUntil } from 'rxjs/operators'
import { UserRoles } from '@auth/enums/user-roles.enum'
import { WorkspaceRoleService } from '@app/modules/auth/services/workspace-role.service'

/**
 * Component that handles workspace members
 */
@Component({
  selector: 'ngx-assign-organizer',
  templateUrl: './assign-organizer.component.html',
  styleUrls: ['./assign-organizer.component.scss']
})
export class AssignOrganizerComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }

  /**
   * Workspace model
   */
  @Input()
  workspace: Workspace

  /**
   * Organizers form group
   */
  form: FormGroup

  /**
   * List of workspace users
   */
  workspaceUsers

  /**
   * List of workspace users ids
   */
  workspaceUsersId

  /**
   * Should hide the user
   */
  hideUser = false

  /**
   * Performing async operation?
   */
  processing = false

  /**
   * Shadowed list of original users
   */
  _sourceUsers: Assignee[] = []

  /**
   * Represents an Assignee
   */
  specificUser

  /**
   * List of available roles
   */
  _availableRoles: any

  /**
   * Represents an Assignee index
   */
  specificUserIndex

  _currentUserRole: string
  /**
   * Creates the component an injects it's dependencies
   * @param ref NbDialogRef
   * @param dialogService NbDialogService
   * @param toastr ToastrService
   * @param workspaceService WorkspaceService
   * @param auth AuthService
   * @param workspaceRole WorkspaceRoleService
   */
  constructor(
    protected ref: NbDialogRef<AssignOrganizerComponent>,
    private dialogService: NbDialogService,
    private toastr: ToastrService,
    private workspaceService: WorkspaceService,
    public auth: AuthService,
    private workspaceRole: WorkspaceRoleService
  ) {}

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.processing = true
    this.workspaceService
      .getWorkspaceUsers(this.workspace.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((users) => {
        this._currentUserRole = this.workspaceRole.getUserRoleFromWorkspaceUsers(users)
        this._sourceUsers = users
        this.form = new FormGroup(
          {
            users: AssigneeFactory.generateAssigneesArray(users, this.workspace.id)
          },
          [AssigneeFactory.duplicateValidator]
        )
        this._availableRoles = UserRoles
        this.processing = false
      })
  }

  /**
   * Gets a list of users from the form group as a FormArray
   */
  get users(): FormArray {
    return this.form.get('users') as FormArray
  }

  /**
   * Opens a dialog with the user in question
   * @param dialog TemplateRef
   * @param event MouseEvent
   * @param user Assignee
   * @param index number
   */
  open(dialog: TemplateRef<any>, event, user, index) {
    event.stopPropagation()
    if (!this.canDeleteUser(user)) return
    this.specificUser = user.value.email
    this.specificUserIndex = index
    this.dialogService.open(dialog)
  }

  /**
   * Adds an empty user form group or triggers upgrade mdoal
   */
  addUser() {
    if (this.auth.user.is_admin || this.auth.plan === 'Pro') {
      this.users.push(AssigneeFactory.generateAssigneeForm(undefined, this.workspace.id))
      return
    }
    this.dialogService.open(InvoicePlansComponent, { context: { createWorkspace: false, assignUser: true } })
  }

  /**
   * Deletes a specific user
   */
  deleteUser() {
    this.users.removeAt(this.specificUserIndex)
    if (this._sourceUsers[this.specificUserIndex]) this.workspaceService.deleteUser(this.workspace.id, this._sourceUsers[this.specificUserIndex].user_id.toString()).subscribe()
  }

  /**
   * Saves the workspace users changes by performing several operations
   */
  save() {
    if (this.form.invalid) return
    this.processing = true
    const calls: Observable<Object>[] = []
    // Get newly assigned users and admins
    this.users.getRawValue().forEach((user) => {
      const sourceUser = this._sourceUsers.find((o) => o.user.id === user.user_id)
      if (!sourceUser) {
        calls.push(this.workspaceService.assignUser({ email: user.email, is_owner: false, workspace_id: this.workspace.id, role: user.role }))
        return
      }
      if (sourceUser && sourceUser.role !== user.role) {
        calls.push(this.workspaceService.updateRole({ user_id: sourceUser.user.id, workspace_id: this.workspace.id, is_owner: user.is_owner, role: user.role }))
      }
    })
    if (!calls.length) {
      this.processing = false
      this.close()
    }
    forkJoin(calls)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this.close()
          this.toastr.success('Workspace users updated!', '')
          this.processing = false
        },
        () => {
          this.toastr.error('We faced an issue while saving your changes!', '')
          this.processing = false
        }
      )
  }

  /**
   * Closes the dialog
   */
  close() {
    this.ref.close()
  }

  /**
   * Checks if the user is the logged in user
   */
  isMe(id: number) {
    return this.auth.user.id === id
  }

  /**
   * Expose the list of available roles
   */
  get availableRoles() {
    return Object.entries(this._availableRoles).map(([key, value]) => value)
  }

  /**
   * Checks if the user can add more users
   * @returns boolean
   */
  canAddUsers(): boolean {
    return this.workspaceRole.canAddUsers(this._currentUserRole)
  }

  /**
   * List of roles that the user can assign to other users
   * @returns string[]
   */
  rolesList(): string[] {
    return this.workspaceRole.rolesListToAdd(this._currentUserRole)
  }

  /**
   * Checks if the authorized user can delete the specified user
   * @returns boolean
   */
  canDeleteUser(user): boolean {
    return this.auth.user?.is_admin
      ? true
      : !this.isMe(user.value.user_id) && this.workspaceRole.rolesListToDelete(this._currentUserRole).includes(user.value.role) && !user.value.is_owner
  }

  canAddUser(user): boolean {
    return this.auth.user?.is_admin
      ? true
      : !this.isMe(user.value.user_id) && this.workspaceRole.rolesListToAdd(this._currentUserRole).includes(user.value.role) && !user.value.is_owner && !user.value.user_id
  }

  canChangeUser(user): boolean {
    return this.auth.user?.is_admin
      ? true
      : !this.isMe(user.value.user_id) && this.workspaceRole.rolesListToChange(this._currentUserRole).includes(user.value.role) && !user.value.is_owner && !!user.value.user_id
  }
}
