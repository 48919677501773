import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '@auth/services/auth.service'

/**
 * Allows routes based on whether the user is a super admin of the system or not
 */
@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  /**
   * Returns whether the user is allowed to access the route
   * @param next Next route
   * @param state current route state
   * @returns boolean
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService?.user?.is_admin) {
      return true
    } else {
      this.router.navigate(['/'])
    }
  }
  /**
   * Creates the guard and injects it's dependencies
   * @param authService AuthService
   */
  constructor(private authService: AuthService, private router: Router) {}
}
