import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CountryComponent } from './components/country/country.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { PhoneComponent } from './components/phone/phone.component'
import { FullnameComponent } from './components/fullname/fullname.component'
import { InputComponent } from './components/input/input.component'

@NgModule({
  imports: [CommonModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule],
  exports: [CountryComponent, PhoneComponent, FullnameComponent, InputComponent],
  declarations: [CountryComponent, PhoneComponent, FullnameComponent, InputComponent]
})
export class FormsModule {}
