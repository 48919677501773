import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { Observable } from 'rxjs'
import { FileInfo } from '@app/modules/shared/interfaces/media-library.type'

@Injectable({
  providedIn: 'root'
})
export class MediaLibraryService {
  constructor(private http: HttpClient) {}

  uploadMapImage(workspaceSlug: string, eventSlug: string, { map_latitude, map_longitude, map_size_width, map_size_height, map_zoom_level }) {
    return this.http.post(`${environment.mediaLibrary}map`, {
      workspace_slug: workspaceSlug,
      event_slug: eventSlug,
      map_latitude: map_latitude,
      map_longitude: map_longitude,
      map_size_width: map_size_width,
      map_size_height: map_size_height,
      map_zoom_level: map_zoom_level,
      pin_latitude: map_latitude,
      pin_longitude: map_longitude
    })
  }

  uploadtoWorkspace(workspaceSlug: string, fileUrl: string): Observable<FileInfo> {
    return this.http.post<FileInfo>(`${environment.mediaLibrary}upload`, {
      workspace: workspaceSlug,
      url: fileUrl
    })
  }
}
