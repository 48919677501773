import { CreateWorkspace } from '@workspaces/interfaces/create-workspace.interface'
import { environment } from '@environments/environment'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { tap, takeUntil } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AuthService } from '@auth/services/auth.service'

/**
 * Handles creating/ updating/ listing workspaces as well as assigning users
 */
@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  /** Creates & inject service dependencies */
  constructor(private http: HttpClient, private auth: AuthService) {}

  /**
   * Determines if show no more workspaces should be shown or not
   */
  public stopRetrieving$: Subject<boolean> = new BehaviorSubject<boolean>(false)

  public spliceWorkspace$ = new Subject()
  /**
   * Creates a workspace
   * @param value CreateWorkspaace interface
   * @returns Observable
   */
  createWorkspace(value: CreateWorkspace): Observable<any> {
    return this.http.post(`${environment.planningApi}workspaces`, value)
  }

  /**
   * Updates a workspace
   * @param workspace Workspace details
   * @returns Observable
   */
  updateWorkspace(workspace: any) {
    return this.http.patch(`${environment.planningApi}workspaces`, {
      ...workspace
    })
  }
  /**
   * gets a workspace and their sub entities
   * @param targetId workspace id
   * @returns workspaces list
   */
  getWorkspaces(targetId: number | null = null, page: number | null = 1, search: string | null = null): Observable<any> {
    const requestedTargetId = !targetId ? '' : `?workspace_id=${targetId}`
    const requestedPage = `${!targetId ? `?` : '&'}page=${page.toString()}`
    const searchParam = search ? `&search=${search}` : ''
    return this.http.get<any>(`${environment.planningApi}workspaces${requestedTargetId + requestedPage + searchParam}`)
  }

  /**
   * gets parent workspace
   * @param targetId workspace id
   * @returns workspaces list
   */
  getParentWorkspaces(targetId: number | null = null, page: number | null = 1, search: string | null = null): Observable<any> {
    const requestedTargetId = !targetId ? '' : `?workspace_id=${targetId}`
    const requestedPage = `${!targetId ? `?` : '&'}page=${page.toString()}`
    const searchParam = search ? `&search=${search}` : ''
    return this.http.get<any>(`${environment.planningApi}workspaces/main${requestedTargetId + requestedPage + searchParam}`)
  }
  /**
   * finds a single workspace
   * @param targetId workspace id
   * @returns workspace list
   */
  findWorkspaces(targetId: number, page: number | null = 1, search: string | null = null): Observable<any[]> {
    const requestedPage = `${!targetId ? `?` : '&'}page=${page.toString()}`
    const searchParam = search ? `&search=${search}` : ''
    return this.http.get<any[]>(`${environment.planningApi}workspaces/find?workspace_id=${targetId + requestedPage + searchParam}`)
  }
  /**
   * Assigns a user to a workspace
   * @param form user assign interface
   * @returns Observable
   */
  assignUser(form) {
    return this.http.post(`${environment.planningApi}workspaceUsers`, form)
  }

  /**
   * Returns a list of workspace users
   * @param id workspace id
   * @returns workspace users list
   */
  getWorkspaceUsers(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${environment.planningApi}workspaceUsers?workspace_id=${id}`)
  }
  /**
   * Gets a list of current workspace users
   * @returns current workspace users
   */
  getCurrentWorkspaceUsers(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.planningApi}workspaceUsers?workspace_id=${this.auth.currentWorkspace.id}`)
  }

  /**
   * Removes a user from a workspace
   * @param workspaceId workspace id
   * @param userId user id
   * @returns Observable
   */
  deleteUser(workspaceId: number, userId: string) {
    return this.http.post(`${environment.planningApi}workspaceUsers/delete`, {
      workspace_id: workspaceId,
      user_id: userId
    })
  }

  /**
   * Checks whether the current user can remove  workspace user
   * @param workspaceId workspaces id
   * @returns Observable
   */
  canDeleteUser(workspaceId): Observable<any> {
    return this.http.get(`${environment.planningApi}workspaceUsers/CanUnassign?workspace_id=${workspaceId}`)
  }

  /**
   * Update user role on the workspace
   * @param value inteface defining worksapce id, user id, role and email address
   * @returns Observable
   */
  updateRole(value: { user_id: number; workspace_id: number; is_owner: boolean; role: string; email?: string }) {
    return this.http.patch(`${environment.planningApi}workspaceUsers`, value)
  }

  /**
   * Gets available plans for a workspace
   * @returns Available plans
   */
  getPlans() {
    return this.http.get(`${environment.planningApi}plans`)
  }

  /**
   * Starts a workspace master export request
   * @returns Observable
   */
  export() {
    return this.http.post(
      `${environment.exportsApi}export/master/${this.auth.currentWorkspace.slug}/sendmail`,
      {},
      {
        headers: {
          Token: this.auth.getToken()
        }
      }
    )
  }

  /**
   * Checks the current progress of an active export request
   * @returns Observable
   */
  checkExportProgress() {
    return this.http.post(`${environment.exportsApi}export/master/${this.auth.currentWorkspace.slug}/progress`, {})
  }

  /**
   * Sets the logo for a workspace
   * @param url logo url
   * @param workspaceId workspace id
   * @returns Observable
   */
  updateLogo(url, workspaceId) {
    return this.http.patch(`${environment.planningApi}workspaces`, {
      id: workspaceId,
      logo: url
    })
  }

  deleteWorkspace(workspaceId) {
    return this.http.patch(`${environment.planningApi}workspaces/delete`, {
      id: workspaceId
    })
  }
}
