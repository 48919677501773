<div class="wrapper">
  <div class="row container">
    <div class="col-md-6 center-content">
      <div>
        <p class="not-found-text">Page not found (404)</p>
        <h1 class="farwandered">You wandered far from the tribe</h1>
        <p class="sorry-text">Sorry, the page you are looking for doesn't exist.</p>
      </div>
      <div class="links">
        <div class="link">
          <a class="question d-flex align-items-center justify-content-start" onclick="history.back()"
            >Go Back
            <div class="arrow-icon"></div
          ></a>
        </div>
        <div class="link">
          <a class="question d-flex align-items-center justify-content-start" target="_blank" href="https://share.hsforms.com/1CmkXzf4LRe-Y6bI55gvdFQbxbsc"
            >Open a support ticket
            <div class="arrow-icon"></div
          ></a>
          <p class="hint">Let us know if you are stuck, our team is here to help.</p>
        </div>
        <!-- <div class="link">
          <a class="question d-flex align-items-center justify-content-start" (click)="chatSupport()">Chat to support <div class="arrow-icon"></div></a>
          <p class="hint">Our friendly team is here to help.</p>
        </div> -->
        <!-- <div class="link">
          <p class="question d-flex align-items-center justify-content-start" ">Our blog <div class="arrow-icon"></div></p>
          <p class="hint">Read the latest posts on our blog.</p>
        </div> -->
      </div>
    </div>
    <div class="col-md-6 d-flex align-items-center justify-content-center">
      <img class="img404" src="https://library.micetribe.com/custom/image.svg" alt="" />
    </div>
  </div>
</div>
