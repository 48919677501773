import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { RepositoriesService } from '@app/services/repositories.service'
import { AsYouType } from 'libphonenumber-js'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FormControl } from '@angular/forms'
@Component({
  selector: 'mt-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit, OnDestroy {
  asYouType = new AsYouType()
  @Input()
  control: FormControl
  @Input()
  label: string
  @ViewChild('htmlField')
  htmlField: ElementRef
  private _destroy$ = new Subject()
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }
  constructor(private repoService: RepositoriesService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.asYouType.reset()
    if (!this.control.value) this.control.patchValue('')
    const formatted = this.asYouType.input(this.control.value)
    this.control.setValue(formatted && formatted.includes('+') ? formatted : '+' + formatted, { emitEvent: true })
    this.cdr.detectChanges()
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((currentValue: string) => {
      this.asYouType.reset()
      const formatted = this.asYouType.input(currentValue)
      this.control.setValue(formatted && formatted.includes('+') ? formatted : '+' + formatted, { emitEvent: false })
      this.cdr.detectChanges()
    })
  }

  onKeyPress(e) {
    const charCode = e.which ? e.which : e.keyCode
    if (!this.isPhoneInput(e)) {
      e.preventDefault()
    }
    if (e.target.value === '+' && charCode === 8) {
      e.preventDefault()
    }
    if (e.target.value && e.target.value.includes('+') && charCode === 43) {
      e.preventDefault()
    }
  }

  isPhoneInput(e) {
    const charCode = e.which ? e.which : e.keyCode
    if (charCode !== 9 && charCode !== 43 && charCode !== 8 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }

  handleFocus() {
    this.htmlField.nativeElement.selectionStart = this.htmlField.nativeElement.selectionEnd = this.htmlField.nativeElement.value.length
  }
  get defaultCountry() {
    return this.repoService.CountriesTimezonesRepository.find((c) => c.name === this.control['field'].defaultCountry)
  }
  get defaultFlag() {
    return this.defaultCountry ? this.defaultCountry.flag : ''
  }

  get flag() {
    if (!this.asYouType.country) {
      return 'assets/images/none.svg'
    }
    const country = this.repoService.CountriesTimezonesRepository.find((c) => c.iso2 === this.asYouType.country)
    if (country) {
      return country.flag
    }
    return this.defaultFlag
  }

  get flagCountry() {
    if (!this.asYouType.country) {
      return ''
    }
    const country = this.repoService.CountriesTimezonesRepository.find((c) => c.iso2 === this.asYouType.country)
    if (country) {
      return country.name
    }
    return ''
  }

  /**
   * Get the error message from the control
   */
  get errorMessage() {
    let error = ''
    if (!this.control.errors) {
      return error
    }
    Object.keys(this.control.errors)
      .filter((k) => this.control.errors.hasOwnProperty(k))
      .map((k) => (this.control.errors[k] === true ? k : this.control.errors[k]))
      .forEach((k) => (error += k))
    return error
  }
}
