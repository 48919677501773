<div (click)="showShareIcons($event)" class="d-flex justify-content-end position-relative">
  <div #menuId type="button" class="share-btn"><img src="https://library.micetribe.com/icons/share.svg" alt="" /> <span>Share</span></div>
  <div [ngClass]="{ 'd-none': !showIcons }" class="custom-container position-absolute">
    <div class="icons-container d-flex justify-content-between">
      <ng-container *ngFor="let social of socials">
        <div class="socials-container d-flex">
          <div class="social-container d-flex flex-column justify-content-center align-items-center" [shareButton]="social.id" [url]="formLink">
            <img width="32" height="32" [src]="social.icon" [alt]="social.name" />
            <p class="text-nowrap">{{ social.name }}</p>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="registration-link-container" (click)="copyCode()" [ngClass]="{ 'show-status': formLinkStatus }" [attr.status]="formLinkStatus" [title]="formLink">
      {{ formLink }}
    </div>
    <div class="qrcode-container d-flex justify-content-center">
      <ngx-qrcode [qrData]="formLink" [format]="format" [backgroundColor]="qrBackgroundColor" [id]="qrCodeTagId"></ngx-qrcode>
    </div>
  </div>
</div>
