import { AbstractControl } from '@angular/forms'

/** Validates an email address format */
export class EmailValidator {
  /** Regex used for validation */
  static regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  /**
   * Applies validation logic
   * @param control Form control
   * @returns error or null
   */
  static validate(control: AbstractControl) {
    if (!control.value) {
      return null
    }
    return EmailValidator.regExp.test(control.value) ? null : { email: `Invalid email address` }
  }
}
