<div class="WordSection1">
  <p class="MsoNoSpacing">&nbsp;</p>
  <p class="MsoNormal" align="center" style="text-align: center">
    <b><span style="font-size: 16pt; line-height: 115%; font-family: 'Verdana', sans-serif">TERMS &amp; CONDITIONS</span></b>
  </p>
  <p class="MsoNoSpacing">&nbsp;</p>
  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">These Terms &amp; Conditions governs </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"> services used by the End Users (as defined below).</span>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNormal" style="text-align: justify">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">A. Introduction</span></b>
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">
      provides its End Users a System Software that caters event registration platform facilitating creation of online registration for events, conferences, meetings, and
      management of attendees.</span
    >
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">By accepting this Terms &amp; Conditions, or by using the </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">
      software solutions, the End User formally give the consent to the Terms &amp; Conditions of this agreement on behalf of himself, the company, and its affiliates.
    </span>
  </p>

  <p class="MsoNoSpacing">&nbsp;</p>

  <p class="MsoNormal" style="text-align: justify">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">B. Purpose &amp; Definitions</span></b>
  </p>

  <p style="margin-top: 0in; margin-right: 0in; margin-bottom: 9pt; margin-left: 0in; text-align: justify; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >This document constitutes the entire agreement and manage the relationship between </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"> </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">and its End Users. </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"> </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >reserves the right to provide special conditions in relation to the provision of additional specific services.
    </span>
  </p>

  <p style="margin-top: 0in; margin-right: 0in; margin-bottom: 9pt; margin-left: 0in; text-align: justify; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 5pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p
    class="MsoListParagraphCxSpFirst"
    style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline"
  >
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">End User</span></b
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"> </span>
  </p>

  <p class="MsoListParagraphCxSpLast" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">The user or users who purchase and use the Services of </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">.</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p
    class="MsoListParagraphCxSpFirst"
    style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline"
  >
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">Services</span></b>
  </p>

  <p class="MsoListParagraphCxSpLast" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">Any service, including the Software, provided by </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"> through the website.</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p
    class="MsoListParagraph"
    style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline"
  >
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">Software</span></b>
  </p>

  <p
    class="MsoNormal"
    style="margin-top: 0in; margin-right: 0in; margin-bottom: 0in; margin-left: 0.5in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline"
  >
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >Software application as described above and related licensed materials and data, access to which is granted to the End User under this Terms &amp; Conditions.</span
    >
  </p>

  <p
    class="MsoNormal"
    style="margin-top: 0in; margin-right: 0in; margin-bottom: 0in; margin-left: 0.5in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline"
  >
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">C. End User’s Obligations</span></b>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p
    class="MsoListParagraph"
    style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline"
  >
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >End User may not copy, modify, distribute, sell, or lease any part of the Site, Application and Services. To the extent permitted by law, the End User may not reverse
      engineer or attempt to extract the source code of the Site, Application and Services.
    </span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p
    class="MsoListParagraphCxSpFirst"
    style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline"
  >
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >The End User shall be responsible for the adequacy and accuracy of all data that they provide to their </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"> account.</span>
  </p>

  <p class="MsoListParagraphCxSpMiddle" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p
    class="MsoListParagraphCxSpLast"
    style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline"
  >
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >Contactless.io OÜ shall provide the Software to the End User subject to payment of fees as software as a service in accordance with the Agreement.
    </span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoListParagraphCxSpFirst" style="margin-bottom: 9pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >The End User shall take reasonable precautions to protect the password against loss or unauthorized use of user ID and password. If the End User suspects any security breach
      to their account by third parties, the End User is asked to immediately contact </span
    ><a name="_Hlk43655417"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span></a
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"> </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >at the following email address help@contactless.io and change their password as soon as possible. </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">
      assumes no responsibility for any damage resulting from the non-diligent custody of the password and users account.</span
    >
  </p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-bottom: 9pt; text-align: justify; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-bottom: 9pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >The End User shall remain solely responsible for the Personal Data of their event attendees and any information, image or other content transmitted and uploaded by End User
      within the Software shall fully remain with the End User.</span
    >
  </p>

  <p class="MsoListParagraphCxSpMiddle">
    <span style="font-size: 10pt; line-height: 107%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoListParagraphCxSpLast" style="margin-bottom: 9pt; text-align: justify; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 9pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">D. Additional Services</span></b>
  </p>

  <p class="MsoNormal" style="margin-bottom: 9pt; text-align: justify; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">
      can, on request, and for a separate price, provide additional services to the End User beyond the functionality of the Site, including, but not limited to, the rent of
      registration equipment, manpower supply, badges, and lanyard supply.</span
    >
  </p>

  <p class="MsoNormal" style="margin-bottom: 9pt; text-align: justify; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 9pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">E. Limitation of Liability</span></b>
  </p>

  <p class="MsoListParagraphCxSpFirst" style="margin-bottom: 9pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >Contactless.io OÜ will not be liable for any consequences arising from the breach by the  End User of its obligations to the General Data Protection Regulation (GDPR) in the
      matter of protecting their attendees personal data, in which the End User are Data Controllers.
    </span>
  </p>

  <p class="MsoListParagraphCxSpMiddle" style="margin-bottom: 9pt; text-align: justify; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">&nbsp;</span>
  </p>

  <p class="MsoListParagraphCxSpLast" style="margin-bottom: 9pt; text-align: justify; text-indent: -0.25in; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol; color: black"
      >·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >Contactless.io OÜ provides a platform for End Users to create and manage their own events. Contactless.io OÜ does not produce or organize events. Therefore, any form of
      direct or indirect responsibility related to the events will be the sole responsibility of the End User.</span
    >
  </p>

  <p class="MsoNormal" style="margin-bottom: 9pt; text-align: justify; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 9pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">F. Intellectual Property Rights </span></b>
  </p>

  <p class="MsoNormal" style="margin-bottom: 9pt; text-align: justify; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">The Site, Services and related software are owned by </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >. All rights and intellectual property rights in the Site, the Services and the related software are and will remain </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"> property.</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">G. Confidentiality</span></b>
  </p>

  <p style="margin: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p style="margin: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"> </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">and </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">its </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">End User</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">s</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">
      agree to keep the Confidential Information they receive from the other party in confidence and to not disclose any of it to a third party or use it for its own benefit
      without previous consent of the other party in writing. They shall not disclose Confidential Information, except to their employees who are legally bound to keep such
      Confidential Information.
    </span>
  </p>

  <p style="margin: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p style="margin: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"
      >They may only use such Confidential Information to exercise their rights and fulfil their obligations under the Agreement and shall take reasonable measures to avoid
      unauthorized disclosure or misuse of Confidential Information.
    </span>
  </p>

  <p style="margin: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p style="margin: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 9pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">H. Data Protection Policy</span></b>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">Contactless.io OÜ</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">
      and its End Users shall comply with any applicable laws concerning the protection of personal data including but not limited to the EU General Data Protection Regulation
      (GDPR). Please refer to the </span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >Contactless.io OÜ <b><u>Data Protection Policy</u></b> for further information.</span
    ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black"> </span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoListParagraph" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; background: white; vertical-align: baseline">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black">I. Termination</span></b>
  </p>

  <p class="MsoListParagraph" style="margin-bottom: 0in; margin-bottom: 0.0001pt; text-align: justify; line-height: 115%; background: white; vertical-align: baseline">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >The End User, at any time, can stop using the System and Services without prior notice.
    </span>
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >In such cases that a breach of these Terms &amp; Conditions or misuse of the Services, Contactless.io OÜ has the right to terminate the End Users account and access to the
      Services without prior notice and without any financial liability.</span
    >
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >In the event of non-payment by the End User, Contactless.io OÜ has the right to restrict access to the Services.&nbsp; End User agrees that such restrictions do not change
      the due amounts under your Agreement.</span
    >
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 5pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">J. Governing Law</span></b>
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >These Terms &amp; Conditions shall be governed by and construed in accordance with the laws&nbsp;of the Republic of Estonia.</span
    >
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <b
      ><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
        >K. Modifications to the Terms &amp; Conditions</span
      ></b
    >
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >Contactless.io OÜ reserve the right to amend any part of these Terms &amp; Conditions by posting updates and changes to our website. It is the End User’s responsibility to
      regularly check our website for updates.
    </span>
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >Your continued use of our Services following the effectiveness of any updates to these Terms &amp; Conditions constitutes acceptance of those updates. If any updates to
      these Terms &amp; Conditions is not acceptable to you, you should cease from accessing and using our Services.</span
    >
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">&nbsp;</span>
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">L. Contact Information</span></b>
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white"
      >If you have any questions, complaints, or comments about our Services that you would like to raise, you may contact us at: <b>help@contactless.io</b></span
    >
  </p>

  <p class="MsoNormal" style="text-align: justify">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif; color: black; background: white">&nbsp;</span>
  </p>
</div>
