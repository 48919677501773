/**
 * Defines an invitation information to a different event in the current workspace
 */
export interface InvitationInfo {
  /** Event registrations are getting invited to */
  eventSlug: string
  /** Persona registrations are getting invited to */
  formSlug: string
  /** Invitation message */
  message: string
  /** Event registrations are getting invited from */
  fromEventSlug: string
  /** Persona registrations are getting invited from */
  fromFormSlug: string
  /** Invitation action - all - include - exclude - test */
  action: string
  /** Invitation ids - can be empty if the action is all */
  ids: string[]
  /** Invitation email template - can can be either 1 or 2 or 3 */
  selectedTemplate: number
  /** Invitation emails - can be empty in case no test email being sent */
  emails: string[]
  isInvitationModal?: boolean,
  count?: number
}

export enum InvitationState {
  EVENT = 'Event',
  PERSONA = 'Persona',
  MESSAGE = 'Message',
  COMPLETE = 'Complete'
}
