<mat-toolbar class="d-flex justify-content-start align-items-center mb-2 flex-row">
  <button
    class="mr-2 btn"
    mat-stroked-button
    color="primary"
    aria-label="Add workspace"
    (click)="open(addWorkspaceDialog)"
    *ngIf="role() !== 'Sub Entity' && currentUseRoleInCurrentWorksapce === 'admin'"
  >
    <mat-icon class="mr-1">create_new_folder</mat-icon>
    {{ role() === 'Admin' ? 'ADD WORKSPACE' : 'ADD SUB ENTITY' }}
  </button>
  <ngx-workspaces-search
    class="form-padded flex-grow-1"
    (noMoreWorkspaces)="showNoMore($event)"
    (workspacesChanged)="propagateWorkspaces($event)"
    [ngClass]="{ 'ml-2': role() !== 'Sub Entity' }"
  ></ngx-workspaces-search>
</mat-toolbar>
<ng-template #dialog let-data let-ref="dialogRef">
  <ngx-workspaces-invoice-plans [createWorkspace]="true" (close)="ref.close()"></ngx-workspaces-invoice-plans>
</ng-template>
<ng-template #addWorkspaceDialog let-data let-ref="dialogRef">
  <ngx-workspaces-create [edit]="false" (dismiss)="ref.close()"></ngx-workspaces-create>
</ng-template>
