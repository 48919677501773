<div class="container-fluid">
  <div class="row justify-content-md-center">
    <div class="col-xl-4 col-lg-6 col-md-8 col-sm-8">
      <nb-card>
        <nb-card-body [nbSpinner]="processing">
          <div class="container-fluid">
            <div class="row form-header justify-content-md-center">
              <div class="col-xl-8 col-lg-9 col-md-10 logo-col">
                <div class="logo">
                  <img src="https://res.cloudinary.com/contactless/image/upload/v1609251891/planning-system/logo.svg" alt="MICEtribe logo" />
                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="form" (ngSubmit)="send(form)">
            <span class="title-text"> Please set your new password: </span>
            <nb-form-field>
              <mat-icon nbPrefix>lock</mat-icon>
              <input formControlName="password" fullWidth nbInput placeholder="New password" type="password" />
            </nb-form-field>
            <nb-form-field>
              <mat-icon nbPrefix>lock</mat-icon>
              <input formControlName="password_confirmation" fullWidth nbInput placeholder="Confirm password" type="password" />
            </nb-form-field>
            <button nbButton fullWidth status="primary" class="my-2">update & Procced to the app</button>
          </form>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
