<div class="payment-page" [nbSpinner]="!loaded" nbSpinnerMessage="Loading your billing details">
  <div class="top">
    <span class="title"> Billing {{ workspaceName && customer ? '- ' + workspaceName + ' (' + customer.data.id + ')' : '' }} </span>
    <span class="icon" (click)="navigate()">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.43394 9.00002L17.7301 0.703815C17.8507 0.583215 17.8507 0.390615 17.7301 0.270015C17.6095 0.149415 17.4169 0.149415 17.2963 0.270015L9.00014 8.56622L0.703937 0.270015C0.583337 0.151215 0.388937 0.151215 0.270137 0.270015C0.149537 0.390615 0.149537 0.583215 0.270137 0.703815L8.56634 9.00002L0.270137 17.2962C0.149537 17.4168 0.149537 17.6094 0.270137 17.73C0.329537 17.7894 0.408737 17.82 0.486137 17.82C0.565337 17.82 0.642737 17.7894 0.702137 17.73L9.00014 9.43382L17.2963 17.73C17.3557 17.7894 17.4349 17.82 17.5123 17.82C17.5897 17.82 17.6689 17.7894 17.7283 17.73C17.8489 17.6094 17.8489 17.4168 17.7283 17.2962L9.43394 9.00002Z"
          fill="#222b45"
        />
      </svg>
    </span>
  </div>
  <ngx-subscription-plan [customer]="customer"></ngx-subscription-plan>
  <ngx-payment-methods [customer]="customer" [paymentMethods]="paymentMethods"></ngx-payment-methods>
  <ngx-wallet [customer]="customer" [paymentMethods]="paymentMethods"></ngx-wallet>
  <ngx-transactions *ngIf="loaded"></ngx-transactions>
</div>
