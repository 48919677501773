<div class="container-fluid d-flex justify-content-center">
  <nb-card class="login-container">
    <nb-card-header>
      <h4>
        <strong> Almost there! </strong>
      </h4>
    </nb-card-header>
    <nb-card-body [nbSpinner]="processing">
      <form [formGroup]="form" (ngSubmit)="createWorkspace()">
        <mt-fullname label="Your full name" [control]="form.controls.full_name"></mt-fullname>
        <mt-phone label="Your phone number" [control]="form.controls.phone"></mt-phone>
        <mt-input label="Your organization name" [control]="form.controls.name"></mt-input>
        <mt-input label="Organization short name (Acronym)" [control]="form.controls.slug"></mt-input>
        <mt-country label="Country of organization" [control]="form.controls.country"></mt-country>
        <button nbButton fullWidth status="primary" class="my-2" [disabled]="!form.valid" [class.disabled]="!form.valid">Create organization</button>
      </form>
    </nb-card-body>
  </nb-card>
</div>
