<div class="header-container" *ngIf="isLoggedIn">
  <div class="logo-container">
    <ng-content class="content"></ng-content>
    <div (click)="navigateHome()" class="logo" [ngStyle]="{ cursor: isLoggedIn ? 'pointer' : 'default' }">
      <img src="https://library.micetribe.com/icons/micetribe-logo-black.svg" alt="MICEtribe logo" />
    </div>
    <ngx-plan-badge *ngIf="showBadge"></ngx-plan-badge>
  </div>

  <div class="logout-container d-flex justify-content-center align-items-center">
    <div id="announcement">
      <img *ngIf="unreadCount" src="https://library.micetribe.com/icons/announcement.svg" alt="Announcements icon" />
      <span class="cdk-visually-hidden"> {{ unreadCount }} unread announcements </span>
    </div>
    <div
      *ngIf="hasRunningJobs"
      class="running-jobs-icon mr-3"
      (mouseover)="showTooltip(jobsButton)"
      (mouseleave)="hideTooltip(jobsButton)"
      (click)="toggleJobsContainer($event)"
      #jobsButton
    >
      <div *ngIf="showTip" class="custom-tooltip d-flex justify-content-center align-items-center">
        <span>Runing jobs</span>
      </div>
      <img [src]="jobsContainerOpened ? 'https://library.micetribe.com/icons/running-jobs-active.svg' : 'https://library.micetribe.com/icons/running-jobs.svg'" alt="" />
    </div>
    <div class="icons">
      <img class="gear-icon" (click)="navigateToSettings()" src="https://library.micetribe.com/icons/gear.svg" alt="" />
    </div>
    <ngx-user-dropdown></ngx-user-dropdown>
  </div>
  <div class="running-jobs-container" *ngIf="jobsContainerOpened" #jobsContainer>
    <ngx-running-jobs [statisticsArray]="runningJobs"></ngx-running-jobs>
  </div>
</div>
