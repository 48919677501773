import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { environment } from '@environments/environment'
import { NbDialogRef } from '@nebular/theme'
import { BillingService } from '@payments/services/billing.service'
/**
 * Global stripe-js variable
 */
declare var Stripe: any

/**
 * Topup modal used to display the status of a wallet topup attempt
 */
@Component({
  selector: 'ngx-top-up-modal',
  templateUrl: './top-up-modal.component.html',
  styleUrls: ['./top-up-modal.component.scss']
})
export class TopUpModalComponent implements OnInit {
  /** Whether the topup operation finished */
  finished = null
  /** Form group to collect payment information */
  stripePaymentForm: FormGroup = new FormGroup({})
  /** Creates a stripe object */
  stripe = Stripe(environment.stripeKey)
  /** Collects secured card details */
  card: any
  /** Topup amount */
  public amount: number = 0
  /** Whether the component is performing an async operation, controls loader */
  public processing = true
  /** Payment intent secret */
  private _secret = null
  /** Payment error  */
  public error = ''
  /**
   * Stripe elements style
   */
  style: {
    base: {
      iconColor: '#666EE8'
      color: '#31325F'
      fontWeight: '300'
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
      fontSize: '18px'
      '::placeholder': {
        color: '#CFD7E0'
      }
    }
  }
  /**
   * Stripe elements options
   */
  elementsOptions: any = {
    locale: 'en'
  }

  /**
   * Creates the component and injects it's dependencies
   * @param dialogRef NbDialogRef<TopUpModalComponent>
   * @param service BillingService
   */
  constructor(private dialogRef: NbDialogRef<TopUpModalComponent>, private service: BillingService) {}

  /**
   * Bootstraps the component
   */
  ngOnInit(): void {
    this.amount
      ? this.service.createPaymentIntent(this.amount).subscribe((result: any) => {
          this._secret = result.client_secret
          this.processing = false

          const elements = this.stripe.elements()
          this.card = elements.create('card', { style: this.style })
          this.card.mount('#card-element')
          this.card.addEventListener('change', (event) => {
            const displayError = document.getElementById('card-errors')
            if (event.error) {
              displayError.textContent = event.error.message
            } else {
              displayError.textContent = ''
            }
          })
        })
      : this.service.createSetupIntent().subscribe((result: any) => {
          this._secret = result.client_secret
          this.processing = false

          const elements = this.stripe.elements()
          this.card = elements.create('card', { style: this.style })
          this.card.mount('#card-element')
          this.card.addEventListener('change', (event) => {
            const displayError = document.getElementById('card-errors')
            if (event.error) {
              displayError.textContent = event.error.message
            } else {
              displayError.textContent = ''
            }
          })
        })
  }

  /** Closes the dialog ref (modal) */
  close() {
    this.dialogRef.close()
  }

  /** Creates a payment token */
  createToken() {
    if (this.processing) {
      return
    }
    this.processing = true

    this.stripe
      .confirmCardPayment(this._secret, {
        payment_method: {
          card: this.card
        },
        setup_future_usage: 'off_session'
      })
      .then((result: any) => {
        if (result.error) {
          this.error = ''
          this.finished = false
          this.processing = false
          return
        }
        this.finished = result.paymentIntent.status === 'succeeded'

        this.service.reloadBillingDetails$.next(true)
        this.processing = false
      })
      .catch((error) => {
        console.error(error)
        this.finished = false
        this.processing = false
      })
  }
}
