import { Component, Input, OnDestroy, AfterContentChecked } from '@angular/core'
import { AuthService } from '@auth/services/auth.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

/**
 * Displays the current workspace plan as a badge next to micetribe logo on navigation bar
 */
@Component({
  selector: 'ngx-plan-badge',
  templateUrl: './plan-badge.component.html',
  styleUrls: ['./plan-badge.component.scss']
})
export class PlanBadgeComponent implements OnDestroy, AfterContentChecked {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * plan name
   */
  plan = ''
  /**
   * current workspace
   */
  private _workspace = null

  /**
   * Input that sets the workspace and also calls the initialize method
   */
  @Input()
  set workspace(value) {
    this._workspace = value
    this.initialize()
  }

  /**
   * gets current workspace
   */
  get workspace() {
    return this._workspace
  }
  /**
   * Creates the component and injects it's dependencies
   * @param auth AuthService
   */
  constructor(private auth: AuthService) {}

  /**
   * OnInit implementation that subscribes to workspace changes to update the badge accordingly
   */
  ngAfterContentChecked(): void {
    this.auth.currentWorkspaceChange.pipe(takeUntil(this._destroy$)).subscribe((workspace) => {
      this.detectPlan(workspace)
      this.initialize()
    })
    this.initialize()
  }
  /**
   * badge look and feel based on plan
   */
  get badgeClass() {
    return {
      basic: this.plan === 'Basic',
      pro: this.plan === 'Pro'
    }
  }
  /**
   * initializes the badge component
   */
  initialize() {
    this.plan = !!this.workspace ? this.detectPlan(this.workspace) : this.auth.plan
  }
  /**
   * detects the current workspace plan
   * @param workspace Workspace
   * @returns string
   */
  detectPlan(workspace) {
    const planId = workspace?.parent_workspace ? workspace?.parent_workspace?.plan_id : workspace?.plan_id
    switch (planId) {
      case 2:
        return 'Pro'
      case 1:
        return 'Basic'
      default:
        return ''
    }
  }
}
