import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core'
import { Subject } from 'rxjs'
import { Workspace } from '@workspaces/interfaces/workspace.interface'
import { WorkspaceService } from '@workspaces/services/workspace.service'
import { AuthService } from '@auth/services/auth.service'
import { Router } from '@angular/router'
import { map, takeUntil } from 'rxjs/operators'

/**
 * Sets the current workspace for a signed in user
 */
@Component({
  selector: 'ngx-workspaces-set',
  templateUrl: './set.component.html',
  styleUrls: ['./set.component.scss']
})
export class SetComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * Sets the current workspace and it's parent then navigates to the events list
   * @param workspace Workspace to set
   */
  public set workspace(workspace: Workspace) {
    this.authService.currentWorkspace = workspace
    if (workspace.role_id === 1) {
      this.authService.currentParentWorkspace = workspace
    } else {
      this.authService.currentParentWorkspace = null
    }
    this.router.navigate(['events', 'list'])
  }
  /**
   * list of available workspaces for the user
   */
  public workspaces: Workspace[] = []
  /**
   * Current page of workspaces
   */
  page: number = 0
  /**
   * Indicator to stop retrieving more workspaces as per there is no more
   */
  stopRetrieving: boolean = false
  /**
   * Indicator to stop request for more workspaces while retrieving some
   */
  pendingRequest: boolean = false
  /**
   * show noMoreWorkspaces text
   */
  noMoreWorkspaces: boolean = false
  /**
   * Creates the component & injects it's dependencies
   * @param authService AuthService
   * @param router Router
   * @param workspaceService WorkspaceService
   */
  constructor(private authService: AuthService, private router: Router, private workspaceService: WorkspaceService) {}
  /**
   * OnInit implementation also gets all workspaces for the user
   * If no workspaces are found it redirects the user to the almost-there component @see AlmostThereComponent
   * If the user has a single workspace it sets that workspace as the current workspace without user interaction
   */
  ngOnInit() {
    this.refresh()
  }
  /**
   * refreshes the workspace list by retrieving it from backend and storing it
   */
  noParentWorksapce = false
  refresh() {
    if (this.pendingRequest || this.stopRetrieving) return
    this.pendingRequest = true
    !this.noParentWorksapce ? this.callParentWorkspaces() : this.callAllworkspaces()
  }
  callParentWorkspaces() {
    this.workspaceService
      .getParentWorkspaces(null, ++this.page)
      .pipe(
        map((workspaces: any) => {
          if (!workspaces.data.length) {
            this.noParentWorksapce = true
            return []
          }
          if (workspaces.data.length) {
            const mainWorkspaces = []
            workspaces.data.forEach((workspace: Workspace) => {
              if (!workspace.parent) {
                mainWorkspaces.push(workspace)
              } else {
                if (!workspaces.data.find((ws: Workspace) => ws.id === workspace.parent)) {
                  mainWorkspaces.push(workspace)
                }
              }
            })
            if (workspaces.to == workspaces.total) {
              this.stopRetrieving = true
              this.noMoreWorkspaces = true
            }
            return mainWorkspaces
          }
          this.page = 0
          return []
        }),
        takeUntil(this._destroy$)
      )
      .subscribe((workspaces: any) => {
        if (workspaces.length === 0) {
          this.callAllworkspaces()
        }
        for (let workspace of workspaces) {
          this.workspaces.push(workspace)
        }
        this.pendingRequest = false
      })
  }
  callAllworkspaces() {
    this.workspaceService
      .getWorkspaces(null, this.page)
      .pipe(
        map((workspaces: any) => {
          const mainWorkspaces = []
          workspaces.data.forEach((workspace: Workspace) => {
            if (!workspace.parent) {
              mainWorkspaces.push(workspace)
            } else {
              if (!workspaces.data.find((ws: Workspace) => ws.id === workspace.parent)) {
                mainWorkspaces.push(workspace)
              }
            }
          })
          if (workspaces.to == workspaces.total) {
            this.stopRetrieving = true
            this.noMoreWorkspaces = true
          }
          this.page++
          return mainWorkspaces
        }),
        takeUntil(this._destroy$)
      )
      .subscribe((workspaces: any) => {
        if (workspaces.length === 0) {
          this.router.navigateByUrl('workspaces/almost-there')
        }
        for (let workspace of workspaces) {
          this.workspaces.push(workspace)
        }
        this.pendingRequest = false
      })
  }
}
