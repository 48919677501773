import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class HubspotService {
  constructor(private http: HttpClient) {}
  openTicket(workspace_id: Number) {
    return this.http.post(`${environment.planningApi}workspaces/requestPayment`, { workspace_id: workspace_id })
  }

  /**
   * Submit new signup data to hubspot form
   */
  public hubspotSignup(form: any): Observable<any> {
    const portalId = '20030700'
    const formGuid = 'bfef5d60-812f-4217-bbb0-6e66ea996824'
    return this.http.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, { ...form, hutk: this.getCookie('hubspotutk') || '' })
  }

  private getCookie(key: string): string {
    const cookieList = {}
    document.cookie.match(/[\w]*=[\S]*/g)?.forEach((cookie) => {
      cookieList[cookie.match(/\w*/)[0]] = cookie.match(/(=)(\S*)(;*)/)[2]
    })
    return cookieList[key]
  }
}
