import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'ngx-invite-to-persona',
  templateUrl: './invite-to-persona.component.html',
  styleUrls: ['./invite-to-persona.component.scss']
})
export class InviteToPersonaComponent {
  @Output()
  personaSelected = new EventEmitter<string>()

  @Input()
  personas: { slug: string; name: string }[]

  @Input()
  selectedPersona: string

  selectPersona(personaSlug: string) {
    this.selectedPersona = personaSlug
    this.personaSelected.next(personaSlug)
  }
}
