<div class="full-page">
  <div class="row my-3 mx-auto justify-content-center align-items-center">
    <div class="col-xl-7 col-sm-12 mx-auto my-2">
      <div class="card m-auto" style="min-width: 380px; max-width: 620px">
        <div class="card-header">
          <div class="text-center">
            <img src="https://res.cloudinary.com/contactless/image/upload/v1599042293/public/MICEtribe_logo.svg" alt="" srcset="" />
          </div>
        </div>
        <div class="card-body justify-content-center flex-column align-items-center">
          <h1 class="text-center d-flex justify-content-center my-4">
            Welcome to <br />
            Micetribe
          </h1>
          <img
            src="https://res.cloudinary.com/contactless/image/upload/v1599043939/public/Email_image.svg"
            alt=""
            class="text-center mx-auto d-flex"
            style="width: 16rem; height: 12.8rem; max-width: 262px; max-height: 206px; object-fit: contain"
          />
          <p class="my-3 text-center" *ngIf="state === 'loading'">Loading</p>
          <p class="my-3 text-center" *ngIf="state === 'invalidToken'">Whoops! an invalid reset token was provided :(</p>
          <p class="my-3 text-center" *ngIf="state === 'unauthorized'">redirecting</p>
          <div class="mb-2 mx-2 mt-3 p-4 foot-container d-flex flex-column align-items-center">
            <span class="foot-text text-center"
              >Should you require any further assistance,<br />
              please do not hesitate to contact our team
            </span>
            <div class="d-flex my-1 align-items-center justify-content-center">
              <span class="icon mx-2">
                <mat-icon style="color: #c5cee0"> email </mat-icon>
              </span>
              <span class="email-text"> help@micetribe.com </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
