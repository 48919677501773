<nb-card [nbSpinner]="!ready || processing" nbSpinnerMessage="Loading..." [ngClass]="{ loading: !ready }">
  <nb-card-body>
    <div class="container">
      <div class="row" *ngIf="ready">
        <div class="col-12 d-flex flex-row">
          <div class="flex-grow-1">
            <div class="basic-title" *ngIf="!createWorkspace && !assignUser">
              <p>Enjoy the benefits and saving of our PRO plan!</p>
            </div>
            <div class="default-title" *ngIf="!!createWorkspace && !assignUser">
              <h6>Your plan is limited to <span>1 workspace</span></h6>
              <h6>Upgrade to PRO for unlimited workspaces</h6>
            </div>
            <div class="default-title" *ngIf="!!assignUser && !createWorkspace">
              <h6>
                Your plan is limited to <span>{{ basicPlan.max_workspace_users }} user</span>
              </h6>
              <h6>Upgrade to PRO and invite unlimited collaborators</h6>
            </div>
          </div>

          <button nbButton ghost size="small" (click)="close()" class="align-self-start close-pop-up">
            <mat-icon class="pt-1"> close </mat-icon>
          </button>
        </div>
      </div>
      <div class="row overflow-auto" *ngIf="ready">
        <div class="col-5 plan active">
          <div class="icon">
            <mat-icon> show_chart </mat-icon>
          </div>
          <div class="title">BASIC</div>
          <div class="price">Free</div>
          <div class="features">
            <div>Single workspace</div>
            <div>Single user</div>
            <div>2 minutes Dashboard updates</div>
            <div>Free exports up to {{ basicPlan.free_exported_entries }} registrants</div>
            <div>
              {{ (basicPlan.virtualPrice.unit_amount / 100).toFixed(2) | currency : selectedPrice.currency.toUpperCase() : 'symbol-narrow' : '1.2-2' }}
              per Virtual Registrant
            </div>
            <div>
              {{ (basicPlan.defaultPrice.unit_amount / 100).toFixed(2) | currency : selectedPrice.currency.toUpperCase() : 'symbol-narrow' : '1.2-2' }} per In-Person Registrant
            </div>
          </div>
          <div class="current-plan text-center bold text-uppercase">Current plan</div>
        </div>

        <div class="col-5 plan">
          <div class="icon">
            <mat-icon> insights </mat-icon>
          </div>
          <div class="title">
            <span>PRO</span>
          </div>
          <div class="price">
            <div class="amount">
              {{ (selectedPrice.unit_amount / 100).toFixed(2) | currency : selectedPrice.currency.toUpperCase() : 'symbol-narrow' : '2.2-2' }}
            </div>
            <div class="period">
              <div>every</div>
              <div>
                {{ selectedPrice.recurring.interval_count > 1 ? selectedPrice.recurring.interval_count : ' ' }}
                {{ selectedPrice.recurring.interval + (selectedPrice.recurring.interval_count > 1 ? 's' : ' ') }}
              </div>
            </div>
          </div>
          <div class="features">
            <div>Unlimited workspaces</div>
            <div>Unlimited users</div>
            <div>Real-time Dashboard updates</div>
            <div>Free exports up to {{ proPlan.free_exported_entries }} registrants</div>
            <div>
              {{ (proPlan.virtualPrice.unit_amount / 100).toFixed(2) | currency : selectedPrice.currency.toUpperCase() : 'symbol-narrow' : '1.2-2' }}
              per Virtual Registrant
            </div>
            <div>
              {{ (proPlan.defaultPrice.unit_amount / 100).toFixed(2) | currency : selectedPrice.currency.toUpperCase() : 'symbol-narrow' : '1.2-2' }} per In-Person Registrant
            </div>
          </div>

          <div class="price-select d-flex flex-stretch">
            <button
              nbButton
              size="tiny"
              status="basic"
              shape="rectangle"
              *ngFor="let price of proPlan.productPrice"
              (click)="selectedPrice = price"
              [appearance]="selectedPrice.id === price.id ? 'filled' : 'outline'"
              [ngClass]="{ 'upgrade-btn': selectedPrice.id === price.id, 'outline-upgrade-btn': selectedPrice.id !== price.id }"
            >
              {{ price.recurring.interval_count + ' ' + price.recurring.interval }}
            </button>
          </div>
          <button nbButton fullWidth status="success" (click)="initiateUpgrade()" class="upgrade-btn">SWITCH TO PRO</button>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
