import { Component, Input, ViewChild } from '@angular/core'
import { BillingService } from '@payments/services/billing.service'
import { NbPopoverDirective } from '@nebular/theme'
import { WorkspaceRoleService } from '@app/modules/auth/services/workspace-role.service'

/**
 * Represents a single payment method
 */
@Component({
  selector: 'ngx-method',
  templateUrl: './method.component.html',
  styleUrls: ['./method.component.scss']
})
export class MethodComponent {
  /**
   * Payment method model
   */
  @Input()
  method: any
  /**
   * Is it the default payment method?
   */
  @Input()
  default: boolean = false
  /**
   * Performing an async operation?
   */
  processing = false
  /**
   * Popover viewchild
   */
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective
  /**
   * Creates the component and injects it's dependencies
   * @param service BillingService
   * @param workspaceRole WorkspaceRoleService
   */
  constructor(private service: BillingService, private workspaceRole: WorkspaceRoleService) {}

  /**
   * Sets this payment method as the default payment method and requests billing reload
   */
  setDefault() {
    this.popover.hide()
    this.processing = true
    this.service.setDefaultPaymentMethod(this.method.id).subscribe((result) => {
      this.service.reloadBillingDetails$.next()
      this.processing = false
    })
  }
  /**
   * Deletes this payment method and requests billing reload
   */
  delete() {
    if (!this.canRemoveCard()) return
    this.popover.hide()
    this.processing = true
    this.service.deletePaymentMethod(this.method.id).subscribe((result) => {
      this.service.reloadBillingDetails$.next()
      this.processing = false
    })
  }
  /**
   * Checks if user can delete this payment method
   * @returns boolean
   */
  canRemoveCard(): boolean {
    return this.workspaceRole.canRemoveCreditcard()
  }
}
