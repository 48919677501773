<div class="method" [nbSpinner]="processing" [ngClass]="{ active: default }">
  <div class="content">
    <div class="status" *ngIf="default">
      <span>Default</span>
    </div>
    <div class="info d-flex justify-content-start align-items-center flex-row w-100">
      <i [class]="'fab fa-cc-' + method.card.brand"></i>
      <div class="d-flex flex-column justify-content-center flex-grow-1 align-items-start">
        <div class="card-number">•••• •••• •••• {{ method.card.last4 }}</div>
        <div class="expiry-date">Expires {{ method.card.exp_month }}/{{ method.card.exp_year }}</div>
      </div>
      <div
        *ngIf="!default"
        class="settings d-flex justify-self-end"
        [nbPopoverTrigger]="'click'"
        [nbPopover]="menuTemplateRef"
        nbPopoverAdjustment="clockwise"
        nbPopoverPlacement="bottom"
      >
        <svg width="5" height="21" viewBox="0 0 5 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="2.5" cy="10.5" r="2.5" fill="#8F9BB3" />
          <circle cx="2.5" cy="18.5" r="2.5" fill="#8F9BB3" />
          <circle cx="2.5" cy="2.5" r="2.5" fill="#8F9BB3" />
        </svg>
      </div>
    </div>
  </div>
</div>
<ng-template #menuTemplateRef>
  <div class="settings-menu d-flex flex-column">
    <div class="setting" (click)="setDefault()">Make default</div>
    <div *ngIf="canRemoveCard()" class="setting" (click)="delete()">Delete</div>
  </div>
</ng-template>
