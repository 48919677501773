import { Input } from '@angular/core'
import { Component } from '@angular/core'
import { ServerRepresentedEvent } from '@app/modules/events/models/event.model'
import { format, parse } from 'date-fns'
import { InvitationInfo } from '../../models/invitation-info.interface'

/**
 * Event invitation message
 */
@Component({
  selector: 'ngx-invite-to-message',
  templateUrl: './invite-to-message.component.html',
  styleUrls: ['./invite-to-message.component.scss']
})
export class InviteToMessageComponent {
  /**
   * Current workspace logo
   */
  @Input()
  workspaceLogo: string = ''
  /**
   * Current workspace name
   */
  @Input()
  workspaceName: string = ''
  /**
   * Event model
   */
  @Input()
  event: Partial<ServerRepresentedEvent>
  /**
   * Invitation info model
   */
  @Input()
  invitationInfo: InvitationInfo
  /**
   * Event timings list
   */
  @Input()
  eventTimings: []

  /**
   * Formats a given date to a string (day)
   * @param date Date
   * @returns string
   */
  getDisplayDay(date: Date): string {
    return format(date, 'E')
  }

  /**
   * Formats a given date to a string (Month day)
   * @param date Date
   * @returns string
   */
  getDisplayMonth(date: Date): string {
    return format(date, 'MMM d')
  }

  /**
   * Gets an event start and end dates
   * @param startDate string
   * @param endDate string
   * @returns string
   */
  getDisplayDate(startDate: string, endDate: string) {
    const publicStartDate = format(parse(startDate, 'dd MMM yyyy', Date.now()), 'dd MMM')
    const publicEndDate = format(parse(endDate, 'dd MMM yyyy', Date.now()), 'dd MMM')
    return publicStartDate + ' - ' + publicEndDate
  }
  /**
   * Determines whether an event has any social media
   */
  get hasSocialMedia() {
    return (
      this.event.social_media.facebookAccount ||
      this.event.social_media.instagramAccount ||
      this.event.social_media.linkedin_account ||
      this.event.social_media.messenger_account ||
      this.event.social_media.snapchat_account ||
      this.event.social_media.twitter_account ||
      this.event.social_media.website ||
      this.event.social_media.youtube_account
    )
  }
}
