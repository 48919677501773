import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '@auth/services/auth.service'

/**
 * Allows/ denies access to certain routes based on whether there's a current workspace
 */
@Injectable({
  providedIn: 'root'
})
export class CurrentWorkspaceGuard implements CanActivate {
  /**
   * Creates the service & injects it's dependencies
   * @param authService AuthService
   * @param router Router
   */
  constructor(private authService: AuthService, private router: Router) {}
  /**
   * Allows access or navigates the user to workspaces list to select a workspace
   * @param next next route
   * @param state current route state
   * @returns boolean
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.currentWorkspace && !this.authService.user.is_admin) {
      this.router.navigate(['workspaces', 'list'])
      return false
    }
    return true
  }
}
