import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { WorkspaceRoleService } from '@app/modules/auth/services/workspace-role.service'
import { BillingService } from '../../services/billing.service'
/**
 * Represents a list of transactions displayed in a tabular fashion
 */
@Component({
  selector: 'ngx-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  /**
   * Transactions details
   */
  public data = null
  retrieving: boolean = true
  lastTransactionId: string = ''
  /**
   * Creates the component and injects it's dependencies
   * @param router Router
   * @param service BillingService
   * @param workspaceRole WorkspaceRoleService
   */
  constructor(private router: Router, private service: BillingService, private workspaceRole: WorkspaceRoleService) {}

  ngOnInit(): void {
    this.service.transactions().subscribe((res) => {
      this.data = res
      const transactions = this.data.original
      this.lastTransactionId = transactions[transactions.length - 1]?.id
      this.retrieving = false
    })
  }
  /**
   * Redirects the user to a component that redirects them to striped to manage their billing details
   */
  manageSubscribtion() {
    this.router.navigateByUrl('/subscription/billing')
  }

  /**
   * Loads the next set of transactions
   * @returns void
   */
  loadNext() {
    if (this.retrieving || !this.data.has_more) {
      return
    }
    this.retrieving = true
    this.service.transactions(this.lastTransactionId).subscribe((res) => {
      this.updateData(res)
      const transactions = this.data.original
      this.lastTransactionId = transactions[transactions.length - 1]?.id
      this.retrieving = false
    })
  }

  private updateData(res: any) {
    this.data.data.push(...res.data)
    this.data.original.push(...res.original)
    this.data.has_more = res.has_more
  }

  /**
   * Checks if user can edit subscription
   * @returns boolean
   */
  canEditSubscription(): boolean {
    return this.workspaceRole.canEditSubscription()
  }
}
