import { AuthService } from '@auth/services/auth.service'
import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { PlanPrice, Price } from '@workspaces/models/plan-price.interface'
import { loadStripe } from '@stripe/stripe-js/pure'
import { environment } from '@environments/environment'
import { NbDialogRef } from '@nebular/theme'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
/**
 * Holds information about available workspace plans
 */
@Component({
  selector: 'ngx-workspaces-invoice-plans',
  templateUrl: './invoice-plans.component.html',
  styleUrls: ['./invoice-plans.component.scss']
})
export class InvoicePlansComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * Was it fired because the user tried to create another workspace
   */
  @Input()
  createWorkspace = false
  /**
   * Was it fired because the user tried to add another user
   */
  @Input()
  assignUser = false
  /**
   * Pro plan prices
   */
  proPlan: PlanPrice
  /**
   * Basic plan prices
   */
  basicPlan: PlanPrice
  /**
   * Is the component ready
   */
  ready = false
  /**
   * Is the component performing an async operation
   */
  processing = false
  /**
   * Currently selected price
   */
  selectedPrice: Price
  /**
   * Creates the component and injects it's dependencies
   * @param dialogRef NbDialogRef
   * @param auth AuthService
   */
  constructor(private dialogRef: NbDialogRef<InvoicePlansComponent>, public auth: AuthService) {}

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.auth
      .getPlansWithPrices()
      .pipe(takeUntil(this._destroy$))
      .subscribe((result) => {
        this.basicPlan = result.find((o) => o.id === 1)
        this.proPlan = result.find((o) => o.id === 2)
        this.ready = true
        this.selectedPrice = this.proPlan.productPrice[0]
      })
  }
  /**
   * Upgrades from Basic to Pro plan
   */
  initiateUpgrade() {
    this.processing = true
    this.auth
      .createSubscription(this.selectedPrice.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (result: any) => {
          switch (result.object) {
            case 'checkout.session':
              this.redirectToCheckout(result.id)
              break
            case 'billing_portal.session':
              window.open(result.url, '_blank')
              this.processing = false
              break
          }
        },
        () => (this.processing = false)
      )
  }
  /**
   * Async redirect to checkout
   * @param checkoutId string
   */
  async redirectToCheckout(checkoutId) {
    const stripe = await loadStripe(environment.stripeKey)
    try {
      await stripe.redirectToCheckout({
        sessionId: checkoutId
      })
    } catch (error) {
      console.error(error)
    }
    this.processing = false
  }

  /**
   * Closes the dialog
   */
  close() {
    this.dialogRef.close()
  }
}
