<div class="wallet-content" *ngIf="customer" [nbSpinner]="processing">
  <div class="title">Wallet</div>
  <div class="content">
    <div class="card">
      <div class="card-title">Wallet balance</div>
      <div class="balance">${{ customer.data.balance }}</div>
    </div>
    <div class="amounts">
      <div class="amounts-title">Top up your wallet</div>
      <div class="amounts-second-title">Choose amount</div>
      <div class="mount">
        <span *ngFor="let amount of amounts" [ngClass]="{ active: selectedAmount === amount }" (click)="selectAmount(amount)">$ {{ amount }}</span>
        <input type="number" [(ngModel)]="customAmount" (keyup)="updateCustomAmount()" class="custom" placeholder="Custom amount" />
      </div>
      <div class="payment-methods" *ngIf="selectedAmount !== 0 || (!!customAmount && customAmount > 0)">
        <div class="amounts-second-title">Choose payment method</div>
        <div class="payment-methods-wrapper d-flex flex-row justify-content-start flex-wrap">
          <div
            class="payment-method d-flex flex-row justify-content-between align-items-center"
            *ngFor="let paymentMethod of paymentMethods.data"
            (click)="selectPaymentMethod(paymentMethod)"
            [ngClass]="{ active: selectedPaymentMethod === paymentMethod }"
          >
            <i [class]="'fab mr-2 fa-cc-' + paymentMethod.card.brand"></i>
            <div class="card-number d-flex flex-grow-1">•••• •••• •••• {{ paymentMethod.card.last4 }}</div>
            <div class="selected" *ngIf="selectedPaymentMethod === paymentMethod">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="10" fill="#00E096" />
                <path d="M7.99993 14.8001L5.19993 12.0001L4.2666 12.9334L7.99993 16.6667L15.9999 8.66673L15.0666 7.7334L7.99993 14.8001Z" fill="white" />
              </svg>
            </div>
          </div>
          <a *ngIf="canAddCreditcard()" (click)="topup(true)" class="new-card" role="button">+ Add New Card</a>
        </div>
      </div>
      <div class="top-up d-flex w-100 justify-content-center align-items-center" *ngIf="(selectedAmount !== 0 || (!!customAmount && customAmount > 0)) && !!selectedPaymentMethod">
        <button nbButton status="primary" (click)="topup()">Top up now</button>
      </div>
    </div>
  </div>
</div>
