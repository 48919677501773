import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
@Component({
  selector: 'mt-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnDestroy {
  private _destroy$ = new Subject()
  @Input()
  public label: string
  @Input()
  public control: FormControl
  @Input()
  public isReadonly

  constructor() {}

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  formatBlur() {
    this.control.setValue(this.control.value.trim())
  }

  /**
   * Get the error message from the control
   */
  get errorMessage() {
    let error = ''
    if (!this.control.errors) {
      return error
    }
    Object.keys(this.control.errors)
      .filter((k) => this.control.errors.hasOwnProperty(k))
      .map((k) => (this.control.errors[k] === true ? k : this.control.errors[k]))
      .forEach((k) => (error += k + '\t'))
    return error
  }
}
