import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Component, Inject, OnInit } from '@angular/core'
import { DialogData } from './dialog.interface'
@Component({
  selector: 'dialog-overview-example-dialog',
  styleUrls: ['./dialog.component.scss'],
  templateUrl: './dialog.component.html'
})
export class DialogOverviewExampleDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}

  proceed(confirm: 'confirm' | 'confirmTwo') {
    this.dialogRef.close(confirm)
  }
}
