import { Component, Input, OnInit, AfterViewChecked, ChangeDetectionStrategy } from '@angular/core'
import * as mapboxgl from 'mapbox-gl'
import { environment } from '@environments/environment'
import { Marker } from '@app/modules/shared/interfaces/marker.interface'
import { Checkin, ServerRepresentedEvent } from '@app/modules/events/models/event.model'
import { Coords } from '@app/modules/shared/interfaces/coords'
import { Feature, Geometry, Position } from 'geojson'

@Component({
  selector: 'ngx-mapbox-gl',
  templateUrl: './mapbox-gl.component.html',
  styleUrls: ['./mapbox-gl.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapboxGlComponent implements OnInit, AfterViewChecked {
  map: mapboxgl.Map
  markerRedIcon = 'https://library.micetribe.com/icons/pin.png'
  rtlTextPluginUrl: string = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js'
  mapMarkers: Marker[] = []
  imageLoaded: boolean = false
  address: string
  static isRtlPluginSet = false

  @Input() event: ServerRepresentedEvent
  @Input() addPadding: boolean = true
  // Style of the map
  @Input() style = 'mapbox://styles/mapbox/streets-v12'
  // Coordinates of the map center
  @Input() coords: Coords = [51.43749312816817, 25.325351337187563] // Doha Micetribe coordinates
  // Zoom level of the map
  @Input() zoom = 17
  // Title of the marker
  @Input() markerTitle = 'Your Event'
  // Array of markers
  @Input() markers: Marker[]

  @Input() height!: number
  constructor() {
    Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken').set(environment.mapboxToken)
  }

  ngOnChanges() {
    if (this.event) {
      const globalCheckin = this.event.checkins.find((loc: Checkin) => loc.slug === 'global')
      this.markerTitle = (globalCheckin.location_details.venue_name as string) || this.markerTitle
      this.zoom = (globalCheckin.location_details.map_zoom_level as number) || this.zoom
      this.address = globalCheckin.location_details.venue_address as string
      this.coords = ([globalCheckin.location_details.map_longitude, globalCheckin.location_details.map_latitude] as Coords) || this.coords
    }
  }

  ngOnInit(): void {
    this.mapMarkers = this.markers
      ? this.markers.map((marker: Marker) => ({ coords: marker.coords || this.coords, title: marker.title || this.markerTitle }))
      : [{ coords: this.coords, title: this.markerTitle }]
    setTimeout(() => {
      this.buildMap()
    }, 1)
  }

  ngAfterViewChecked() {
    if (this.map) {
      this.map.resize()
    }
  }

  /**
   * Builds the Mapbox GL map.
   */
  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: this.zoom,
      center: this.coords,
      pitch: 10
    })

    if (!MapboxGlComponent.isRtlPluginSet) {
      /**
       * Set the RTL text plugin for right-to-left language support.
       *
       * @param url The URL of the RTL text plugin.
       * @param callback The callback function to handle the plugin loading result.
       * @param lazy Whether to defer loading the plugin until necessary.
       */
      mapboxgl.setRTLTextPlugin(
        this.rtlTextPluginUrl,
        (error) => {
          if (error) {
            console.error('Failed to load the RTL text plugin:', error)
          }
        },
        true // Defers loading of the plugin until necessary, improving page load performance
      )
      MapboxGlComponent.isRtlPluginSet = true
    }

    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
    this.addRecenterControl(this.coords, this.zoom)

    this.map.on('load', () => {
      if (this.imageLoaded) return
      this.map.loadImage(this.markerRedIcon, (error, image) => {
        if (error) throw error
        this.map.addImage('custom-marker', image)

        this.map.addSource('points', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: this.mapMarkers.map((marker) => this.addMarker(marker))
          }
        })

        // Add a symbol layer
        this.map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'points',
          layout: {
            'icon-image': 'custom-marker',
            'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 1, 15, 0.5],
            'text-field': ['get', 'title'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 1.25],
            'text-anchor': 'top'
          }
        })
      })
      this.imageLoaded = true
    })
  }

  addRecenterControl(coords, zoom) {
    const disabledSrc = 'https://library.micetribe.com/icons/recenter-black.svg'
    const activeSrc = 'https://library.micetribe.com/icons/recenter.svg'

    const div = document.createElement('div')
    const img = document.createElement('img')
    img.src = disabledSrc
    img.setAttribute('width', '29px')
    img.style.padding = '2px'
    img.style.cursor = 'pointer'

    div.className = 'mapboxgl-ctrl mapboxgl-ctrl-group'
    div.appendChild(img)
    div.addEventListener('contextmenu', (e) => e.preventDefault())

    class HomeButton {
      onAdd(map) {
        div.addEventListener('click', () => {
          map.flyTo({
            center: coords,
            zoom: zoom,
            pitch: 10,
            animate: true
          })
        })
        map.on('moveend', () => {
          if (map.getCenter().lng.toFixed(7) == coords[0].toFixed(7) && map.getCenter().lat.toFixed(7) == coords[1].toFixed(7)) {
            img.src = disabledSrc
          } else {
            img.src = activeSrc
          }
        })

        return div
      }

      onRemove() {}
    }

    const homeButton = new HomeButton() as mapboxgl.IControl
    this.map.addControl(homeButton, 'bottom-right')
  }

  addMarker(marker: Marker): Feature<Geometry, { [name: string]: any }> {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: marker.coords as Position
      },
      properties: {
        title: marker.title
      }
    }
  }
}
