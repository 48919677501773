<div class="personas-list">
  <div class="personas-container d-flex">
    <div class="persona-item" *ngFor="let persona of personas" (click)="selectPersona(persona.slug)" [class.selected]="selectedPersona === persona.slug">
      <div class="persona-details d-flex flex-column justify-content-center align-items-center">
        <span class="persona-dot"></span>
        <span class="persona-name">{{ persona.name }}</span>
        <div>
          <img src="assets/images/persona-qrcode.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="no-match" *ngIf="!personas.length">This event has no available personas</div>
  </div>
</div>
