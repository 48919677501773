import { AbstractControl } from '@angular/forms'
import { AbstractValidator } from './abstract.validator'
import { ValidationTypes } from './validation.types'

export class CountryValidator implements AbstractValidator {
  static type = ValidationTypes.validateWith

  static validateWith() {
    return (control: AbstractControl) => {
      if (control.pristine || !control.value) {
        return null
      }
      const validExpression = /([\u0621-\u064Aa-zA-Zà-žÀ-Ž]{2,}\s)*[\u0621-\u064Aa-zA-Zà-žÀ-Ž]{2,}/g
      return validExpression.test(control.value.trim()) ? null : { countryName: `Please enter a valid country name` }
    }
  }
}
