<nb-layout [withScroll]="true" [class.largePaddingTop]="authService.user !== null && !authService.user['confirmed'] && !vcdRoute">
  <nb-layout-header
    class="h50"
    *ngIf="!publicMode && isLoggedIn"
    [ngStyle]="{ 'background-color': authService.user ? 'white' : 'transparent' }"
    fixed
    [class.largerHeading]="authService.user !== null && !authService.user['confirmed']"
  >
    <ngx-header> </ngx-header>
    <div class="alert confirm-alert px-2 m-0" *ngIf="authService.user !== null && !authService.user['confirmed']">
      <mat-icon class="mx-2"> warning </mat-icon>
      Please confirm your email {{ authService.user.email }}. <a (click)="resendConfirmation()" style="text-decoration: underline" class="mx-1"> Resend confirmation</a>
    </div>
  </nb-layout-header>
  <nb-layout-column>
    <div class="fb-customerchat" page_id="330351414098151"></div>
    <ng-template #dialog let-data let-ref="dialogRef">
      <div class="basics">
        <nb-card>
          <nb-card-header>
            <a class="float-right icon" (click)="ref.close()">
              <mat-icon style="cursor: pointer"> cancel </mat-icon>
            </a>
          </nb-card-header>
          <nb-card-body>
            <div class="container-fluid">
              <div class="row just-content-center">
                <ngx-data-privacy *ngIf="popStatus === 'dpr'"></ngx-data-privacy>
                <ngx-terms-and-conditions *ngIf="popStatus === 'tc'"></ngx-terms-and-conditions>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </ng-template>
    <router-outlet></router-outlet>
  </nb-layout-column>
  <nb-layout-footer *ngIf="!publicMode" fixed>
    <div class="footer-content">
      <div class="left-content">
        <a href="https://micetribe.com/terms-conditions" target="_blank" class="icon terms" style="color: #fff">Terms & Conditions</a>
        <a href="https://micetribe.com/privacy-policy" target="_blank" class="icon data-privacy" style="color: #fff">Data protection policy</a>
      </div>
      <div class="right-content d-flex flex-row">
        <ngx-videos-list></ngx-videos-list>
      </div>
    </div>
  </nb-layout-footer>
</nb-layout>
