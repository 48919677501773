<div class="content" [ngClass]="{ haveNoBorder: haveNoBorder }">
  <div class="videos-content">
    <img class="thumb" [src]="sanitizedThumbUrl" alt="" *ngIf="!item.load" />
    <div class="play" *ngIf="!item.load" (click)="startLoadingVideo($event)"></div>
    <iframe
      [id]="item.id"
      *ngIf="item.load"
      class="video-embed"
      type="text/html"
      width="276"
      height="255"
      [src]="sanitizedUrl"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      allowfullscreen
    ></iframe>
  </div>
  <div class="videos-title" (click)="startLoadingVideo($event)">
    <span>{{ item.title }}</span>
  </div>
</div>
