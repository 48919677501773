<div class="running-jobs-container">
  <owl-carousel-o *ngIf="percentageArray.length > 0" [options]="customOptions">
    <ng-template *ngFor="let statistic of percentageArray" carouselSlide>
      <div class="running-job__header__details d-flex align-items-center flex-column">
        <div class="header__details__invitations d-flex align-items-center justify-content-between">
          <div class="header__invitations">
            <span
              >Invitations: <span class="header__invitations__event-name">{{ statistic.eventName }}</span></span
            >
          </div>
          <div class="header__invitations__timer">
            <div>{{ statistic['Remaining Time']?.remaining_time }}</div>
          </div>
        </div>
        <div class="header__details__total-invites d-flex align-items-center justify-content-between">
          <div class="header__total-invites">
            <span>Total invites </span>
          </div>
          <div class="header__total-invites__timer">
            <div class="percentage d-flex align-items-ceneter justify-content-between">
              <span>{{ statistic['Total Invitations'].number }}</span> <span>({{ statistic['Total Invitations'].percentage }}%)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="running-jobs__body d-flex align-items-center flex-column">
        <div class="status" *ngFor="let name of ['Sent', 'Delivered', 'Opened', 'Clicked', 'Registered']">
          <div class="d-flex align-items-center justify-content-between">
            <span>{{ name }}</span>
            <div class="percentage d-flex align-items-ceneter justify-content-between">
              <span>{{ statistic[name].number }}</span> <span>({{ statistic[name].percentage }}%)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="running-jobs__footer d-flex flex-column">
        <div class="failed-status" *ngFor="let name of ['Bounced', 'Opted Out', 'Moved to spam']">
          <div class="d-flex align-items-center justify-content-between">
            <span class="state">{{ name }}</span>
            <div class="percentage d-flex align-items-ceneter justify-content-between">
              <span>{{ statistic[name].number }}</span> <span>({{ statistic[name].percentage }}%)</span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
