import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { WorkspaceRoleService } from '@app/modules/auth/services/workspace-role.service'
import { Workspace } from '@app/modules/workspaces/interfaces/workspace.interface'
import { InvoicePlansComponent } from '@auth/components/invoice-plans/invoice-plans.component'
import { NbDialogService } from '@nebular/theme'
import { format, parse } from 'date-fns'
import { BillingService } from '../../services/billing.service'
/**
 * Represents the current subscription plan with options to upgrade
 */
@Component({
  selector: 'ngx-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit {
  /**
   * Are we on basic plan?
   */
  basicPlan = true
  /**
   * Customer model
   */
  _customer = null
  /**
   * Sets the customer model and the basic plan
   */
  @Input()
  set customer(value) {
    this._customer = value
    if (!!this._customer) {
      this.basicPlan = !this._customer.data.subscriptions.length && this.workspace.plan_id === 1
    }
  }
  /**
   * The target workspace
   */
  workspace: Workspace
  /**
   * Gets the customer model
   */
  get customer() {
    return this._customer
  }
  /**
   * Creates the component and injects it's dependencies
   * @param dialogService NbDialogService
   * @param router Router
   * @param billingService BillingService
   * @param workspaceRole WorkspaceRoleService
   */
  constructor(private dialogService: NbDialogService, private router: Router, private billingService: BillingService, private workspaceRole: WorkspaceRoleService) {}

  ngOnInit(): void {
    this.workspace = this.billingService.workspace
  }

  /**
   * Opens the upgrade to Pro plan dialog
   */
  upgradeToPro() {
    this.dialogService.open(InvoicePlansComponent)
  }
  /**
   * Gets the customer plan details
   */
  get planDetails() {
    return this._customer.data.subscriptions.find((o) => o.status === 'active')
  }
  /**
   * Redirects the user to a component that redirects them to stripe to manage their plan
   */
  manageSubscription() {
    this.router.navigateByUrl('/subscription/billing')
  }
  /**
   * Gets the current subscription expiry date in a displayable format
   */
  get planExpiryDate() {
    return this.planDetails
      ? format(parse(this.planDetails.current_period_end, 'yyyy-MM-dd HH:mm:ss', Date.now()), 'dd MMM yyyy')
      : this.workspace.plan_expiry_date
      ? format(new Date(this.workspace.plan_expiry_date), 'dd MMM yyyy')
      : false
  }

  /**
   * Checks if user can edit subscription
   * @returns boolean
   */
  canEditSubscription(): boolean {
    return this.workspaceRole.canEditSubscription()
  }
}
