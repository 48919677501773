import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core'
import { Workspace } from '@workspaces/interfaces/workspace.interface'
import { Subject } from 'rxjs'
import { WorkspaceService } from '../../services/workspace.service'
import { AuthService } from '@auth/services/auth.service'
import { takeUntil, debounceTime } from 'rxjs/operators'

/**
 * Searches the current list of workspaces (Client side)
 */
@Component({
  selector: 'ngx-workspaces-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /** Search term */
  term: string = ''

  /**
   * List of workspaces that matches the search term
   */
  @Output()
  workspacesChanged: EventEmitter<Workspace[]> = new EventEmitter<Workspace[]>()
  /**
   * Indicator to show no more workspaces
   */
  @Output()
  noMoreWorkspaces: EventEmitter<boolean> = new EventEmitter<boolean>()
  /**
   * List of available workspaces
   */
  workspaces: Workspace[] = []
  /**
   * Current page of workspaces
   */
  page: number = 0
  /**
   * Indicator to stop retrieving more workspaces as per there is no more
   */
  stopRetrieving: boolean = false
  /**
   * Indicator to stop request for more workspaces while retrieving some
   */
  pendingRequest: boolean = false

  /**
   * Creates the component and injects it's dependencies
   * @param workspaceService Workspace Service
   * @param authService Auth Service
   */
  constructor(private workspaceService: WorkspaceService, private authService: AuthService) {
    this.workspaceService.spliceWorkspace$.pipe(takeUntil(this._destroy$)).subscribe((res: number) => {
      let workspaceIndex = this.workspaces.findIndex((workspace) => workspace.id === res)
      this.workspaces.splice(workspaceIndex, 1)
    })
  }

  /**
   * OnInit implementation - refreshes which means it gets a list of current workspaces on start
   */
  ngOnInit() {
    this.refresh()
  }

  /**
   * Filters current available workspaces and emits the result
   * @param term search term
   */
  search = (e) => {
    this.term = e.target.value
    this.page = 0
    this.workspaces = []
    this.stopRetrieving = false
    this.noMoreWorkspaces.emit(false)
    this.refresh()
  }

  /**
   * refreshes the workspace list by retrieving it from backend and storing it
   */
  refresh() {
    if (this.pendingRequest || this.stopRetrieving) return
    this.pendingRequest = true
    if (!this.term && !this.workspaces.length) this.page = 0
    const subscription =
      this.workspaceService.getWorkspaces(null, ++this.page, this.term)
    if (!!this.authService.currentParentWorkspace) {
      this.authService.currentWorkspace = this.authService.currentParentWorkspace
    }
    subscription.pipe(debounceTime(500), takeUntil(this._destroy$)).subscribe((response: any) => {
      const workspaces = response.data
      if (response.current_page === response.last_page) {
        this.stopRetrieving = true
        this.noMoreWorkspaces.emit(true)
      }
      if (!workspaces.length) this.noMoreWorkspaces.emit(false)
      for (let workspace of workspaces) {
        this.workspaces.push(workspace)
      }
      this.workspacesChanged.emit(this.workspaces)
      this.pendingRequest = false
    })
  }
}
