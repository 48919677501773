<form [formGroup]="form" (ngSubmit)="update()" *ngIf="workspace">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 form-content">
        <nb-card>
          <nb-card-body>
            <nb-form-field>
              <mat-icon nbPrefix>cases</mat-icon>
              <input type="text" fullWidth nbInput formControlName="name" placeholder="Workspace Name" />
              <mat-icon nbSuffix matTooltip="Help you to find specific workspace by its name" matTooltipPosition="above">help_outline</mat-icon>
            </nb-form-field>
            <nb-form-field>
              <mat-icon nbPrefix>cases</mat-icon>
              <input type="text" fullWidth nbInput formControlName="slug" placeholder="Workspace acronym" />
              <mat-icon nbSuffix matTooltip="Help you to find specific workspace by its acronym" matTooltipPosition="above">help_outline</mat-icon>
            </nb-form-field>

            <button nbButton fullWidth status="primary" class="mt-4">Save</button>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </div>
</form>
