import { Component, OnInit, TemplateRef, OnDestroy, ViewChild } from '@angular/core'
import { NbDialogRef, NbIconLibraries } from '@nebular/theme'
import { AuthService } from '@auth/services/auth.service'
import { NbDialogService } from '@nebular/theme'
import { ToastrService } from './services/toastr.service'
import { NewVersionModalComponent } from '@auth/components/new-version-modal/new-version-modal.component'
import { environment } from '@environments/environment.prod'
import { interval, Subject } from 'rxjs'
import { filter, startWith, takeUntil } from 'rxjs/operators'
import { NavigationEnd, Router } from '@angular/router'

declare var gtag

/**
 * Main application bootstrap component
 */
@Component({
  selector: 'ngx-app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /**
   * Whether we should display the sidebar
   */
  showSidebar = false
  /**
   * Displayed popup modal status (For privacy policy and terms of services)
   */
  popStatus
  /**
   * Application navigation menu
   */
  menu = []
  /**
   * Current navigated route
   */
  currentUrl: string
  /**
   * public mode is used for venue capacity dashboards display that allows the authenticated users to change it
   */
  publicMode = true
  /**
   * Displayed modal dialog reference
   */
  @ViewChild('dialog') dialog: TemplateRef<any>
  /**
   * Whether the user is logged in or not
   */
  get loggedIn() {
    return !!this.authService.user && !!this.authService.currentWorkspace
  }
  /**
   * Creates the component & injects it's dependencies
   * @param router Router
   * @param dialogService Dialog Service
   * @param iconLibraries Icon libraries
   * @param authService AuthService
   * @param toastr ToastrService
   */
  constructor(
    private router: Router,
    private dialogService: NbDialogService,
    private iconLibraries: NbIconLibraries,
    public authService: AuthService,
    private toastr: ToastrService
  ) {
    // window.location.href = 'https://micetribe.com/under-maintenance/'
    if (environment.gaMeasurementId) {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaMeasurementId
      document.head.prepend(script)
    }
  }
  /**
   * OnInit implementation also setups the icons libraries
   */
  ngOnInit() {
    this.authService.displayPrivacy$
      .pipe(
        takeUntil(this._destroy$),
        filter((result) => result)
      )
      .subscribe(() => {
        this.open(this.dialog, 'dpr')
      })

    this.authService.displayTerms$
      .pipe(
        takeUntil(this._destroy$),
        filter((result) => result)
      )
      .subscribe(() => {
        this.open(this.dialog, 'tc')
      })
    this.router.events
      .pipe(
        takeUntil(this._destroy$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        try {
          if (environment.gaMeasurementId) gtag('config', environment.gaMeasurementId, { page_path: event.urlAfterRedirects })
        } catch (error) {
          console.error(error.message)
        }
        this.publicMode = event.url.startsWith('/capacity') || event.url.endsWith('/venue-capacity')
      })
    this.iconLibraries.registerFontPack('font-awesome', {
      packClass: 'fas',
      iconClassPrefix: 'fa'
    })
    this.iconLibraries.registerFontPack('brands', {
      packClass: 'fab',
      iconClassPrefix: 'fa'
    })
    this.iconLibraries.registerSvgPack('exports', {
      excel: '<img src="https://res.cloudinary.com/contactless/image/upload/v1613303999/planning-system/xlsx.svg" height="15px" width="15px">',
      benchmark: '<img src="https://res.cloudinary.com/contactless/image/upload/v1613304299/planning-system/benchmark.svg"  height="15px" width="15px">',
      database: '<img src="https://res.cloudinary.com/contactless/image/upload/v1649119987/planning-system/upload_file_white_24dp.svg"  height="20px" width="20px">'
    })
    this.checkVersion()

    this.iconLibraries.setDefaultPack('font-awesome')
    this.authService.userChange.pipe(takeUntil(this._destroy$)).subscribe((next) => {
      if (!!next && next?.is_admin) {
        this.showSidebar = true
      } else {
        if (!!next && !!this.authService.currentWorkspace) {
          this.showSidebar = true
        } else {
          this.showSidebar = false
        }
      }
    })
    this.authService.currentWorkspaceChange.pipe(takeUntil(this._destroy$)).subscribe((next) => {
      this.menu = [
        {
          title: 'Workspaces',
          icon: 'briefcase',
          link: '/workspaces/list'
        },
        {
          title: 'Billing',
          icon: 'credit-card',
          link: '/billing'
        },
        {
          title: 'Settings',
          icon: 'settings',
          link: '/app-settings'
        }
      ]
    })
  }

  /**
   * New version modal reference
   */
  private _modalRef: NbDialogRef<NewVersionModalComponent>
  /**
   * Checks the application version on development, staging, live environment
   * every 60 seconds on staging and development
   * increased to 600 seconds (10 minutes) on live environments
   * @returns void
   */
  checkVersion() {
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      return
    }

    interval(60000)
      .pipe(
        takeUntil(this._destroy$),
        startWith(0),
        filter(() => !this._modalRef?.componentRef?.instance)
      )
      .subscribe(() => {
        this.authService.getLiveVersion().subscribe((data) => {
          if (data.version !== environment.appVersion) {
            this.authService._version = data.version
            this._modalRef = this.dialogService.open(NewVersionModalComponent)
          }
        })
      })
  }

  /**
   * Opens a ToS or PP dialog
   * @param dialog Dialog to open or template reference
   * @param status Dialog status - used for tos and pp
   */
  open(dialog: TemplateRef<any>, status: string) {
    this.popStatus = status
    this.dialogService.open(dialog)
  }

  /**
   * Resend an unvalidated user confirmation email
   */
  resendConfirmation() {
    this.authService
      .confirmRegisteredEmail(this.authService.user['email'])
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          this.toastr.success('Email confirmation sent ', '')
        },
        (error) => {
          this.toastr.error('Failed to send email confirmation', '')
        }
      )
  }

  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * tests whether the current route is a venue capacity dashboard route
   */
  get vcdRoute() {
    return /\/events\/.*\/venue-capacity/.test(this.router.url) || /\/capacity\/.*\/.*\/.*\/.*/.test(this.router.url)
  }

  /**
   * gets whether the user is logged in or not
   */
  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn
  }
}
