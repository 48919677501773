import { Component, Input } from '@angular/core'
/**
 * Represents a list of payment methods
 */
@Component({
  selector: 'ngx-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent {
  /**
   * Customer model
   */
  @Input()
  customer: any
  /**
   * List of payment methods
   */
  @Input()
  paymentMethods: any
}
