import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ICountWithPercentage, IJobStatistics, IRunningJob } from './model/running-jobs.interface'
import { OwlOptions } from 'ngx-owl-carousel-o'
import { interval, Subscription } from 'rxjs'
import { map, takeWhile } from 'rxjs/operators'
import { EventService } from '@app/modules/events/services/event.service'
@Component({
  selector: 'ngx-running-jobs',
  templateUrl: './running-jobs.component.html',
  styleUrls: ['./running-jobs.component.scss']
})
export class RunningJobsComponent implements OnInit, OnDestroy {
  percentageArray: IJobStatistics[]
  @Input() statisticsArray: IRunningJob[]
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 200,
    nav: true,
    items: 1,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>']
  }
  countdowns: Subscription[] = []

  constructor(private eventService: EventService) {}

  ngOnInit(): void {
    this.percentageArray = this.calculatePercentages(this.statisticsArray)
    this.initializeCountdownsAndFetchEventNames(this.statisticsArray)
  }

  /**
   * Initializes countdowns for each job and fetches event names.
   *
   * For each job, it starts a countdown from its creation time plus five hours to the current time.
   * It also fetches the name of the event associated with the job.
   * When the countdown for a job reaches zero, the job is removed from the `statisticsArray` and `percentageArray`.
   *
   * @param {IRunningJob[]} statisticsArray - Array of jobs to initialize countdowns for.
   * @returns {Promise<void>}
   */
  initializeCountdownsAndFetchEventNames(statisticsArray: IRunningJob[]): void {
    statisticsArray.forEach((item, index) => {
      // fetch event name
      this.eventService.getEventById(item.event_id).subscribe((event) => {
        this.percentageArray[index].eventName = event.name
        this.statisticsArray[index].eventName = event.name
      })

      const createdAt = new Date(item.created_at).getTime()
      const now = Date.now()
      const countdownDuration = 5 * 60 * 60 * 1000
      const diffInSeconds = Math.floor((createdAt + countdownDuration - now) / 1000)

      this.countdowns[index] = interval(1000)
        .pipe(
          map((i) => diffInSeconds - i),
          takeWhile((time) => time >= 0)
        )
        .subscribe((time) => {
          let remainingTime
          if (time === 0) {
            remainingTime = '00:00:00'
            this.statisticsArray[index].remaining_time = remainingTime
            this.percentageArray[index]['Remaining Time'] = { number: 0, percentage: 0, remaining_time: remainingTime }
            console.log(`Countdown for item ${index}: ${this.statisticsArray[index].remaining_time}`)
          } else {
            const hours = Math.floor(time / 3600)
            const minutes = Math.floor((time - hours * 3600) / 60)
            const seconds = time - hours * 3600 - minutes * 60
            remainingTime = `${hours.toString().padStart(2, '0')}:` + `${minutes.toString().padStart(2, '0')}:` + `${seconds.toString().padStart(2, '0')}`
            this.statisticsArray[index].remaining_time = remainingTime
            this.percentageArray[index]['Remaining Time'] = { number: 0, percentage: 0, remaining_time: remainingTime }
          }

          if (time === 0) {
            this.statisticsArray = this.statisticsArray.filter((_, i) => i !== index)
            this.percentageArray = this.percentageArray.filter((_, i) => i !== index)
            this.countdowns[index].unsubscribe()
            this.countdowns = this.countdowns.filter((_, i) => i !== index)
          }
        })
    })
  }

  ngOnDestroy(): void {
    this.countdowns.forEach((subscription) => subscription.unsubscribe())
  }

  /**
   * Calculates the percentages of various events (sent, delivered, opened, etc.) with respect to the total invitations.
   * Distributes fractions to make the total percentage 100%.
   *
   * @param {IRunningJob[]} statisticsArray - Array of objects containing count of various events for different jobs.
   *
   * @returns {Object[]} An array of objects containing the percentage and count of various events for each job,
   * with keys mapped according to the 'keyMapping' object.
   */
  calculatePercentages(statisticsArray: IRunningJob[]) {
    const keyMapping = {
      sent_count: 'Sent',
      delivered_count: 'Delivered',
      opened_count: 'Opened',
      clicked_count: 'Clicked',
      registration_count: 'Registered',
      bounced_count: 'Bounced',
      opted_out_count: 'Opted Out',
      spam_count: 'Moved to spam'
    }

    const result = statisticsArray.map((item) => {
      const counts = Object.keys(keyMapping)

      const totalInvitations: ICountWithPercentage = {
        number: item.requested_invite_count,
        percentage: 100
      }

      const results: { [key: string]: ICountWithPercentage } = {
        'Total Invitations': totalInvitations
      }

      let previousCount = totalInvitations.number

      counts.forEach((countName) => {
        const count = item[countName]

        let base = previousCount
        if (['bounced_count', 'opted_out_count', 'spam_count'].includes(countName)) {
          base = totalInvitations.number
        }

        const percentage = base === 0 ? 0 : Math.round((count / base) * 100)

        results[keyMapping[countName]] = { number: count, percentage }
        previousCount = count
      })

      return results
    })

    return result
  }
}
