import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
/**
 * Represents a tutorial video item
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ngx-video-item',
  templateUrl: './video-item.component.html',
  styleUrls: ['./video-item.component.scss']
})
export class VideoItemComponent {
  /**
   * Whether the video item have a border
   */
  @Input()
  haveNoBorder = false
  /**
   * Creates the component and injects it's dependencies
   * @param urlSanitizer DomSanitizer
   */
  constructor(private urlSanitizer: DomSanitizer) {}
  /**
   * Video item model
   */
  @Input()
  item: any
  /**
   * Emits the playing id
   */
  @Output()
  playingIdChanged: EventEmitter<string> = new EventEmitter<string>()

  /**
   * Gets video sanitized url
   */
  get sanitizedUrl(): SafeResourceUrl {
    return this.urlSanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.item.id}?autoplay=1`)
  }
  /**
   * Gets video sanitized image
   */
  get sanitizedThumbUrl(): SafeResourceUrl {
    return this.urlSanitizer.bypassSecurityTrustResourceUrl(`https://i.ytimg.com/vi/${this.item.id}/hqdefault.jpg`)
  }
  /**
   * Starts playing the video
   * @param event MouseEvent
   * @returns void
   */
  startLoadingVideo(event: MouseEvent) {
    event.stopPropagation()
    event.preventDefault()
    if (this.item.load) {
      return
    }

    this.item.load = true
    this.playingIdChanged.emit(this.item.id)
  }
}
