<nb-card>
  <nb-card-header>New version available!</nb-card-header>
  <nb-card-body>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <span class="my-2">
        <svg width="167" height="188" viewBox="0 0 167 188" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24.2308 24.1342C23.9631 23.7618 23.7286 23.2553 23.7657 22.6477L23.836 21.4647L22.6517 21.3942L22.5814 22.5763C22.5102 23.7435 23.0656 24.7909 23.9306 25.5584C23.9615 26.2962 24.264 27.2365 25.2048 28.2995C25.7826 28.9524 26.3932 29.3379 26.9014 29.5654L24.6688 31.5379C24.5376 31.1127 24.2984 30.6251 23.8699 30.141C22.4977 28.5906 21.2122 28.5795 20.568 28.7109C20.5614 28.7122 20.5547 28.7136 20.5481 28.715C19.1856 28.0622 17.4169 28.4899 16.236 29.535L15.6832 30.0234L16.4687 30.9125L17.0219 30.4238C17.5883 29.9225 18.3362 29.6086 19.064 29.5934C18.2387 30.518 18.076 31.8734 18.8401 32.7353C19.4903 33.4688 20.6169 33.5375 21.3515 32.8885C22.1372 32.1943 22.2802 31.1546 21.7684 29.9875C22.1692 30.156 22.5781 30.4714 22.9815 30.9273C23.5362 31.554 23.6279 32.1568 23.6347 32.4516L19.6712 35.9535L35.1999 42.195L30.8816 26.0488L27.9177 28.6675C27.5575 28.5822 26.8042 28.3165 26.0932 27.5133C25.7702 27.1483 25.5253 26.7853 25.3611 26.4332C25.9752 26.6699 26.615 26.7605 27.2047 26.6779C28.5839 26.4848 29.211 25.599 29.2622 24.7582C29.3341 23.5808 28.2986 22.4573 26.6063 22.5737C25.5897 22.6435 24.6798 23.248 24.2308 24.1342ZM20.515 30.132C20.8453 30.7174 21.118 31.5117 20.5659 31.9994C20.3208 32.2159 19.9449 32.1931 19.7279 31.9482C19.2919 31.4565 19.6241 30.4397 20.3987 30.022C20.4392 30.0567 20.478 30.0933 20.515 30.132ZM27.4202 30.6902L26.9976 37.6196L25.2573 36.9201L25.5357 32.3552L27.4202 30.6902ZM21.8975 35.5697L24.2794 33.4651L24.0971 36.4538L21.8975 35.5697ZM31.41 39.3931L31.7466 33.8748L33.4408 40.2092L31.41 39.3931ZM30.7787 30.2558L30.2499 38.9267L28.1577 38.0859L28.6765 29.5802L30.2308 28.207L30.7787 30.2558ZM28.076 24.5795C28.1246 25.0455 27.7181 25.408 27.0403 25.5029C26.4585 25.5844 25.7678 25.3953 25.164 25.0083C25.3404 24.2978 25.9735 23.8064 26.6877 23.7572C27.5763 23.6961 28.0302 24.1406 28.076 24.5795Z"
            fill="#FFCB08"
          />
          <path
            d="M19.6332 23.4638C19.6929 22.4855 18.9446 21.6409 17.9651 21.5811C16.9856 21.5214 16.1402 22.2688 16.0806 23.2471C16.0209 24.2255 16.7692 25.0701 17.7486 25.1298C18.7281 25.1895 19.5735 24.4422 19.6332 23.4638ZM17.2648 23.3193C17.2846 22.994 17.5664 22.7454 17.8929 22.7653C18.2194 22.7852 18.4688 23.0662 18.449 23.3916C18.4291 23.717 18.1474 23.9655 17.8209 23.9456C17.4944 23.9257 17.2449 23.6447 17.2648 23.3193Z"
            fill="#FFCB08"
          />
          <path d="M20.2907 25.6761L20.3628 24.4939L21.547 24.5661L21.4749 25.7483L20.2907 25.6761Z" fill="#FFCB08" />
          <path d="M17.7624 27.5029L17.8345 26.3207L19.0187 26.3929L18.9466 27.5752L17.7624 27.5029Z" fill="#FFCB08" />
          <path d="M21.9345 27.9564L22.0066 26.7742L23.1908 26.8464L23.1187 28.0287L21.9345 27.9564Z" fill="#FFCB08" />
          <path
            d="M162.262 30.5432C162.64 30.2825 163.15 30.0575 163.757 30.1059L164.939 30.1983L165.031 29.0155L163.851 28.9232C162.685 28.8302 161.627 29.3659 160.844 30.2164C160.106 30.2336 159.16 30.5185 158.08 31.4393C157.416 32.0049 157.019 32.6081 156.782 33.1119L154.852 30.843C155.279 30.7197 155.771 30.4896 156.263 30.0703C157.839 28.7273 157.874 27.4421 157.755 26.7956C157.754 26.789 157.752 26.7822 157.751 26.7756C158.429 25.4256 158.035 23.6492 157.012 22.449L156.534 21.8872L155.63 22.656L156.108 23.2182C156.599 23.7939 156.899 24.5474 156.9 25.2754C155.991 24.433 154.639 24.245 153.763 24.993C153.018 25.6294 152.928 26.7545 153.563 27.5011C154.243 28.2996 155.28 28.462 156.456 27.972C156.28 28.3696 155.957 28.7725 155.494 29.1674C154.857 29.7103 154.252 29.7907 153.957 29.792L150.53 25.7639L144 41.1734L160.224 37.1571L157.661 34.1448C157.753 33.7864 158.033 33.0381 158.849 32.3423C159.22 32.0261 159.588 31.788 159.943 31.6305C159.694 32.24 159.592 32.878 159.664 33.4692C159.831 34.8517 160.705 35.4952 161.545 35.5621C162.72 35.6559 163.863 34.6416 163.778 32.9474C163.727 31.9297 163.14 31.0086 162.262 30.5432ZM156.335 26.7161C155.743 27.0354 154.944 27.2932 154.467 26.7322C154.255 26.4831 154.285 26.1076 154.534 25.8952C155.033 25.4685 156.044 25.8197 156.447 26.6019C156.412 26.6418 156.374 26.6799 156.335 26.7161ZM155.648 33.6097L148.728 33.0578L149.459 31.3309L154.018 31.6945L155.648 33.6097ZM150.872 27.9969L152.932 30.4177L149.947 30.1797L150.872 27.9969ZM146.872 37.4365L152.383 37.876L146.018 39.4516L146.872 37.4365ZM156.02 36.9758L147.36 36.2852L148.24 34.2091L156.734 34.8865L158.078 36.4661L156.02 36.9758ZM161.745 34.3794C161.279 34.4194 160.924 34.0061 160.841 33.3266C160.771 32.7435 160.973 32.0564 161.371 31.4599C162.078 31.6496 162.558 32.2917 162.593 33.0067C162.638 33.8963 162.185 34.3418 161.745 34.3794Z"
            fill="#FFCE1A"
          />
          <path
            d="M163.018 25.9589C163.995 26.0368 164.854 25.3044 164.932 24.3262C165.01 23.348 164.278 22.4888 163.301 22.4109C162.324 22.333 161.466 23.0654 161.388 24.0436C161.31 25.0218 162.041 25.881 163.018 25.9589ZM163.207 23.5936C163.532 23.6195 163.775 23.9059 163.749 24.2319C163.723 24.558 163.438 24.8022 163.113 24.7762C162.788 24.7503 162.544 24.464 162.57 24.1379C162.596 23.8119 162.882 23.5677 163.207 23.5936Z"
            fill="#FFCE1A"
          />
          <path d="M160.794 26.575L161.975 26.6691L161.881 27.8518L160.7 27.7576L160.794 26.575Z" fill="#FFCE1A" />
          <path d="M159.015 24.0131L160.196 24.1072L160.101 25.2899L158.921 25.1957L159.015 24.0131Z" fill="#FFCE1A" />
          <path d="M158.484 28.176L159.664 28.2701L159.57 29.4528L158.389 29.3586L158.484 28.176Z" fill="#FFCE1A" />
          <g clip-path="url(#clip0)">
            <path
              d="M15.7572 114.063L12.8 113.823L14.4087 116.316L13.2671 119.054L16.135 118.295L18.3866 120.227L18.5504 117.264L21.0836 115.72L18.3169 114.649L17.6309 111.762L15.7572 114.063ZM18.4461 115.955L17.4144 116.585L17.3476 117.791L16.4305 117.004L15.2624 117.314L15.7274 116.198L15.0722 115.183L16.2766 115.28L17.0398 114.343L17.3193 115.519L18.4461 115.955Z"
              fill="#FFDA5A"
            />
            <path
              d="M25.9609 118.309L27.1837 116.009L29.7711 115.713L27.9621 113.84L28.4799 111.287L26.1391 112.429L23.8717 111.148L24.234 113.727L22.3149 115.487L24.8796 115.94L25.9609 118.309ZM25.4802 114.174L25.3625 113.336L26.099 113.752L26.8593 113.382L26.6911 114.211L27.2787 114.819L26.4383 114.915L26.0411 115.662L25.6899 114.893L24.8569 114.746L25.4802 114.174Z"
              fill="#FFDA5A"
            />
            <path
              d="M20.8411 123.5L19.4025 121.329L18.5545 123.791L16.0453 124.488L18.125 126.056L18.0129 128.658L20.1463 127.164L22.5862 128.075L21.8249 125.584L23.445 123.545L20.8411 123.5ZM20.5241 125.338L20.7714 126.147L19.9789 125.851L19.286 126.336L19.3224 125.491L18.6469 124.982L19.4618 124.755L19.7373 123.955L20.2046 124.66L21.0504 124.675L20.5241 125.338Z"
              fill="#FFDA5A"
            />
            <path d="M19.7212 118.593L20.3754 117.62L29.0197 123.432L28.3654 124.405L19.7212 118.593Z" fill="#FFDA5A" />
            <path d="M29.955 121.984L27.4852 117.717L28.4998 117.129L30.9695 121.397L29.955 121.984Z" fill="#FFDA5A" />
            <path d="M22.9539 126.344L23.1534 125.188L26.8 125.818L26.6004 126.973L22.9539 126.344Z" fill="#FFDA5A" />
            <path
              d="M10.8069 120.078C11.4425 119.346 11.3643 118.233 10.6325 117.598C9.90077 116.962 8.78833 117.04 8.15272 117.772C7.51711 118.504 7.59533 119.616 8.32708 120.252C9.05883 120.888 10.1713 120.809 10.8069 120.078ZM9.03744 118.541C9.24931 118.297 9.62012 118.271 9.86404 118.482C10.108 118.694 10.134 119.065 9.92216 119.309C9.71029 119.553 9.33948 119.579 9.09556 119.367C8.85164 119.155 8.82557 118.785 9.03744 118.541Z"
              fill="#FFDA5A"
            />
            <path
              d="M21.9505 108.44C22.7273 107.545 22.6317 106.186 21.7374 105.409C20.843 104.632 19.4834 104.728 18.7065 105.622C17.9297 106.516 18.0253 107.876 18.9196 108.653C19.814 109.43 21.1736 109.334 21.9505 108.44ZM19.5912 106.39C19.9444 105.984 20.5624 105.94 20.9689 106.294C21.3754 106.647 21.4189 107.265 21.0658 107.671C20.7127 108.078 20.0946 108.121 19.6881 107.768C19.2816 107.415 19.2381 106.797 19.5912 106.39Z"
              fill="#FFDA5A"
            />
            <path
              d="M31.4077 116.137C30.7721 116.869 30.8503 117.981 31.5821 118.617C32.3139 119.252 33.4263 119.174 34.0619 118.442C34.6975 117.711 34.6193 116.598 33.8875 115.963C33.1558 115.327 32.0434 115.405 31.4077 116.137ZM33.1772 117.674C32.9653 117.918 32.5945 117.944 32.3506 117.732C32.1067 117.52 32.0806 117.149 32.2925 116.906C32.5043 116.662 32.8751 116.636 33.1191 116.847C33.363 117.059 33.3891 117.43 33.1772 117.674Z"
              fill="#FFDA5A"
            />
            <path d="M14.4061 122.373L14.3239 121.204L15.4927 121.122L15.5749 122.29L14.4061 122.373Z" fill="#FFDA5A" />
            <path d="M20.3703 112.494L20.2881 111.325L21.457 111.243L21.5391 112.411L20.3703 112.494Z" fill="#FFDA5A" />
          </g>
          <g clip-path="url(#clip1)">
            <path
              d="M156.773 113.29L157.359 110.381L154.694 111.685L152.11 110.229L152.526 113.166L150.342 115.174L153.264 115.686L154.499 118.384L155.889 115.763L158.836 115.421L156.773 113.29ZM154.576 115.737L154.073 114.638L152.883 114.43L153.772 113.612L153.603 112.415L154.656 113.008L155.741 112.477L155.502 113.662L156.343 114.53L155.142 114.669L154.576 115.737Z"
              fill="#FFDA5A"
            />
            <path
              d="M151.354 122.922L153.493 124.407L153.482 127.011L155.556 125.436L158.029 126.251L157.172 123.792L158.711 121.691L156.108 121.747L154.585 119.634L153.834 122.127L151.354 122.922ZM155.517 122.932L156.362 122.914L155.862 123.596L156.141 124.395L155.338 124.13L154.664 124.642L154.667 123.796L153.973 123.313L154.778 123.055L155.022 122.245L155.517 122.932Z"
              fill="#FFDA5A"
            />
            <path
              d="M146.803 117.226L149.128 116.053L146.783 114.921L146.386 112.347L144.584 114.228L142.014 113.81L143.246 116.104L142.054 118.42L144.616 117.957L146.45 119.806L146.803 117.226ZM145.015 116.695L144.182 116.845L144.569 116.093L144.169 115.348L145.004 115.483L145.589 114.872L145.718 115.708L146.48 116.076L145.725 116.457L145.61 117.295L145.015 116.695Z"
              fill="#FFDA5A"
            />
            <path d="M151.807 116.692L152.696 117.457L145.906 125.356L145.016 124.591L151.807 116.692Z" fill="#FFDA5A" />
            <path d="M147.234 126.455L151.762 124.505L152.226 125.582L147.697 127.532L147.234 126.455Z" fill="#FFDA5A" />
            <path d="M143.729 118.989L144.853 119.323L143.798 122.87L142.674 122.536L143.729 118.989Z" fill="#FFDA5A" />
            <path
              d="M151.383 107.665C152.035 108.382 153.149 108.436 153.866 107.784C154.584 107.132 154.637 106.018 153.985 105.301C153.334 104.584 152.22 104.53 151.502 105.182C150.785 105.834 150.732 106.948 151.383 107.665ZM153.118 106.089C153.335 106.328 153.317 106.7 153.078 106.917C152.839 107.134 152.468 107.116 152.251 106.877C152.033 106.638 152.051 106.267 152.29 106.049C152.529 105.832 152.901 105.85 153.118 106.089Z"
              fill="#FFDA5A"
            />
            <path
              d="M161.627 120.103C162.423 120.979 163.785 121.045 164.662 120.248C165.538 119.451 165.604 118.09 164.807 117.213C164.011 116.336 162.649 116.271 161.772 117.068C160.895 117.864 160.83 119.226 161.627 120.103ZM163.94 118.001C164.302 118.4 164.272 119.019 163.874 119.381C163.475 119.743 162.856 119.713 162.494 119.315C162.132 118.916 162.162 118.297 162.56 117.935C162.959 117.573 163.578 117.603 163.94 118.001Z"
              fill="#FFDA5A"
            />
            <path
              d="M152.869 128.587C152.217 127.869 151.103 127.816 150.385 128.468C149.668 129.119 149.615 130.233 150.266 130.951C150.918 131.668 152.032 131.722 152.749 131.07C153.467 130.418 153.52 129.304 152.869 128.587ZM151.134 130.163C150.917 129.924 150.934 129.552 151.173 129.335C151.413 129.118 151.784 129.136 152.001 129.375C152.218 129.614 152.201 129.985 151.961 130.202C151.722 130.42 151.351 130.402 151.134 130.163Z"
              fill="#FFDA5A"
            />
            <path d="M148.68 110.969L149.85 111.025L149.794 112.195L148.624 112.139L148.68 110.969Z" fill="#FFDA5A" />
            <path d="M157.787 118.056L158.958 118.112L158.902 119.282L157.731 119.226L157.787 118.056Z" fill="#FFDA5A" />
          </g>
          <g clip-path="url(#clip2)">
            <path
              d="M85.1958 6.95775L81.6014 2.23663L80.0406 7.96126L74.4399 9.92078L79.4021 13.1743L79.535 19.1064L84.1626 15.3925L89.8455 17.0993L87.7434 11.5504L91.1227 6.67313L85.1958 6.95775ZM85.9713 13.4886L83.6566 12.7935L81.7716 14.3062L81.7175 11.89L79.6964 10.5648L81.9777 9.76665L82.6134 7.43493L84.0774 9.35783L86.4915 9.24196L85.1151 11.2286L85.9713 13.4886Z"
              fill="#FFDA5A"
            />
            <path
              d="M92.5087 27.817L97.5646 26.5647L101.462 30.0198L101.834 24.8244L106.324 22.1851L101.498 20.2263L100.375 15.1402L97.0211 19.1249L91.837 18.6208L94.5902 23.0422L92.5087 27.817ZM98.0207 21.5769L99.1102 20.2827L99.4747 21.9347L101.042 22.571L99.5839 23.4282L99.4632 25.1157L98.1972 23.9934L96.5551 24.4002L97.2311 22.8493L96.3369 21.4132L98.0207 21.5769Z"
              fill="#FFDA5A"
            />
            <path
              d="M77.9432 27.1322L79.2521 22.0909L74.4544 24.1184L70.0643 21.3157L70.5101 26.5052L66.488 29.8144L71.5611 30.9941L73.4655 35.842L76.155 31.3816L81.3542 31.0687L77.9432 27.1322ZM74.7842 29.1161L73.9107 30.5649L73.2921 28.9903L71.6443 28.6071L72.9508 27.5322L72.8059 25.8466L74.2319 26.757L75.7903 26.0984L75.3651 27.7358L76.4731 29.0144L74.7842 29.1161Z"
              fill="#FFDA5A"
            />
            <path d="M83.7494 18.91L86.0717 18.5838L88.9702 39.2145L86.6479 39.5408L83.7494 18.91Z" fill="#FFDA5A" />
            <path d="M92.3757 38.671L95.4267 29.2944L97.656 30.0198L94.605 39.3964L92.3757 38.671Z" fill="#FFDA5A" />
            <path d="M76.533 34.0766L78.5192 32.8299L82.4539 39.0984L80.4677 40.3451L76.533 34.0766Z" fill="#FFDA5A" />
            <path
              d="M69.63 7.62648C71.5683 7.59481 73.1194 5.99214 73.0878 4.05388C73.0561 2.11562 71.4534 0.564495 69.5152 0.596171C67.5769 0.627847 66.0258 2.23052 66.0574 4.16877C66.0891 6.10703 67.6918 7.65816 69.63 7.62648ZM69.5535 2.93961C70.1995 2.92905 70.7338 3.44609 70.7443 4.09218C70.7549 4.73826 70.2378 5.27249 69.5918 5.28305C68.9457 5.2936 68.4114 4.77656 68.4009 4.13048C68.3903 3.48439 68.9074 2.95017 69.5535 2.93961Z"
              fill="#FFDA5A"
            />
            <path
              d="M101.839 8.66281C104.208 8.62409 106.104 6.66528 106.065 4.29629C106.026 1.92731 104.067 0.0314907 101.698 0.0702061C99.3294 0.108921 97.4336 2.06774 97.4723 4.43672C97.511 6.8057 99.4698 8.70152 101.839 8.66281ZM101.737 2.41364C102.813 2.39605 103.704 3.25778 103.721 4.33459C103.739 5.4114 102.877 6.30177 101.8 6.31937C100.724 6.33697 99.8333 5.47523 99.8157 4.39842C99.7981 3.32161 100.66 2.43124 101.737 2.41364Z"
              fill="#FFDA5A"
            />
            <path
              d="M103.018 33.0218C101.08 33.0535 99.5289 34.6561 99.5605 36.5944C99.5922 38.5327 101.195 40.0838 103.133 40.0521C105.071 40.0204 106.623 38.4178 106.591 36.4795C106.559 34.5412 104.956 32.9901 103.018 33.0218ZM103.095 37.7087C102.449 37.7192 101.915 37.2022 101.904 36.5561C101.893 35.91 102.41 35.3758 103.057 35.3652C103.703 35.3547 104.237 35.8717 104.247 36.5178C104.258 37.1639 103.741 37.6981 103.095 37.7087Z"
              fill="#FFDA5A"
            />
            <path d="M71.0231 16.0493L72.6529 14.3655L74.3368 15.9952L72.707 17.6791L71.0231 16.0493Z" fill="#FFDA5A" />
            <path d="M93.6943 11.7271L95.3241 10.0433L97.0079 11.673L95.3782 13.3569L93.6943 11.7271Z" fill="#FFDA5A" />
          </g>
          <path
            d="M57.7498 157.268C62.5821 157.268 66.4995 153.308 66.4995 148.425C66.4995 143.541 62.5821 139.583 57.7498 139.583C52.9174 139.583 49 143.541 49 148.425C49 153.308 52.9174 157.268 57.7498 157.268Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M120.248 157.268C125.08 157.268 128.998 153.308 128.998 148.425C128.998 143.541 125.08 139.583 120.248 139.583C115.416 139.583 111.498 143.541 111.498 148.425C111.498 153.308 115.416 157.268 120.248 157.268Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M113.835 111.652L42.7499 116.845C40.4918 116.845 39 114.858 39 112.579V45.5165C39 37.3977 48.4357 33.0223 54.5415 38.3093L119.959 94.9463C126.589 100.683 122.567 111.652 113.835 111.652Z"
            fill="url(#paint2_linear)"
          />
          <path
            d="M43.9967 116.845H48.9966C64.864 116.845 79.7166 129.581 79.7166 145.611V161.131H98.5148V147.983C98.5148 130.643 111.824 116.845 128.994 116.845H133.994C136.222 116.845 139 114.753 139 112.502V98.3285V45.5262C139 37.4143 129.577 33.0398 123.481 38.3183L41.4968 114.318C38.9969 116.845 42.7715 116.845 43.9967 116.845Z"
            fill="url(#paint3_linear)"
          />
          <path
            d="M43.156 187.583H134.841C137.135 187.583 138.997 185.643 138.997 183.253V179.28C138.997 176.89 137.135 174.951 134.841 174.951H43.156C40.8618 174.951 39 176.89 39 179.28V183.253C39 185.643 40.8618 187.583 43.156 187.583Z"
            fill="#FAAC18"
          />
          <defs>
            <linearGradient id="paint0_linear" x1="48.9986" y1="148.424" x2="66.5012" y2="148.424" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFE7A3" />
              <stop offset="0.5" stop-color="#FFCA05" />
              <stop offset="1" stop-color="#FAAC18" />
            </linearGradient>
            <linearGradient id="paint1_linear" x1="111.495" y1="148.424" x2="128.997" y2="148.424" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFE7A3" />
              <stop offset="0.5" stop-color="#FFCA05" />
              <stop offset="1" stop-color="#FAAC18" />
            </linearGradient>
            <linearGradient id="paint2_linear" x1="81.1317" y1="41.0528" x2="81.1317" y2="116.705" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FEC409" />
              <stop offset="1" stop-color="#D88F00" />
            </linearGradient>
            <linearGradient id="paint3_linear" x1="90.1192" y1="41.0651" x2="90.1192" y2="161.133" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFE7A3" />
              <stop offset="0.5" stop-color="#FFCA05" />
              <stop offset="1" stop-color="#FAAC18" />
            </linearGradient>
            <clipPath id="clip0">
              <rect width="20" height="20" fill="white" transform="translate(7 119.099) rotate(-49.0219)" />
            </clipPath>
            <clipPath id="clip1">
              <rect width="20" height="20" fill="white" transform="translate(152.803 104) rotate(47.746)" />
            </clipPath>
            <clipPath id="clip2">
              <rect width="40" height="40" fill="white" transform="translate(66 0.653618) rotate(-0.936281)" />
            </clipPath>
          </defs>
        </svg>
      </span>
      <p class="text-center my-0">
        MICEtribe just got better! <br />
        Update to latest version?
      </p>

      <div class="my-4 d-flex justify-content-space-around align-items-center">
        <button nbButton size="small" class="mx-3" status="basic" (click)="close()">No, Skip this version</button>
        <button nbButton size="small" class="mx-3" status="primary" (click)="hardReloadAndClose()">Yes</button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
