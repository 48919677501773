import { Router, ActivatedRoute } from '@angular/router'
import { AuthService } from '@auth/services/auth.service'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { EmailValidator } from '@auth/validators/email.validator'
import { Component, OnInit, TemplateRef, OnDestroy, AfterViewInit } from '@angular/core'
import { ToastrService } from '@app/services/toastr.service'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { environment } from '@environments/environment'
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations'
/**
 * Displays an authentication form to login/signup users
 */
@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInOnEnterAnimation({ duration: 300, delay: 200 }), fadeOutOnLeaveAnimation({ duration: 300, delay: 0 })]
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }

  formTouched = false
  ngAfterViewInit(): void {
    document.getElementById('loginSignup').addEventListener('click', () => {
      if (!this.formTouched) this.checkEmailExists(this.form.value.email)
      this.formTouched = true
    })
  }
  /**
   * Login/Signup form group
   */
  form: FormGroup
  /**
   * Holds error information
   */
  error: any
  /**
   * Whether we are performing an async operation
   */
  processing = false
  /**
   * Whether the user is registered
   */
  registered = null
  /**
   * Dialog reference
   */
  dialog: TemplateRef<any>
  /**
   * loading flag
   */
  isLoading: boolean

  /**
   * Creates the component and injects it's dependencies
   * @param fb FormBuilder
   * @param authService AuthService
   * @param router Router
   * @param route ActivatedRoute
   * @param toastr ToastrService
   */
  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  /**
   * Initializes the component & form group
   */
  ngOnInit() {
    this.form = this.fb.group({
      email: new FormControl('', { validators: [Validators.required, EmailValidator.validate] }),
      password: ['', { validators: [Validators.required, Validators.minLength(8), Validators.maxLength(20)] }],
      password_confirmation: ['']
    })
    this.form
      .get('email')
      .valueChanges.pipe(debounceTime(150), distinctUntilChanged())
      .subscribe((value) => {
        this.registered = null
        if (this.form.get('email').valid && this.formTouched) {
          this.checkEmailExists(value)
        }
      })
    const email = this.route.snapshot.queryParams.email
    if (!!email) {
      this.form.patchValue({ email: email })
    }
    const token = this.route.snapshot.queryParams.token
    if (!!token) {
      this.authService
        .loginWithToken(token)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (data) => {
            if (data['email'] && data['token']) {
              this.authService.user = data
              this.authService
                .getPlans()
                .pipe(takeUntil(this._destroy$))
                .subscribe(() => { })
              this.authService
                .getWorkspaces()
                .pipe(takeUntil(this._destroy$))
                .subscribe((workspaces) => {
                  this.processing = false
                  this.authService.justLoggedIn = 'false'
                  this.navigate(workspaces)
                  this.authService.justLoggedIn = 'true'
                })
            } else {
              this.error = data.reduce((prev, next) => prev + '\n' + next)
            }
          },
          (error) => {
            this.processing = false
            this.form.setErrors(error.error)
            this.error = 'Please make sure the email address and/or password are correct.'
          }
        )
    }
  }
  /**
   * Checks if an email address is registered in the system
   * @param email string
   */

  checkEmailExists(email?: string): void {
    if (!email) return
    this.isLoading = true
    this.form.updateValueAndValidity()
    this.authService
      .checkIfEmailRegistered(email ?? this.form.value.email)
      .pipe(takeUntil(this._destroy$), takeUntil(this.form.valueChanges))
      .subscribe(
        (data) => {
          this.registered = data['isEmailExists']
          this.form.get('password_confirmation').setValidators(this.registered ? [] : [Validators.required])
          this.form.get('password_confirmation').updateValueAndValidity()
          this.isLoading = false
        },
        (err) => {
          this.isLoading = false
        }
      )
  }
  /**
   * Handles login/signup button click
   */
  submit() {
    if (this.form.invalid) {
      this.toastr.error('Error', 'Please make sure the email address and/or password are correct')
      return
    }
    if (this.registered) {
      this.login()
      return
    }
    if (!environment.production) alert(`Sign up's are disabled on staging environment`)
    this.signup()
  }
  /**
   * Attempts to signup the user to the system
   */
  signup() {
    this.error = null
    this.processing = true
    this.authService
      .signup(this.form.value)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          this.toastr.success(`Welcome ${this.form.value['email']}`, '')
          this.authService.user = data
          this.authService.justLoggedIn = 'false'
          this.router.navigateByUrl('workspaces/almost-there')
          this.authService.justLoggedIn = 'true'
        },
        (error) => {
          this.processing = false
          this.form.setErrors(error.error)
          this.error = 'Please make sure the email address and/or password are correct.'
        }
      )
  }

  /**
   * Attempts to login the user to the system
   */
  login() {
    this.error = null
    this.processing = true
    const value = this.form.value
    delete value.password_confirmation
    this.authService
      .login(value)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          if (data['email'] && data['token']) {
            this.authService.user = data
            this.authService
              .getPlans()
              .pipe(takeUntil(this._destroy$))
              .subscribe(() => { })
            this.authService
              .getWorkspaces()
              .pipe(takeUntil(this._destroy$))
              .subscribe((workspaces) => {
                this.processing = false
                this.authService.justLoggedIn = 'false'
                this.navigate(workspaces,data['full_name'])
                this.authService.justLoggedIn = 'true'
              })
          } else {
            this.error = data.reduce((prev, next) => prev + '\n' + next)
          }
        },
        (error) => {
          this.processing = false
          this.form.setErrors(error.error)
          this.error = 'Please make sure the email address and/or password are correct.'
        }
      )
  }
  /**
   * Social login with facebook
   */
  loginWithFacebook() {
    window.location.href = `${environment.baseContactlessApi}v3/plan/contactless/auth/login/facebook`
  }
  /**
   * Social login with google
   */
  loginWithGoogle() {
    window.location.href = `${environment.baseContactlessApi}v3/plan/contactless/auth/login/google`
  }
  /**
   * Navigates to password reset
   */
  navigateToRestPass() {
    this.router.navigate(['auth', 'reset'], { queryParams: { email: this.form.controls['email'].valid ? this.form.value['email'] : '' } })
  }

  navigate(workspaces: { data: any[] },full_name?:string) {
    const workspacesLength = workspaces?.data?.length || 0;
    let route: string[];
    if (workspacesLength && !full_name) {
      this.router.navigate(['app-settings/profile'])
    } else if (workspacesLength > 1) {
      route = ['workspaces', 'list'];
    } else if (workspacesLength === 1) {
      this.authService.currentWorkspace = workspaces.data[0]
      this.authService.currentParentWorkspace = workspaces.data[0].parent || workspaces.data[0]
      route = ['events', 'list'];
    } else {
      route = ['workspaces', 'almost-there'];
    }
    this.router.navigate(route);
  }

  /**
   * Determines if the form is valid for submission
   */
  get formValid(): boolean {
    // here we have the 'passwords' group
    const pass = this.form.get('password').value
    const confirmPass = this.form.get('password_confirmation').value

    return (this.registered || pass === confirmPass) && this.form.valid
  }
  /**
   * Displays the password confirmation input
   */
  get dualMode() {
    return this.registered === null || !this.form.value.email || !this.form.get('email').valid
  }

  /**
   * Displays the terms and conditions modal
   */
  displayTermsAndConditions() {
    this.authService.displayTerms$.next(true)
  }
}
