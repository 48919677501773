import { ActivatedRoute } from '@angular/router'
import { Component, OnInit, OnDestroy } from '@angular/core'
import { AuthService } from '@auth/services/auth.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from '@app/services/toastr.service'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

/**
 * Resets the user password by a token that was sent to his email address
 */
@Component({
  selector: 'ngx-auth-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * form group to collect password information
   */
  form: FormGroup
  /**
   * Determines whether the component is performing an async operation
   */
  processing = false
  /**
   * Reset token retrieved from the activated route
   */
  ResetToken: string
  /**
   * Creates the component & injects it's dependencies
   * @param fb FormBuilder
   * @param activatedRoute ActivatedRoute
   * @param authService AuthService
   * @param toastrService ToastrService
   * @param router Router
   */
  constructor(private fb: FormBuilder, public activatedRoute: ActivatedRoute, private authService: AuthService, private toastrService: ToastrService, private router: Router) {}

  /**
   * OnInit implementation - Gets the token, set up the form
   */
  ngOnInit(): void {
    this.ResetToken = this.activatedRoute.snapshot.queryParams.remember_token
    this.form = this.fb.group({
      remember_token: [this.ResetToken, Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required]
    })
  }

  /**
   * Sends password reset email
   * @param form sends the password change request, signs in the user and navigate him according to his permission
   */
  send(form) {
    form = this.form.value
    this.processing = true
    this.authService
      .resetPassword(this.form.value)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          this.toastrService.success('Congratulations', 'Your password has been reset.')

          if (data['email'] && data['token']) {
            this.authService.user = data
            this.authService
              .getWorkspaces()
              .pipe(takeUntil(this._destroy$))
              .subscribe(() => {
                this.processing = false
                this.router.navigate(['workspaces', 'list'])
              })
          }
        },
        () => {
          this.processing = false
        }
      )
  }
}
