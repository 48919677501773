import { Injectable } from '@angular/core'
import { CoverLogoComponent } from '@app/modules/events/components/edit/components/cover-logo/cover-logo.component'
import { ProfileComponent } from '@app/modules/settings/components/profile/profile.component'
import { DetailsComponent } from '@workspaces/components/details/details.component'
import { takeUntil } from 'rxjs/operators'
import { MediaLibraryService } from './media-library.service'
import { AuthService } from '@app/modules/auth/services/auth.service'
import { FileInfo } from '@app/modules/shared/interfaces/media-library.type'

/**
 * Cloudinary JS library global object
 */
declare var cloudinary

/**
 * Creates the global cloudinary uploader and share it with different components
 */
@Injectable({
  providedIn: 'root'
})
export class CloudinaryUploadService {
  constructor(private mediaLibrary: MediaLibraryService, private auth: AuthService) {}

  /**
   * Bootstraps a cloudinary widget for a cover photo
   * @param coverLogoComponent
   */
  public bootCoverPhoto(coverLogoComponent: CoverLogoComponent) {
    coverLogoComponent.cloudinaryUploadWidgetForCover = cloudinary.createUploadWidget(
      {
        cloud_name: 'contactless',
        upload_preset: 'wydqifyd',
        cropping: true,
        croppingAspectRatio: 3.529,
        croppingShowDimensions: true,
        maxFileSize: 1000000,
        minImageWidth: 1200,
        maxImageWidth: 2400,
        croppingValidateDimensions: true,
        showSkipCropButton: false,
        clientAllowedFormats: ['jpeg', 'jpg', 'png']
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          this.mediaLibrary.uploadtoWorkspace(this.auth.currentWorkspace.slug, result.info.secure_url).subscribe((file: FileInfo) => {
            coverLogoComponent.cover.patchValue(file.encoded_url)
            coverLogoComponent.logo.clearValidators()
            coverLogoComponent.logo.updateValueAndValidity()
          })
        }
        if (result.event === 'close') coverLogoComponent.cover.patchValue('')
        if (result?.failed && !result?.event) {
          setTimeout(() => {
            coverLogoComponent.cloudinaryUploadWidgetForCover.destroy()
            this.bootCoverPhoto(coverLogoComponent)
            coverLogoComponent.cloudinaryUploadWidgetForCover.open()
          }, 2500)
        }
      }
    )
  }

  /**
   * Bootstraps a cloudinary widget for a logo photo
   * @param coverLogoComponent
   */
  public bootLogoPhoto(coverLogoComponent: CoverLogoComponent) {
    coverLogoComponent.cloudinaryUploadWidgetForLogo = cloudinary.createUploadWidget(
      {
        cloud_name: 'contactless',
        upload_preset: 'wydqifyd',
        maxFileSize: 1000000,
        clientAllowedFormats: ['jpeg', 'jpg', 'png']
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          this.mediaLibrary.uploadtoWorkspace(this.auth.currentWorkspace.slug, result.info.secure_url).subscribe((file: FileInfo) => {
            coverLogoComponent.logo.patchValue(file.encoded_url)
            coverLogoComponent.cover.clearValidators()
            coverLogoComponent.cover.updateValueAndValidity()
          })
        }
        if (result.event === 'close') coverLogoComponent.logo.patchValue('')
        if (result?.failed && !result?.event) {
          setTimeout(() => {
            coverLogoComponent.cloudinaryUploadWidgetForCover.destroy()
            this.bootLogoPhoto(coverLogoComponent)
            coverLogoComponent.cloudinaryUploadWidgetForCover.open()
          }, 2500)
        }
      }
    )
  }

  /**
   * Bootstraps a cloudinary widget for a profile component
   * @param profileComponent Profile component
   */
  public bootProfile(profileComponent: ProfileComponent) {
    profileComponent.cloudinaryUploadWidget = cloudinary.createUploadWidget(
      {
        cloud_name: 'contactless',
        upload_preset: 'wydqifyd',
        cropping: true,
        clientAllowedFormats: ['jpeg', 'jpg', 'png']
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          profileComponent.hasAvatar = true
          profileComponent.profileForm.controls.profile_picture.patchValue(result.info.secure_url)
          profileComponent.profileForm.controls.profile_picture.markAsDirty()
        }
      }
    )
  }
  /**
   * bootstraps a cloudinary uploader for a details component
   * @param detailsComponent Details component
   */
  public bootDetails(detailsComponent: DetailsComponent) {
    detailsComponent.cloudinaryUploadWidget = cloudinary.createUploadWidget(
      {
        cloud_name: 'contactless',
        upload_preset: 'wydqifyd',
        clientAllowedFormats: ['jpeg', 'jpg', 'png']
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          detailsComponent.workspaceService
            .updateLogo(result.info.secure_url, detailsComponent.workspace.id)
            .pipe(takeUntil(detailsComponent._destroy$))
            .subscribe(() => {
              detailsComponent.workspace.logo = result.info.secure_url
            })
        }
      }
    )
  }
}
