import { NgModule } from '@angular/core'
import { WorkspacesComponent } from './components/workspaces.component'
import { EditWorkspaceComponent } from './components/edit-workspace/edit-workspace.component'
import { RouterModule } from '@angular/router'
import { CreateComponent } from './components/create/create.component'
import { SelectComponent } from './components/select/select.component'
import { WorkspaceService } from './services/workspace.service'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  NbIconModule,
  NbLayoutModule,
  NbCardModule,
  NbFormFieldModule,
  NbSelectModule,
  NbInputModule,
  NbButtonModule,
  NbActionsModule,
  NbTooltipModule,
  NbSpinnerModule,
  NbDialogModule,
  NbRadioModule,
  NbToggleModule,
  NbDatepickerModule,
  NbCheckboxModule,
  NbTagModule,
  NbAutocompleteModule,
  NbListModule
} from '@nebular/theme'
import { DetailsComponent } from './components/details/details.component'
import { HeaderComponent } from './components/header/header.component'
import { SearchComponent } from './components/search/search.component'
import { SetComponent } from './components/set/set.component'
import { AssignOrganizerComponent } from './components/assign-organizer/assign-organizer.component'
import { MasterExportComponent } from './components/master-export/master-export.component'
import { AlmostThereComponent } from '@auth/components/almost-there/almost-there.component'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { PlanBadgeComponent } from './components/plan-badge/plan-badge.component'
import { FormsModule as MicetribeFormsModule } from '@micetribe/forms/forms.module'
import { RealTimeService } from './services/real-time.service'
import { MatDialogModule } from '@angular/material/dialog'
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbLayoutModule,
    NbIconModule,
    MatIconModule,
    NbCardModule,
    NbFormFieldModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbActionsModule,
    NbTooltipModule,
    NbSpinnerModule,
    NbDialogModule.forChild({
      closeOnBackdropClick: true,
      closeOnEsc: true
    }),
    NbRadioModule,
    NbToggleModule,
    NbDatepickerModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    NbCheckboxModule,
    NbTagModule,
    MatIconModule,
    MatTooltipModule,
    NbAutocompleteModule,
    MicetribeFormsModule,
    NbListModule,
    MatDialogModule,
    RouterModule.forChild([
      {
        path: 'list',
        component: WorkspacesComponent,
      },
      {
        path: 'almost-there',
        component: AlmostThereComponent
      },
      {
        path: 'create',
        component: CreateComponent
      }
    ])
  ],
  declarations: [
    WorkspacesComponent,
    EditWorkspaceComponent,
    CreateComponent,
    SelectComponent,
    DetailsComponent,
    HeaderComponent,
    SearchComponent,
    SetComponent,
    AssignOrganizerComponent,
    AlmostThereComponent,
    MasterExportComponent,
    PlanBadgeComponent
  ],
  exports: [CreateComponent, SelectComponent, DetailsComponent, SetComponent, HeaderComponent, SearchComponent, MasterExportComponent, PlanBadgeComponent],
  providers: [WorkspaceService, RealTimeService]
})
export class WorkspacesModule {}
