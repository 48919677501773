<div *ngIf="isLoaded" class="display-info-wrapper">
  <div *ngIf="showMenuBar" id="toolbar"></div>
  <div
    class="display-info"
    [style.minHeight.px]="containerHeight"
    [ngClass]="{ 'display-info-border': border, 'black-placeholder': blackPlaceholder, 'allow-padding': editorPadding }"
    [ngStyle]="{ background: whiteBackground ? '#ffffff' : 'transparent' }"
  >
    <div class="display-info-editor">
      <editor
        [apiKey]="editorAPIKey"
        [inline]="true"
        class="editor-wrapper"
        [init]="tinyMCEInit"
        [ngModel]="message"
        (ngModelChange)="update($event)"
        (onBlur)="updateMessage()"
      ></editor>
    </div>
  </div>
</div>
