import { ExtraOptions, RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { AuthGuard } from './guards/auth.guard'
import { GuestGuard } from './guards/guest.guard'
import { AdminGuard } from './guards/admin.guard'
import { CurrentWorkspaceGuard } from '@workspaces/guards/current-workspace.guard'
import { RoleGuard } from './guards/role.guard'
import { NotFoundComponent } from './modules/shared/components/404/not-found.component'
/**
 * Defines a list of available application routes
 */
const routes: Routes = [
  {
    path: '',
    redirectTo: 'workspaces/list',
    pathMatch: 'full'
  },
  {
    path: 'capacity',
    loadChildren: () => import('./modules/venue-capacity/venue-capacity.module').then((m) => m.VenueCapacityModule)
  },
  {
    path: 'personas',
    loadChildren: () => import('./modules/personas/personas.module').then((m) => m.PersonasModule),
    canActivate: [AuthGuard, CurrentWorkspaceGuard]
  },
  { path: 'wizard', loadChildren: () => import('./modules/@micetribe/events/wizard/wizard.module').then((m) => m.WizardModule), canActivate: [AuthGuard, CurrentWorkspaceGuard] },
  {
    path: 'event-landing',
    loadChildren: () => import('./modules/event-landing-page/event-landing-page.module').then((m) => m.EventLandingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'events',
    loadChildren: () => import('./modules/events/event.module').then((m) => m.EventModule),
    canActivate: [AuthGuard, CurrentWorkspaceGuard],
    canActivateChild: [RoleGuard]
  },
  { path: 'app-settings', loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule) },
  { path: 'billing', loadChildren: () => import('./modules/payments/payments.module').then((m) => m.PaymentsModule) },
  {
    path: 'workspaces',
    loadChildren: () => import('./modules/workspaces/workspaces.module').then((m) => m.WorkspacesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'subscription',
    loadChildren: () => import('./modules/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full'
  }
]

/** Defines a global routing configuration options */
const config: ExtraOptions = {
  useHash: false,
  onSameUrlNavigation: 'reload'
  // enableTracing: true
}

/**
 * Main routing module
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
