import { setHours, parse, format, addDays, differenceInCalendarDays, setMinutes } from 'date-fns'
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms'
import { EventConvertHook } from './event.convert.hook'
import { ServerEncapsulatedCrewMembers } from './crew-member.interface'
import { Zone } from './zone.model'
import { Persona } from '@app/modules/personas/interfaces/persona.interface'

export interface Checkin {
  parent: number
  slug: string
  name: string
  weight: number
  is_virtual: true
  location_details: {
    [key: string]: string | number
  }
  id?: number
}

/**
 * Shared interface holding information about an event
 */
interface ISharedEvent {
  /**
   * Event ID
   */
  id?: number
  /**
   * Workspace ID that owns the event
   */
  workspace_id: number

  /**
   *  shorthand used instead of ID in creating urls
   */
  slug: string

  /**
   * Determines whether an event is virtual or not
   */
  is_virtual: boolean

  /**
   *  Event name
   */
  name: string

  /**
   * Event country
   */
  country: string

  /**
   * Event city
   */
  city: string

  /**
   * Event timezone
   */
  timezone: string

  /**
   * Event type - Currently defaults to Exhibition
   * However supported types also includes Seminars - Concert - Conference
   */
  type: number

  /**
   *  URL of an Event logo
   */
  logo: string

  /**
   *  Hosting venue name
   */
  venue_name: string

  /**
   *  Hosting venue address
   */
  venue_address: string

  /**
   *  Hosting venue longitude
   */
  venue_longitude: string

  /**
   *  Hosting venue latitude
   */
  venue_latitude: string

  /**
   *  Link to google maps location
   */
  map_direct_link: string

  /**
   *  Link to google maps location
   */
  virtual_link: string
  /**
   *  Link to event type
   */
  physical_type_id: number
  /**
   *  Link to google maps location and the it's details
   */
  global_physical_location: {
    venue_name: string
    venue_address: string
    venue_longitude: string
    venue_latitude: string
    map_direct_link: string
  }
  /**
   *  Link to virtual event link
   */
  global_virtual_location: {
    link: string
  }

  /**
   * Social media associated to an event
   */
  social_media: {
    [key: string]: string
    /**
     *  Link to facebook social account
     */
    facebookAccount?: string

    /**
     *  Link to instagram social account
     */
    instagramAccount?: string

    /**
     *  Link to snapchat social account
     */
    snapchat_account?: string

    /**
     *  Link to linkedin social account
     */
    linkedin_account?: string

    /**
     *  Link to twitter social account
     */
    twitter_account?: string

    /**
     *  Link to youtube social account
     */
    youtube_account?: string

    /**
     *  Link to messenger social account
     */
    messenger_account?: string

    /**
     *  Link to event website
     */
    website?: string

    /**
     *  link to Event sponsor logo(s)
     */
    sponsor_picture_url?: string

    /**
     *  Link to redirect when a sponsor logo is clicked
     */
    sponsor_url?: string
  }

  /**
   *  whether an event is archived or not (Not used at the moment)
   */
  is_archived: string

  /**
   *  Paid parking
   */
  is_parking_paid: string

  /**
   *  whether to use google map link or longitude/ latitude of the hosting venue
   */
  use_map_direct_link: string

  /**
   *  Number of registrants in all event personas (This property is retrieved via a separate API)
   */
  registration_count?: number

  /**
   *  Number of registrants in all event personas (This property is retrieved via a separate API)
   */
  registration_count_display?: string
  /**
   * Link to event cover page picture
   */
  cover_page?: string | null

  /**
   * List of crew members assigned to an event
   */
  crew?: ServerEncapsulatedCrewMembers

  /**
   * is an object that represent the traffic dashboard
   */
  dashboard: {
    event_id: number
    id: number
    name: string
    slug: string
  }

  /**
   * Checkins assigned to an event
   */
  checkins: Checkin[]
  /**
   * is an object that represent the zones of an event
   */
  zones: {
    main: Zone
    virtual: Zone
    physical: Zone
  }
  currency_id: number | null
}

/**
 * Shared properties in an event timing
 */
export interface ISharedEventTiming {
  /**
   * Time the event begins in a day
   */
  start_datetime: string
  /**
   * Time the event ends in a day
   */
  end_datetime: string

  /**
   * Extra notes about a specific day
   */
  info?: string

  /**
   * Event ID that the timing belongs to
   */
  event_id?: number
}

/**
 * Properties of an event timing that is represented in the frontend
 */
export interface ClientRepresentedEventTiming extends ISharedEventTiming {
  /**
   * Date object of a specific timing day
   */
  day: Date
}

/**
 * Properties of an event timing that is represented in the backend
 */
export interface ServerRepresentedEventTiming extends ISharedEventTiming {
  /**
   * ID of an Event timing
   */
  id?: string
}

/**
 * Properties of an Event that is represented in the frontend
 */
export interface ClientRepresentedEvent extends ISharedEvent {
  /**
   * Date representing the first day of an event
   */
  public_start_date: Date

  /**
   * Date representing the last day of an event
   */
  public_end_date: Date

  /**
   * Collection of days between @see public_start_date and @see public_end_date
   */
  event_timing: ClientRepresentedEventTiming[]

  /**
   * Used to disable physical type changes when an event has a registrations
   */
  status?: number

  /**
   * Used to allow checkin before event starts
   */
  checkin_lead_time?: number
}

/**
 * Registrations count for an event
 * Each key represents a persona
 */
export interface ServerRepresentedEventRegistrationsCount {
  [key: string]: {
    /**
     * Registrants attended
     */
    attended: number
    /**
     * Total registrants
     */
    total: number
    /**
     * Registrants who missed the event
     */
    missed: number
  }
}

/**
 * Registration counts for an event by it's status
 * Used for payments & expots
 */
export interface ServerRepresentedSingleEventRegistrationsCount {
  /**
   * Total registrations in an event
   */
  totalCount: number
  /** Settled (paid) registrations */
  settledCount: number
  /** Unsettled (unpaid) registrations */
  unsettledCount: number
  /** Personas & their details - Each object property is a persona */
  forms: {
    [/** Persona Slug */ key: string]: {
      /** Object contains the settled registrants counts */
      settled?: {
        /** Settled as free of charge */
        free?: number
        /** Settled as virtual attendee */
        virtual?: number
        /** Settled as a physical attendee */
        physical?: number
        /** Settled as a virtual attendee that got upgraded to physical attendee (difference) */
        difference?: number
      }
      /** Object contains the unsettled registrants counts */
      unsettled?: {
        /** Unsettled as free of charge */
        free?: number
        /** Unsettled as virtual attendee */
        virtual?: number
        /** Unsettled as a physical attendee */
        physical?: number
        /** Unsettled as a virtual attendee that got upgraded to physical attendee (difference) */
        difference?: number
      }
    }
  }
}
/**
 * Defines an event object that was retrieved from an endpoint
 */
export interface ServerRepresentedEvent extends ISharedEvent {
  /**
   * String representing the first day of an event
   */
  public_start_date: string

  /**
   *  String representing the first day of an event
   */
  public_end_date: string

  /**
   * Collection of days between @see public_start_date and @see public_end_date
   */
  event_timing: ServerRepresentedEventTiming[]
  /**
   * Collection of days between @see public_start_date and @see public_end_date
   */
  event_timings?: ServerRepresentedEventTiming[]

  /**
   * List of Personas
   * Optional
   */
  forms?: Partial<Persona>[]

  /**
   * Used to disable physical type changes when an event has a registrations
   */
  status?: number
  /**
   * String representing the first day to register to an event
   */
  registration_start_date?: string

  /**
   * String representing the last day to register to an event
   */
  registration_end_date?: string
  /**
   *  workspace details
   */
  workspace?: {
    id?: number
    contact_number: string
    contact_email: string
    logo?: string
    name?: string
    slug?: string
  }
  currency?: {
    id: number
    name: string
    slug: string
  }
}

/**
 * Parses an event object between the frontend client and the backend server
 * @class EventParser
 * @classdesc A collection of handy methods that converts between ClientRepresentedEvent and a ServerRepresentedEvent
 */
export class EventParser {
  /**
   * A collection of global convert hooks, these hooks activates it's method before/ after an event object
   * is transformed into either a server or a client represented part
   */
  static _globalConvertHooks: EventConvertHook[] = []

  /**
   * registers a convert hook
   * @param convertHook The event convert hook definition, usually a new instance of a class that implements the abstract EventConvertHook class
   * @param convertHookConfigurations Convert hook registration configuration
   */
  static registerGlobalConvertHook(
    convertHook: EventConvertHook,
    convertHookConfigurations: { /** Defines whether the same hook can be registered multiple times */ multi: boolean } = { multi: false }
  ) {
    // Check if we are registering it more than once
    if (convertHookConfigurations.multi) {
      // Add the hook
      this._globalConvertHooks.push(convertHook)
    } else {
      // Check if the hook doesn't exist, here we are comparing constructor names
      if (!EventParser._globalConvertHooks.find((o) => o.constructor.name === convertHook.constructor.name)) {
        // Add the hook
        this._globalConvertHooks.push(convertHook)
      }
    }
  }

  /**
   * Gets a list of current registered hook names
   * @returns string[]
   */
  static get globalConvertHooks(): string[] {
    return EventParser._globalConvertHooks.map((o) => o.constructor.name)
  }
  /**
   * parses a string to date
   * @method parseStringToDate returns a Date object from a server date string
   * @param date a string that holds server date
   */
  static parseStringToDate = (date: string): Date => parse(date, 'yyyy-MM-dd HH:mm:ss', new Date())

  /**
   * parses a date to a string
   * @method parseDateToString returns a server date string from a Date object
   * @param date Date to be converted to a server date string
   */
  static parseDateToString = (date: Date): string => {
    return format(date, 'yyyy-MM-dd HH:mm:ss')
  }

  /**
   * parses a composed dte to a string
   * @method parseComposedDateToString joins a time string with a Date object and converts them to a server date string
   * @param date the Date to set the time for
   * @param time string holding the time info
   */
  static parseComposedDateToString = (date: Date, time: string) => {
    let composedDate = setHours(date, parseInt(time.split(':')[0], 10))
    composedDate = setMinutes(composedDate, parseInt(time.split(':')[1], 10))
    return EventParser.parseDateToString(composedDate)
  }

  /**
   * converts an client event to a server event
   * @method toRequest converts a @see ClientRepresentedEvent to a @see ServerRepresentedEvent
   * @param event Object to be converted
   * @returns ServerRepresentedEvent
   * Hooks update chains
   */
  static toRequest(event: ClientRepresentedEvent): ServerRepresentedEvent {
    /** Executes every before converting to server represented event convert hook */
    this._globalConvertHooks.forEach((convertHook) => {
      event = convertHook.beforeConvertingToServerRepresentedEvent(event)
    })
    /** Updates event start end date */
    let convertedEvent: ServerRepresentedEvent = {
      ...event,
      public_start_date: EventParser.parseDateToString(event.public_start_date),
      public_end_date: EventParser.parseDateToString(event.public_end_date),
      /** Update event timing */
      event_timing: event.event_timing.map((time) => {
        return {
          start_datetime: EventParser.parseComposedDateToString(time.day, time.start_datetime),
          end_datetime: EventParser.parseComposedDateToString(time.day, time.end_datetime),
          info: time.info
        }
      })
    }
    /** Executes every after converting to server represented event convert hook */
    this._globalConvertHooks.forEach((convertHook) => {
      convertedEvent = convertHook.afterConvertingToServerRepresentedEvent(convertedEvent)
    })
    return convertedEvent
  }

  /**
   * converts a server even to a client event
   * @method toResponse converts a @see ServerRepresentedEvent to a @see ClientRepresentedEvent
   * @param event Object to be converted
   * @returns ClientRepresentedEvent
   */
  static toResponse(event: ServerRepresentedEvent): ClientRepresentedEvent {
    /** Executes every before converting to client represented event convert hook */
    this._globalConvertHooks.forEach((convertHook) => {
      event = convertHook.beforeConvertingToClientRepresentedEvent(event)
    })
    /** Updates event start & end dates */
    let convertedEvent: ClientRepresentedEvent = {
      ...event,
      public_start_date: EventParser.parseStringToDate(event.public_start_date),
      public_end_date: EventParser.parseStringToDate(event.public_end_date),
      /** Updates event timings */
      event_timing: event.event_timing.map(
        /**
         * parses an event timing to a representable format
         * @param time event timing details
         * @returns event timing details
         */
        (time) => {
          return {
            day: EventParser.parseStringToDate(time.start_datetime),
            start_datetime: EventParser.getHoursAndMinutes(EventParser.parseStringToDate(time.start_datetime)),
            end_datetime: EventParser.getHoursAndMinutes(EventParser.parseStringToDate(time.end_datetime)),
            info: time.info
          }
        }
      )
    }
    /** Executes every after converting to client represented event convert hook */
    this._globalConvertHooks.forEach((convertHook) => {
      convertedEvent = convertHook.afterConvertingToClientRepresentedEvent(convertedEvent)
    })
    return convertedEvent
  }

  /**
   * extracts time from a Date object
   * @param date date to extract time from
   * @returns string
   */
  static getHoursAndMinutes(date: Date): string {
    return format(date, 'HH:mm')
  }

  /**
   * creates multiple timings
   * @method createTimings Creates a FormArray of timings objects
   * @param startDate Date to start creating timings for
   * @param endDate Date to stop creating timings at
   */
  static createTimings(startDate, endDate): FormArray {
    const difference = differenceInCalendarDays(endDate, startDate)
    const timings = new FormArray([])
    /** Creates multiple timings controls */
    for (let index = 0; index <= difference; index++) {
      timings.push(
        new FormGroup({
          day: new FormControl(addDays(startDate, index), Validators.required),
          start_datetime: new FormControl('10:00', Validators.required),
          end_datetime: new FormControl('22:00', Validators.required),
          info: new FormControl('')
        })
      )
    }
    return timings
  }

  /**
   * generate event form group
   * @method generateFormGroup Creates a FormGroup for a ClientRepresntedEvent
   * @param event ClientRepresentedEvent to create FormGroup for
   * @returns FormGroup
   */
  static generateFormGroup(event?: ClientRepresentedEvent): FormGroup {
    return new FormGroup({
      // General event info
      workspace_id: new FormControl(event ? event.workspace_id : null, Validators.required),
      slug: new FormControl(event ? { value: event.slug, disabled: event.registration_count > 0 } : '', [Validators.required, Validators.pattern('[a-zA-Z]+[a-zA-Z0-9-]*')]),
      name: new FormControl(event ? event.name : '', Validators.required),
      is_virtual: new FormControl(event ? event.is_virtual : false, [Validators.required]),
      country: new FormControl(event ? event.country : ''),
      timezone: new FormControl(event ? event.timezone : '', { updateOn: 'blur' }),
      type: new FormControl(1, Validators.required),
      logo: new FormControl(event ? event.logo : '', event && event.cover_page ? null : Validators.required),
      cover_page: new FormControl(event ? event.cover_page : '', event && event.logo ? null : Validators.required),

      physical_type_id: new FormControl(event ? event.physical_type_id : null),
      global_virtual_location: new FormGroup({
        link: new FormControl(event ? event.global_virtual_location?.link ?? '' : '')
      }),
      global_physical_location: new FormGroup({
        venue_name: new FormControl(event ? event.global_physical_location?.venue_name : ''),
        venue_address: new FormControl(event ? event.global_physical_location?.venue_address : ''),
        venue_longitude: new FormControl(event ? event.global_physical_location?.venue_longitude : ''),
        venue_latitude: new FormControl(event ? event.global_physical_location?.venue_latitude : ''),
        map_direct_link: new FormControl(event ? event.global_physical_location?.map_direct_link : '')
      }),
      social_media: new FormGroup({
        facebookAccount: new FormControl(event ? event?.social_media?.facebookAccount : ''),
        instagramAccount: new FormControl(event ? event?.social_media?.instagramAccount : ''),
        snapchat_account: new FormControl(event ? event?.social_media?.snapchat_account : ''),
        linkedin_account: new FormControl(event ? event?.social_media?.linkedin_account : ''),
        twitter_account: new FormControl(event ? event?.social_media?.twitter_account : ''),
        youtube_account: new FormControl(event ? event?.social_media?.youtube_account : ''),
        messenger_account: new FormControl(event ? event?.social_media?.messenger_account : ''),
        sponsor_url: new FormControl(event ? event?.social_media?.sponsor_url : ''),
        website: new FormControl(event ? event?.social_media?.website : '')
      }),
      is_archived: new FormControl(0),
      is_parking_paid: new FormControl(0),
      use_map_direct_link: new FormControl(1),
      sponsor_picture_url: new FormControl(event ? event.social_media.sponsor_url : ''),
      public_start_date: new FormControl(event ? event.public_start_date : addDays(new Date(), 1), Validators.required),
      public_end_date: new FormControl(event ? event.public_end_date : addDays(new Date(), 4), Validators.required),
      registration_count: new FormControl(event ? event.registration_count : 0),
      /** Populates event timings */
      event_timing: event
        ? new FormArray(
            event.event_timing.map((time) => {
              return new FormGroup({
                day: new FormControl(time.day, Validators.required),
                start_datetime: new FormControl(time.start_datetime, Validators.required),
                end_datetime: new FormControl(time.end_datetime, Validators.required),
                info: new FormControl(time.info)
              })
            })
          )
        : EventParser.createTimings(addDays(new Date(), 1), addDays(new Date(), 4)),
      checkin_lead_time: new FormControl(event?.checkin_lead_time ?? 60, Validators.required),
      zones: new FormGroup({
        physical: new FormGroup({
          event_id: new FormControl(event ? event.id : null),
          id: new FormControl(event ? event.zones.physical.id : null),
          max_capacity: new FormControl(event ? event.zones.physical?.max_capacity : null),
          form_ids: new FormControl(event ? event.zones.physical?.form_ids ?? [] : []),
          location_details: new FormGroup({
            venue_name: new FormControl(event ? event.zones.physical.location_details?.venue_name : ''),
            venue_address: new FormControl(event ? event.zones.physical.location_details?.venue_address : ''),
            venue_longitude: new FormControl(event ? event.zones.physical.location_details?.venue_longitude : ''),
            venue_latitude: new FormControl(event ? event.zones.physical.location_details?.venue_latitude : ''),
            venue_city: new FormControl(event ? event.zones.physical.location_details?.venue_city : ''),
            venue_country: new FormControl(event ? event.zones.physical.location_details?.venue_country : ''),
            map_direct_link: new FormControl(event ? event.zones.physical.location_details?.map_direct_link : ''),
            pin_longitude: new FormControl(event ? event.zones.physical.location_details?.pin_longitude : ''),
            pin_latitude: new FormControl(event ? event.zones.physical.location_details?.pin_latitude : ''),
            map_longitude: new FormControl(event ? event.zones.physical.location_details?.map_longitude : ''),
            map_latitude: new FormControl(event ? event.zones.physical.location_details?.map_latitude : ''),
            map_size_width: new FormControl(event ? event.zones.physical.location_details?.map_size_width : ''),
            map_size_height: new FormControl(event ? event.zones.physical.location_details?.map_size_height : ''),
            map_zoom_level: new FormControl(event ? event.zones.physical.location_details?.map_zoom_level : ''),
            map_place_id: new FormControl(event ? event.zones.physical.location_details?.map_place_id : '')
          }),
          /** populates zones */
          subcheckins: new FormArray(
            event
              ? event.zones.physical?.subcheckins?.map((zone) => {
                  return new FormGroup({
                    parent: new FormControl(zone.parent),
                    slug: new FormControl(zone.slug),
                    name: new FormControl(zone.name, Validators.required),
                    weight: new FormControl(zone.weight),
                    is_virtual: new FormControl(false),
                    event_id: new FormControl(event.id),
                    max_capacity: new FormControl(zone.max_capacity ?? null),
                    form_ids: new FormControl(zone ? zone.form_ids ?? [] : []),
                    id: new FormControl(zone.id),
                    location_details: new FormGroup({
                      venue_name: new FormControl(zone.location_details?.venue_name ? zone.location_details.venue_name : ''),
                      venue_address: new FormControl(zone.location_details?.venue_address ? zone.location_details.venue_address : ''),
                      venue_longitude: new FormControl(zone.location_details?.venue_longitude ? zone.location_details.venue_longitude : ''),
                      venue_latitude: new FormControl(zone.location_details?.venue_latitude ? zone.location_details.venue_latitude : ''),
                      venue_city: new FormControl(event ? event.zones.physical.location_details?.venue_city : ''),
                      venue_country: new FormControl(event ? event.zones.physical.location_details?.venue_country : ''),
                      map_direct_link: new FormControl(zone.location_details?.map_direct_link ? zone.location_details.map_direct_link : '')
                    })
                  })
                }) ?? []
              : []
          )
        }),
        virtual: new FormGroup({
          event_id: new FormControl(event ? event.id : null),
          id: new FormControl(event ? event.zones.virtual.id : null),
          max_capacity: new FormControl(event ? event.zones.virtual.max_capacity : null),
          form_ids: new FormControl(event ? event.zones.virtual?.form_ids ?? [] : []),
          location_details: new FormGroup({
            link: new FormControl('')
          }),
          /** populates zones */
          subcheckins: new FormArray(
            event
              ? event.zones.virtual?.subcheckins?.map((zone) => {
                  return new FormGroup({
                    parent: new FormControl(zone.parent),
                    slug: new FormControl(zone.slug),
                    name: new FormControl(zone.name, Validators.required),
                    weight: new FormControl(zone.weight),
                    is_virtual: new FormControl(true),
                    event_id: new FormControl(event.id),
                    max_capacity: new FormControl(zone.max_capacity ?? null),
                    form_ids: new FormControl(zone ? zone.form_ids ?? [] : []),
                    id: new FormControl(zone.id),
                    location_details: new FormGroup({
                      link: new FormControl(zone.location_details?.link ? zone.location_details.link : ''),
                      service: new FormControl(zone.location_details?.service ? zone.location_details.service : {})
                    })
                  })
                }) ?? []
              : []
          )
        })
      })
    })
  }

  /**
   * returns the event part excluding timings & physical type
   * @method getEventPart returns a part of the ServerRepresentedEvent interface needed to create an Event
   * @param event ServerRepresentedEvent to extract the event part of
   * @returns Partial<ServerRepresentedEvent>
   */
  static getEventPart(event: ServerRepresentedEvent): Partial<ServerRepresentedEvent> {
    const partialEvent = {
      ...event,
      event_timing: null
    }
    delete partialEvent.event_timing
    delete partialEvent.physical_type_id
    return partialEvent
  }
  /**
   * returns the timings part of an event
   * @method getTimingsPart returns a part of the ServerRepresentedEvent interface needed to create an Event timings
   * @param event ServerRepresentedEvent to extract the event part of
   * @returns Partial<ServerRepresentedEvent>
   */
  static getTimingsPart(event: ServerRepresentedEvent): Partial<ServerRepresentedEvent> {
    return {
      event_timing: event.event_timing
    }
  }
  /**
   * combines an event & timing part along a zones part to a server represented event
   * @method compose Combines eventPart and timingPart to form a ServerRepresentedEvent
   * @param eventPart Event data represented
   * @param timingPart Timing data represented
   * @returns ServerRepresentedEvent
   */
  static compose(eventPart: ServerRepresentedEvent, timingPart: ServerRepresentedEventTiming[], zonesPart?: Zone[]): ServerRepresentedEvent {
    return {
      ...eventPart,
      event_timing: timingPart,
      zones: zonesPart
        ? {
            main: zonesPart[0],
            virtual: zonesPart[0].subcheckins.find((o) => o.slug === 'global_virtual'),
            physical: zonesPart[0].subcheckins.find((o) => o.slug === 'global')
          }
        : eventPart.zones
    }
  }
}
