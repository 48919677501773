import { AbstractControl } from '@angular/forms'

/**
 * Validates a full name string based on a regex
 * @param control Abstract control
 * @returns error or null
 */
export function ValidateFullName(control: AbstractControl) {
  if (!control.value) return null
  if (formatError(control.value)) {
    return { control: 'Your name should have atleast two letters and no numbers.' }
  }
  if (twoWordsError(control.value)) {
    return { control: 'You should provide atleast two names.' }
  }
  return null
}

function formatError(value) {
  const capitalizedFirstLetterRegex = /\b[a-zà-ž]([?!]\s)/g
  const onlyLettersRegex = /[^\u0621-\u064A\u066E-\u06D5a-zA-Zà-žÀ-Ž\s\.]/g
  return capitalizedFirstLetterRegex.test(value) || onlyLettersRegex.test(value)
}
function twoWordsError(value) {
  const twoWordsRegex = /([\u0621-\u064A\u066E-\u06D5a-zA-Zà-žÀ-Ž\.]{2,}\s)*([\u0621-\u064A\u066E-\u06D5a-zA-Zà-žÀ-Ž]{2,}\s)+[\u0621-\u064A\u066E-\u06D5a-zA-Zà-žÀ-Ž]{2,}/g
  return value.replace(twoWordsRegex, '$') != '$'
}
