<div class="WordSection1">
  <p class="MsoNoSpacing" align="center" style="text-align: center; line-height: 115%">
    <b><span style="font-size: 16pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" align="center" style="text-align: center; line-height: 115%">
    <b><span style="font-size: 16pt; line-height: 115%; font-family: 'Verdana', sans-serif">DATA PROTECTION POLICY</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 16pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Contactless.io OÜ is a European based company and is committed to protecting the Personal Data of individuals who uses its software and services. This Privacy Policy aims to
      provide you with details on the type of information Contactless.io OÜ collects from you, and to explain how we gather, use, and protect your Personal Data.
    </span>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 5pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 5pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">A. Definitions</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; text-indent: -0.25in; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">GDPR</span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <i><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">(General Data Protection Regulation)</span></i>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >GDPR is a regulation that requires businesses to protect the Personal Data and privacy of all individuals across the EU. EU residents will now have greater say over what,
      how, why, where, and when their Personal Data is used, processed, or disposed. This rule clarifies how the EU Personal Data laws apply even beyond the borders of the EU. Any
      organization that works with EU residents' Personal Data in any manner, irrespective of location, has obligations to protect the data.</span
    >
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; text-indent: -0.25in; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">Personal Data</span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Data pertaining to Data Controller’s participants of the event. Personal Data may include, but is not limited to, name, age group, nationality, company name, job title,
      email address, mobile number, payment details, IP address or message content etc.</span
    >
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >If you are submitting Personal Data relating to any other person or on behalf of other individuals, you represent that you have full authorization to provide this
      information to Contactless.io OÜ, and to permit us to use it in compliance with this Privacy Policy.</span
    >
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >With respect to the Personal Data of EU residents, Contactless.io OÜ complies with applicable requirements of the General Data Protection Regulation (GDPR) as it applies to
      Personal Data.</span
    >
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; text-indent: -0.25in; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">Data Processor </span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <i><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">(Contactless.io OÜ) </span></i>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; text-indent: 0.5in; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >The Data Processor is an organization contracted by the Data Controller to carry out the data processing. They deal with the data requested by the Data Controller for
      specific purposes that involve personal data processing.</span
    >
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Contactless.io OÜ being the Data Processor is not responsible for any decisions or actions taken by the Data Controller (Client) with respect to your information (or by any
      third party with whom the Data Controller may share your information).</span
    >
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; text-indent: -0.25in; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">Data Controller</span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <i><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">(Client)</span></i>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >The Data Controller is an organization who owns the data and the responsibility of their participant’s data. They are the one making decisions about data collection and
      processing activities. They also need to ensure its GDPR compliance by defining its requirements to the Data Processor.</span
    >
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">B. Data Collection</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; text-indent: -0.25in; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">From our Website</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >If you visit Contactless.io OÜ website and request a free demo, ask for Service information, or download content, you will be asked to provide certain Personal Data,
      including your contact details.</span
    >
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >We will use this Personal Data for purposes such as answering questions regarding our Service offerings or initiating a sales dialogue.</span
    >
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; text-indent: -0.25in; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">From our Platform</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Data collected via the Contactless.io OÜ platform is collected from or on behalf of our Clients who are hosting or managing events. This information is either collected from
      Client administrators who access the Back-End of our Platform.</span
    >
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >This type of Personal Data is controlled by the Client, and Contactless.io OÜ only collects and processes it at the Client’s instruction, and in accordance with instructions
      provided by the Client, including the applicable terms in any Client Agreement.
    </span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; text-indent: -0.25in; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">From Email messages</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >If you contact a Contactless.io OÜ representative, we will store information such as the email address from which you sent the message and use the information to respond to
      your inquiry.</span
    >
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; text-indent: -0.25in; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: Symbol">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
    ><b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">Information from Cookies</span></b>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Contactless.io OÜ may collect additional information through the use of cookies which are small data files placed on your computer’s hard drive. This will help us to know
      when you visit a particular site or identify which pages are being used to analyze data about web page traffic to better improve our website.</span
    >
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Generally, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie will never give us access
      to your computer or any information about you, other than the data you choose to share with us. You may set your computer’s browser preferences to remove and disable cookies.
      However, please note that some features of the Sites or Services may not be accessible or operate properly if you disable your cookies.</span
    >
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">C. Accessing your Personal Data</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >You can access your account at your convenience to revise or correct errors in your personal data (unless such revisions or corrections may compromise privacy or security
      concerns).</span
    >
  </p>

  <p class="MsoNoSpacing" style="margin-left: 0.5in; text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">D. Retaining your Personal Data</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Contactless.io OÜ will retain Personal Data we collect from our Clients or Event Participants, and process on behalf of the Client, for as long as necessary to provide our
      Services to the Client, and will maintain such information controlled by the Client in accordance with the Client’s instructions, including any applicable Client Agreement
      terms, and as required by applicable law.</span
    >
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">E. Security</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Contactless.io OÜ adheres in protecting the privacy of your Personal Data. We implement appropriate organizational, technical, and administrative measures to protect your
      Personal Data from any unauthorized access and Personal Data Breach.
    </span>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >While Contactless.io OÜ strives take necessary measures in order to protect your Personal Data, please be aware that no data transmission or electronic storage system
      guarantees 100% security. Contactless.io OÜ therefore cannot guarantee the absolute security of your Personal Data.
    </span>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >However, if you feel that the security of your transaction with us has been compromised, please immediately notify us by contacting our Data Protection Officer at </span
    ><a href="mailto:gdpr@contactless.io"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">gdpr@contactless.io</span></a>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">F. Contact Information</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Your privacy is important to us. If you have any questions or concerns about privacy at Contactless.io OÜ, please contact us, and our Data Protection Officer will be happy
      to assist you.</span
    >
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">Ahmed Fawzi</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">Data Protection Officer</span>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">gdpr@contactless.io</span>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">G. Amendments to Privacy Policy</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Our business is constantly evolving and adapting to new changes and, therefore, our Privacy Policy may be updated from time to time. If we make any changes, we will notify
      you either by means of a notice on this website or through email you specified in your account prior to the changes becoming effective.
    </span>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >Please review this section of the Website periodically to check for the latest information on our Privacy Policy.</span
    >
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif"
      >If you want to know more about the EU General Data Protection Regulation, please check this link: </span
    ><a href="https://www.gdpr.eu"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">https://www.gdpr.eu</span></a>
  </p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%"><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></p>

  <p class="MsoNoSpacing" style="text-align: justify; line-height: 115%">
    <b><span style="font-size: 10pt; line-height: 115%; font-family: 'Verdana', sans-serif">&nbsp;</span></b>
  </p>
</div>
