import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { AuthService } from '@auth/services/auth.service'
import { ToastrService } from './toastr.service'
import { map, catchError } from 'rxjs/operators'
import { environment } from '@environments/environment'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'

/**
 * Intercepts outgoing HTTP requests and adds a token to authorize it
 * Also handles Error codes via a toastr service
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  /** Creates a new instances of the interceptor */
  constructor(private auth: AuthService, private toastr: ToastrService, private router: Router) { }
  /**
   * Determines whether a request should be intercepted, cloned with a token or not
   * @param request HttpRequest
   * @returns boolean
   */
  private shouldInterceptToken(request: HttpRequest<any>): boolean {
    return ![
      request.url.startsWith(`${environment.baseContactlessApi}v3/plan/contactless/auth/login`),
      request.url === `${environment.baseContactlessApi}v3/plan/contactless/auth` && request.method === 'GET',
      request.url.startsWith('https://maps.googleapis.com/maps/api/timezone'),
      request.url.startsWith(`${environment.airmeet}`)
    ].some((o) => o)
  }
  /**
   * Determines whether an error toastr should be displayed
   * @param error Http error response
   * @returns boolean
   */
  private shouldDisplayError(error: HttpErrorResponse): boolean {
    if (error.url === `${environment.planningApi}workspaces/legal-info?workspace_id=${this.auth?.currentParentWorkspace?.id}` && error.status === 404) {
      return false
    }
    return true
  }
  /**
   * Intercepts an http requests, clones it, adds a token to it's headers, handles it's errors
   * @param request Http request
   * @param next next http handler
   * @returns Observable<HttpEvent<any>>
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(
        // Check whether we should intercept or not
        this.shouldInterceptToken(request)
          ? // clone the request
            request.clone({
              setHeaders: {
                token: `${this.auth.getToken()}`,
                appId: '1'
              }
            })
          : // return the request
            request
      )
      .pipe(
        /** Map an event to itself */
        map((event: HttpEvent<any>) => event),
        /**
         * Catch an error and handle it
         */
        catchError((error: HttpErrorResponse) => {
          if (error.status == 404) {
            localStorage.removeItem('current-workspace')
            localStorage.removeItem('current-parent-workspace')
            localStorage.removeItem('workspaces')
            this.router.navigate(['not-found'])
          }
          /**
           * Whether an error should be displayed or throwed
           * */
          if (!this.shouldDisplayError(error)) {
            // Throw the message error
            throw error.message
          }
          /** Error status code between 400/ 500 */
          if (error.status >= 400 && error.status < 500) {
            let message = ''
            // if we have a string error, display it as message
            if (typeof error.error === 'string') {
              message = error.error
            } else {
              // Otherwise loop through each property in th error an concat them to a string
              Object.keys(error.error).forEach((key) => {
                // Loop through each property key
                for (let i = 0; i < error.error[key].length; i++) {
                  message = message + error.error[key][i]
                }
              })
            }
            // If we have the email rate limit header (Multiple failed logins)
            if (error.headers.has('email-ratelimit-remaining')) {
              message = message + '\r\n - ' + error.headers.get('email-ratelimit-remaining') + ' attempt(s) remaining'
            }
            if (!(request.url.includes('/crew/users/free/invite') && request.method === 'POST')) {
              this.toastr.error('Error ', `${message}`)
            }
          }
          // throw the complete error
          throw error
        })
      )
  }
}
