import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Input } from '@angular/core'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { Observable, Subject } from 'rxjs'
import { map, startWith, takeUntil } from 'rxjs/operators'
import { RepositoriesService } from '@app/services/repositories.service'
import { FormControl } from '@angular/forms'
@Component({
  selector: 'mt-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit, OnDestroy, AfterViewInit {
  private _destroy$ = new Subject()
  public countries: Observable<{ name: string; flag: string; score: number }[]>
  @Input()
  public label: string
  @Input()
  public control: FormControl
  @ViewChild('country', { read: MatAutocompleteTrigger })
  autoTrigger: MatAutocompleteTrigger

  constructor(private repoService: RepositoriesService) {}

  ngOnInit(): void {
    this.countries = this.control.valueChanges.pipe(
      takeUntil(this._destroy$),
      startWith(''),
      map((value) => this.filterCountries(value))
    )
  }

  /**
   * Get the flag source
   */
  get flag() {
    return this.repoService.getCountryByName(this.control.value)?.flag || 'assets/images/none.svg'
  }

  /**
   * Get the error message from the control
   */
  get errorMessage() {
    let error = ''
    if (!this.control.errors) {
      return error
    }
    Object.keys(this.control.errors)
      .filter((k) => this.control.errors.hasOwnProperty(k))
      .map((k) => (this.control.errors[k] === true ? k : this.control.errors[k]))
      .forEach((k) => (error += k))
    return error
  }

  /**
   * Filter the countries based on the value passed
   * @param value string
   * @returns Array of contry name, flag & prediction score
   */
  filterCountries(value: string) {
    value = value.toLowerCase()
    if (!value) {
      return []
    }
    return this.repoService.CountriesTimezonesRepository.filter((o) => o.name.toLowerCase().includes(value) || o.alternatives.toLowerCase().includes(value))
      .map((o) => {
        return {
          name: o.name,
          flag: o.flag,
          score: o.name.toLowerCase().startsWith(value) ? 1 : 0
        }
      })
      .sort((a, b) => b.score - a.score)
      .slice(0, 6)
  }

  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }

  ngAfterViewInit(): void {
    this.autoTrigger?.panelClosingActions.subscribe(() => {
      if (this.autoTrigger?.activeOption) {
        this.control.setValue(this.autoTrigger.activeOption.value)
        this.countries = null
        this.countries = this.control.valueChanges.pipe(
          takeUntil(this._destroy$),
          startWith(''),
          map((value) => this.filterCountries(value))
        )
      }
    })
  }
}
