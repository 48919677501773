import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { intervalToDuration, isBefore, differenceInDays } from 'date-fns'

@Component({
  selector: 'ngx-landing-timer',
  templateUrl: './landing-timer.component.html',
  styleUrls: ['./landing-timer.component.scss']
})
export class LandingTimerComponent implements OnInit, OnDestroy {
  @Input() startDate: string | number
  @Input() endDate: string | number
  @Input() isLandingPage: boolean = true

  formattedDate: string
  interval: NodeJS.Timeout

  /**
   * Initialize Component and set initial value for formatted date
   */
  ngOnInit(): void {
    this.formatDate()

    this.interval = setInterval(() => {
      this.formatDate()
    }, 1000)
  }

  /**
   * Clear intrval when component is destroyed
   */
  ngOnDestroy(): void {
    clearInterval(this.interval)
  }

  /**
   * Formate Date every second
   * @returns void
   */
  formatDate(): void {
    if (isBefore(new Date(this.startDate), new Date())) {
      if (isBefore(new Date(this.endDate), new Date())) {
        this.formattedDate = 'Finished'
        return clearInterval(this.interval)
      }
      this.formattedDate = 'Live'
      return clearInterval(this.interval)
    }

    // Check if event is in the future and not landing page
    if (!isBefore(new Date(this.startDate), new Date()) && !this.isLandingPage) {
      this.formattedDate = 'Upcoming'
      return clearInterval(this.interval)
    }

    const duration = intervalToDuration({
      start: new Date(this.startDate),
      end: new Date(new Date())
    })

    const days = differenceInDays(new Date(this.startDate), new Date(new Date()))
    if (Object.values(duration).every((v) => !v)) {
      this.formattedDate = 'Live'
      return clearInterval(this.interval)
    }
    this.formattedDate = `${days ? `${days}d:` : ''}${duration.hours}h:${duration.minutes}m:${duration.seconds}s to Start`
  }
}
