import { Component, ElementRef, HostListener } from '@angular/core'
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations'
import { SharedService } from '../../services/shared.service'
/**
 * Displays a list of tutorial events
 */
@Component({
  selector: 'ngx-videos-list',
  templateUrl: './videos-list.component.html',
  styleUrls: ['./videos-list.component.scss'],
  animations: [fadeInOnEnterAnimation(), fadeOutOnLeaveAnimation()]
})
export class VideosListComponent {
  /**
   * Tutorial video lists
   */
  videosList = [
    {
      title: 'MICEtribe First Time Tutorial',
      id: 'qUzOmCAFFS0',
      load: false
    },
    {
      title: 'Add a limited capacity to my event registration',
      id: 'O6JXiXEINNs',
      load: false
    },
    {
      title: 'Make my registration data required',
      id: 'Hwg30wzjY-I',
      load: false
    },
    {
      title: 'Avoid duplicate registrations in my database',
      id: 'vE4oaxv3bos',
      load: false
    },
    {
      title: 'Approve my persona or guest list',
      id: 'moss2qFhHmg',
      load: false
    },
    {
      title: 'Approve my persona or guest list',
      id: 'IJl30J1-37I',
      load: false
    },
    {
      title: 'Access my persona registration links',
      id: '6Qr5eJQ4_20',
      load: false
    },
    {
      title: 'Access the tracking dashboard links',
      id: 'Y8dbeTmMs38',
      load: false
    },
    {
      title: 'Export my database',
      id: '--m1VmgRCf8',
      load: false
    }
  ]
  /**
   * Shows the current video list
   */
  showVideosList = false

  /**
   * Handles clicking outside the video list (closes it)
   * @param event MouseEvent
   */
  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.elementRef.nativeElement.contains(event.target) && this.showVideosList) {
      this.showVideosList = false
    }
  }
  /**
   * Creates the component and injects it's dependencies
   * @param elementRef ElementRef
   * @param sharedService SharedService
   */
  constructor(private elementRef: ElementRef, public sharedService: SharedService) {}

  /**
   * Toggles the video list
   */
  toggleVideoList() {
    this.showVideosList = !this.showVideosList
  }
  /**
   * Sets the currently loaded video (playing)
   * @param event string
   */
  handleListLoad(event) {
    this.videosList.forEach((item) => (item.load = event === item.id))
  }
}
