/**
 * Contains a list of environment variables
 * API Endpoints
 * API Keys
 * Public frontends urls
 */
export const environment = {
  production: false,
  micetribeEvents: 'https://events.development.micetribe.com/',
  planningApi: 'https://api.development.micetribe.com/v3/plan/contactless/',
  planningDashboard: 'https://api.development.micetribe.com/v3/dashboards/',
  registrationsApi: 'https://api.development.micetribe.com/v3/registrations/',
  searchApi: 'https://api.development.micetribe.com/v3/search',
  elasticSearch: 'https://api.development.micetribe.com/v3/elasticsearch',
  handshakesApi: 'https://api.development.micetribe.com/v3/handshake/',
  exportsApi: 'https://exports.development.micetribe.com/',
  publicFormsUrl: 'https://app.development.micetribe.com/public/',
  landingPageUrl: 'https://app.development.micetribe.com/register/',
  baseFormsUrl: 'https://app.development.micetribe.com/',
  baseUrl: 'https://plan.development.micetribe.com/',
  stripeKey: 'pk_test_47Z7iMDWR236BIUktKpxkWXG',
  publicGoogleMapsAPIKey: 'AIzaSyBE9vDiiFxTVsZJem1gnxPpCZQrpr9ApSM',
  googleMapsAPIKey: 'AIzaSyCchlV70JS27eCJaaSqZKLaUf3gDJf6Ejc',
  crewApi: 'https://api.development.micetribe.com/v3/crew/',
  baseContactlessApi: 'https://api.development.micetribe.com/',
  dashboardsBaseUrl: 'https://dashboards.development.micetribe.com/',
  publicPersonaPageUrl: 'https://events.development.micetribe.com/',
  benchmarkApi: 'https://clientapi.benchmarkemail.com/',
  benchmarkExportApi: 'https://api.development.micetribe.com/v3/benchmark/',
  rtsUrl: 'https://rts.development.micetribe.com/',
  rtsWS: 'wss://rts.development.micetribe.com/ws/',
  crewSystem: 'https://crew.development.micetribe.com',
  airmeet: 'https://api.development.micetribe.com/v3/integrations/airmeet',
  industrySector: 'https://api.development.micetribe.com/v3/plan/contactless/industrySector',
  appVersion: 'prestaging.build-50',
  gaMeasurementId: '',
  mediaLibrary: 'https://api.development.micetribe.com/v3/library/',
  sentry: {
    dsn: 'https://df2e721aa8134c08aa2d559841314209@sentry.development.micetribe.com/5922241',
    environment: 'staging',
    sampleRate: 1.0
  },
  productFruitsWorkspace: 'UgGAdGMkAGhbHUus',
  mapboxToken: 'pk.eyJ1IjoiY29udGFjdGxlc3MiLCJhIjoiY2xobXpwMjJrMWFlcDNlb3h5aGh4bjJoOSJ9.ulKz7o1wPpEBk48Tz33BYw',
  tinyMCEApiKey: 'rq9lnthcyfkxy5t5fj1krg7crd4ld1tqv53k4o9dfuhckq6f'
}

