<div class="container-fluid" *ngIf="mode === 'select'">
  <div class="row">
    <div class="col-12">
      <h6 [class]="'text-' + labelAlignment" *ngIf="label">{{ label }}</h6>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <nb-form-field>
        <nb-select fullWidth placeholder="Select a workspace" (selectedChange)="select($event)">
          <nb-option *ngFor="let workspace of workspaces$ | async" [value]="workspace">
            {{ workspace.name }}
          </nb-option>
        </nb-select>
      </nb-form-field>
    </div>
  </div>
</div>
<div class="container-fluid bg-content P-0" *ngIf="mode === 'cards'">
  <div class="row">
    <div class="col-12 title">
      <div [class]="'h1 text-' + labelAlignment">{{ label }}</div>
    </div>
  </div>
  <nb-list class="overflow-hidden" nbInfiniteList listenWindowScroll [threshold]="100" (bottomThreshold)="loadNext()">
    <nb-list-item class="row border-bottom-0">
      <div class="col-6" *ngFor="let workspace of workspaces$ | async">
        <ngx-workspaces-details
          [workspace]="workspace"
          mode="regular"
          [noActions]="true"
          (click)="select(workspace)"
          [showPlan]="true"
          [emitEvents]="false"
        ></ngx-workspaces-details>
      </div>
      <div class="col-12" *ngIf="noMoreWorkspaces">
        <h6 class="text-center py-4">You don’t have anymore workspaces.</h6>
      </div>
    </nb-list-item>
  </nb-list>
</div>
