import { Component, OnInit, OnDestroy } from '@angular/core'
import { AuthService } from '@auth/services/auth.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { EmailValidator } from '@auth/validators/email.validator'

/**
 * Takes a user email and sends a reset password message to it's inbox
 */
@Component({
  selector: 'ngx-auth-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * Form group to collect email address
   */
  form: FormGroup
  /**
   * Determines if the component is performing an async operation
   */
  processing = false
  /**
   * Determines whether the reset password email is sent
   */
  emailSent = false
  /**
   * Creates the component & injects it's dependencies
   * @param fb FormBuilder
   * @param authService AuthService
   * @param router Router
   * @param activatedRoute activated route
   */
  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute) {}

  /**
   * On init implementation - creates the form group
   */
  ngOnInit(): void {
    this.form = this.fb.group({
      email: [this.activatedRoute.snapshot.queryParams.email ?? '', [Validators.required, EmailValidator.validate]]
    })
  }

  /**
   * Sends the reset password email
   * @param form Formgroup form
   */
  send(form) {
    form = this.form.value
    this.processing = true
    this.authService
      .confirmEmail(form)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this.processing = false
          this.emailSent = true
        },
        (error) => {
          this.processing = false
        }
      )
  }

  /**
   * Navigates to login page
   */
  navigateToLogin() {
    this.router.navigateByUrl('/auth/login')
  }
}
