import { ResetPasswordComponent } from '@auth/components/reset-password/reset-password.component'
import { ConfirmEmailComponent } from '@auth/components/confirm-email/confirm-email.component'
import { RouterModule } from '@angular/router'
import { LoginComponent } from '@auth/components/login/login.component'
import { NgModule } from '@angular/core'
import { AuthGuard } from '@app/guards/auth.guard'
import { GuestGuard } from '@app/guards/guest.guard'
import { NbCardModule, NbSpinnerModule, NbIconModule, NbFormFieldModule, NbButtonModule, NbInputModule } from '@nebular/theme'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { ConfirmEmailAlertComponent } from '@auth/components/confirmation-email/confirmation-email.component'
import { InvoicePlansComponent } from '@auth/components/invoice-plans/invoice-plans.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatCardModule } from '@angular/material/card'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
/**
 * Authentication module & routes
 */
@NgModule({
  imports: [
    CommonModule,
    NbCardModule,
    NbSpinnerModule,
    ReactiveFormsModule,
    NbButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    NbIconModule,
    NbFormFieldModule,
    NbInputModule,
    MatIconModule,
    RouterModule.forChild([
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'confirm-email',
        component: ConfirmEmailAlertComponent
      },
      {
        path: 'reset',
        component: ConfirmEmailComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'login'
      }
    ])
  ],
  exports: [InvoicePlansComponent],
  declarations: [LoginComponent, ResetPasswordComponent, ConfirmEmailComponent, ConfirmEmailAlertComponent, InvoicePlansComponent],
  providers: [AuthGuard, GuestGuard]
})
export class AuthModule {}
