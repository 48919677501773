import { Component, Input } from '@angular/core'

@Component({
  selector: 'ngx-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent {
  @Input() qrData: string = ''
  @Input() width: string = '100%'
  @Input() format: string = 'svg'
  @Input() qrCodeColor: string = '#000000ff'
  @Input() backgroundColor: string = '#ffffffff'
  @Input() id: string
}
