import { Component, OnInit, TemplateRef, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, HostListener } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@auth/services/auth.service'
import { NbMenuService } from '@nebular/theme'
import { NbMenuItem } from '@nebular/theme'
import { filter, map, takeUntil } from 'rxjs/operators'
import { NbDialogService } from '@nebular/theme'
import { InvoicePlansComponent } from '@auth/components/invoice-plans/invoice-plans.component'
import { Subject, interval } from 'rxjs'
import { WorkspaceRoleService } from '@app/modules/auth/services/workspace-role.service'
import { environment } from '@environments/environment'
import { RealTimeService } from '@app/modules/workspaces/services/real-time.service'
import { ToastrService } from '@app/services/toastr.service'
import { IRunningJob } from './running-jobs/model/running-jobs.interface'
import { InviteService } from '@app/modules/invite/services/invite.service'
/**
 * Represents shared module header
 */
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** Menu items for user menu */
  private originalMenuItems = [
    { title: 'Switch Workspace', icon: { icon: 'briefcase', pack: 'font-awesome' }, link: '/workspaces/list' },
    { title: 'Billing', icon: { icon: 'credit-card', pack: 'font-awesome' }, link: '/billing' },
    { title: 'Settings', icon: { icon: 'cog', pack: 'font-awesome' }, link: 'app-settings/profile' },
    { title: 'Logout', icon: { icon: 'log-out', pack: 'eva' } }
  ]

  runningJobs: IRunningJob[]
  hasRunningJobs: boolean
  jobsContainerOpened: boolean = false
  showTip: boolean = false
  @ViewChild('jobsButton') jobsButton: ElementRef
  @ViewChild('jobsContainer') jobsContainer: ElementRef

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.jobsButton?.nativeElement.contains(event.target) && !this.jobsContainer?.nativeElement.contains(event.target)) {
      this.jobsContainerOpened = false
    }
  }
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * Current dialog ref
   */
  dialog: TemplateRef<any>

  /**
   * Count of unread announcements
   */
  unreadCount: number = 0

  /**
   * Triggered once the productFruits API is loaded
   */
  productFruitsLoaded$: Subject<boolean> = new Subject()

  /**
   * Holds the ID of the timeout to be stopped
   */
  apiVerfierTimeOut: NodeJS.Timeout

  /**
   * Is the menu open
   */
  menuIsOpen: boolean = false

  duration: number = 5 * 60 * 1000
  /**
   * Creates the component and injects it's dependencies
   * @param dialogService NbDialogService
   * @param nbMenuService NbMenuService
   * @param auth AuthService
   * @param router Router
   * @param cdr ChangeDetectorRef
   * @param roleService WorkspaceRoleService
   */
  constructor(
    private dialogService: NbDialogService,
    private nbMenuService: NbMenuService,
    private auth: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private roleService: WorkspaceRoleService,
    private realTimeService: RealTimeService,
    private toastrService: ToastrService,
    private inviteService: InviteService
  ) {}
  /**
   * Current menu items
   */
  items: NbMenuItem[] = JSON.parse(JSON.stringify(this.originalMenuItems))
  /**
   * Initializes the component
   */
  ngOnInit() {
    this.auth.currentWorkspaceChange.pipe(takeUntil(this._destroy$)).subscribe((ws) => {
      this.resetMenuItems()
      if (this.auth.currentWorkspace) this.getAndSetRunningJobs(this.auth.currentWorkspace.slug)
      this.cdr.detectChanges()
    })

    // Calling the getAndSetRunningJobs method every 30 minutes
    interval(this.duration)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.getAndSetRunningJobs(this.auth?.currentWorkspace?.slug)
      })

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map((item) => item.item)
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe((item) => {
        this.menuIsOpen = item.selected
        if (item.title === 'Logout') {
          this.logOut()
        } else if (item.title === 'Upgrade to pro') {
          this.dialogService.open(InvoicePlansComponent)
        }
      })

    this.auth.currentWorkspaceChange
      .pipe(
        takeUntil(this._destroy$),
        filter((workspace) => !!workspace)
      )
      .subscribe(() => this.establishWebsocketConnection())



    this.auth.runningJobs$.pipe(takeUntil(this._destroy$)).subscribe((jobs) => {
      this.runningJobs = jobs
      this.hasRunningJobs = jobs.length > 0
    })
  }

  toggleJobsContainer(event) {
    this.showTip = false
    if (this.jobsButton.nativeElement.contains(event.target)) {
      this.jobsContainerOpened = !this.jobsContainerOpened
    }
  }

  getAndSetRunningJobs(workspace_slug: string): void {
    this.inviteService.getRunningJobs(workspace_slug).subscribe((jobs: IRunningJob[]) => {
      this.runningJobs = jobs
      this.auth.updateRunningJobs(jobs)
    })
  }
  /**
   * Resets the menu items
   */
  resetMenuItems() {
    this.roleService.getUserRole().subscribe(() => {
      let filteredMenuList = this.roleService.canAddCreditcard() ? this.originalMenuItems : this.originalMenuItems.filter((item) => item.title !== 'Billing')
      this.items = JSON.parse(JSON.stringify(filteredMenuList))
      if (this.auth?.user?.is_admin && !this.items.find((o) => o.title === 'Metrics')) {
        this.items.splice(this.items.length - 2, 0, {
          title: 'Metrics',
          icon: { icon: 'bar-chart-outline', pack: 'eva' },
          link: 'admin/metrics'
        })
      }
    })
  }
  /**
   * Navigtes to the home route based on role
   */
  navigateHome() {
    if (this.isLoggedIn) {
      this.router.navigate(['workspaces', 'list'])
    }
  }

  /** Logs the user out and clears local storage */
  logOut() {
    this.auth.user = null
    this.auth.currentWorkspace = null
    this.auth.currentParentWorkspace = null
    this.auth.workspaces = null
    this.auth.plans = null
    if (!!localStorage.getItem('benchmarkToken')) {
      localStorage.removeItem('benchmarkToken')
    }
    this.router.navigate(['/auth', 'login'])
  }

  /**
   * Connect to the RTS websocket using the workspace slug,
   * and handle the websocket messages
   */
  establishWebsocketConnection() {
    let CURRENT_WORKSPACE = this.auth.currentWorkspace
    this.realTimeService.open(`${environment.rtsWS}updates/${CURRENT_WORKSPACE.slug}`)
    this.realTimeService.messages$.pipe(takeUntil(this._destroy$)).subscribe((res) => {
      if (CURRENT_WORKSPACE.id != res.workspaceData.id) return
      const plan = this.auth.plans.find((p) => p.id === res.workspaceData.plan_id)
      this.auth.currentWorkspace = { ...res.workspaceData, plan }
      if (!res.workspaceData.parent) this.auth.currentParentWorkspace = this.auth.currentWorkspace
      // To warn user for chaning the plan
      if (res.workspaceData.plan_id != CURRENT_WORKSPACE.plan_id) this.toastrService.warning('Heads Up!', 'Plan has been changed.')
    })
  }

  navigateToSettings() {
    this.router.navigate(['app-settings/profile'])
  }

  /**
   * Is the user logged in
   */
  get isLoggedIn(): boolean {
    return this.auth.isLoggedIn
  }
  /**
   * Should we show workspace badge
   */
  get showBadge() {
    return this.auth.role !== 'Admin' && this.isLoggedIn
  }
  /** Current authenticated user */
  get user() {
    return this.auth.user
  }

  showTooltip(element: HTMLElement) {
    if (!this.jobsContainerOpened) this.showTip = true
  }

  hideTooltip(element: HTMLElement) {
    this.showTip = false
  }
}
