<div class="card">
  <div class="card-header">
    <span class="text">Top up with new payment method</span>
    <span class="icon" (click)="close()"
      ><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.43394 9.00002L17.7301 0.703815C17.8507 0.583215 17.8507 0.390615 17.7301 0.270015C17.6095 0.149415 17.4169 0.149415 17.2963 0.270015L9.00014 8.56622L0.703937 0.270015C0.583337 0.151215 0.388937 0.151215 0.270137 0.270015C0.149537 0.390615 0.149537 0.583215 0.270137 0.703815L8.56634 9.00002L0.270137 17.2962C0.149537 17.4168 0.149537 17.6094 0.270137 17.73C0.329537 17.7894 0.408737 17.82 0.486137 17.82C0.565337 17.82 0.642737 17.7894 0.702137 17.73L9.00014 9.43382L17.2963 17.73C17.3557 17.7894 17.4349 17.82 17.5123 17.82C17.5897 17.82 17.6689 17.7894 17.7283 17.73C17.8489 17.6094 17.8489 17.4168 17.7283 17.2962L9.43394 9.00002Z"
          fill="white"
        />
      </svg>
    </span>
  </div>
  <div class="row content" [nbSpinner]="processing" *ngIf="finished === null">
    <form novalidate (ngSubmit)="createToken()" [formGroup]="stripePaymentForm" class="w-100" id="payment-form">
      <label for="card-element">Credit or debit card</label>
      <div id="card-element" class="d-block w-100 mb-5"></div>
      <div id="card-errors" role="alert" class="d-block w-100 mb-5"></div>
      <button nbButton status="primary" type="submit" class="mb-2">Top up ${{ amount }}</button>
    </form>
  </div>

  <div class="card success secondView" *ngIf="finished === true">
    <div class="icon">
      <span>
        <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.5004 37.73L11.7704 28L8.45703 31.29L21.5004 44.3333L49.5004 16.3333L46.2104 13.0433L21.5004 37.73Z" fill="#00E096" />
        </svg>
      </span>
    </div>
    <div class="text">Your wallet has been successfully topped up</div>
    <div class="btn">
      <button nbButton status="success" (click)="close()">Done!</button>
    </div>
  </div>

  <div class="card failed secondView" *ngIf="finished === false">
    <div class="icon">
      <span>x</span>
    </div>
    <div class="text">Something went wrong, Unfortunately, your wallet didn't top up</div>
    <div class="text">{{ error }}</div>
    <div class="btn">
      <button nbButton status="danger" (click)="close()">Ok</button>
    </div>
  </div>
</div>
