<nb-card size="large" class="p-3 content">
  <nb-card-header *ngIf="invitationInfo.isInvitationModal">
    <p>Can invite {{ optOutStatus.notOptOut }} / can't invite {{ optOutStatus.opt_out }} <img src="" alt="" /></p>
    <nb-form-field class="search-field" *ngIf="state === InvitationState.EVENT">
      <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
      <input type="text" [ngModel]="searchTerm" (ngModelChange)="onSearchTermChangedEvent($event)" nbInput placeholder="Search" class="mr-2" />
    </nb-form-field>
    <div class="title" *ngIf="state === InvitationState.PERSONA">Select your persona</div>
    <div class="title" *ngIf="state === InvitationState.MESSAGE">Customize your invitation</div>
  </nb-card-header>
  <nb-card-header *ngIf="!invitationInfo.isInvitationModal">
    <p>Changing workspace payment gateway can affect it's paid events</p>
  </nb-card-header>
  <nb-card-body [nbSpinner]="processing">
    <div class="custom-scrollable-content">
      <ngx-invite-to-event *ngIf="state === InvitationState.EVENT" [events]="events" (eventSelected)="setEvent($event)"></ngx-invite-to-event>
      <ngx-invite-to-persona *ngIf="state === InvitationState.PERSONA" [personas]="personasList" [selectedPersona]="selectedPersonaSlug" (personaSelected)="setPersona($event)">
      </ngx-invite-to-persona>
      <ngx-templates
        *ngIf="state === InvitationState.MESSAGE && eventTimings"
        [invitationInfo]="invitationInfo"
        [workspaceLogo]="authService.currentWorkspace.logo"
        [workspaceName]="authService.currentWorkspace.name"
        [event]="event"
        [eventTimings]="eventTimings"
      ></ngx-templates>

      <div class="complete text-center h-100 d-flex justify-content-center align-items-center" *ngIf="state === InvitationState.COMPLETE">
        <div>
          <img src="https://library.micetribe.com/images/email-sent.svg" alt="" />
          <div class="invitation-sent">
            Your job is running in the background right now we will send you a job report once the job is completed if you want to track progress for this running job press on
            running jobs in header
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer *ngIf="state === InvitationState.PERSONA || state === InvitationState.MESSAGE || state === InvitationState.COMPLETE">
    <div class="d-flex justify-content-between align-items-center">
      <button class="invitation-button pr-4" (click)="back()" *ngIf="state === InvitationState.PERSONA || state === InvitationState.MESSAGE">
        <mat-icon class="back" style="cursor: pointer">navigate_before</mat-icon> Back
      </button>

      <div class="d-flex justify-content-end align-items-end">
        <button class="invitation-button send-test mr-3" (click)="inviteTest()" *ngIf="state === InvitationState.MESSAGE">
          Send test to<br>{{authService.user.email}}
        </button>
        <button class="invitation-button invite" (click)="invite()" *ngIf="state === InvitationState.MESSAGE"><mat-icon class="mr-2">email</mat-icon> Send</button>
      </div>
      <button class="invitation-button invite" (click)="ref.close()" *ngIf="state === InvitationState.COMPLETE" style="margin-left: auto">
        <mat-icon class="mr-2">check</mat-icon> Done
      </button>
    </div>
  </nb-card-footer>
  <nb-card-footer *ngIf="!invitationInfo.isInvitationModal">
      <button class="invitation-button invite float-right" (click)="ref.close()">
        <mat-icon class="mr-2">close</mat-icon> Close
      </button>
  </nb-card-footer>
</nb-card>
