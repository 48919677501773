import { AuthService } from '@auth/services/auth.service'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { OnInit, Component, OnDestroy, ViewChild } from '@angular/core'
import { Workspace } from '@workspaces/interfaces/workspace.interface'
import { ToastrService } from '@app/services/toastr.service'
import { WorkspaceService } from '@workspaces/services/workspace.service'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { Router } from '@angular/router'
import { HeaderComponent } from './header/header.component'
/**
 * Main workspaces component
 */
@Component({
  selector: 'ngx-workspaces',
  templateUrl: './workspaces.component.html',
  styleUrls: ['./workspaces.component.scss']
})
export class WorkspacesComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * Workspaces list
   */
  workspaces: Workspace[]

  /**
   * Current worksapce id
   */
  workspaceId: number

  /**
   * Edit form that represents a workspace
   */
  editForm: FormGroup

  /**
   * Current workspace
   */
  workspace: Workspace

  /**
   * show noMoreWorkspaces text
   */
  noMoreWorkspaces: boolean = false

  /**
   * Header component view child
   */
  @ViewChild(HeaderComponent)
  private headerViewChild: HeaderComponent

  /**
   * Creates the component and injects it's dependencies
   * @param workspaceService WorkspaceService
   * @param fb FormBuilder
   * @param toastr ToastrService
   * @param auth AuthService
   * @param router Router
   */
  constructor(private workspaceService: WorkspaceService, private fb: FormBuilder, private toastr: ToastrService, private auth: AuthService, private router: Router) {}

  /**
   * Updates current workspace list
   * @param workspaces Workspace[]
   */
  updateWorkspaces(workspaces: Workspace[]) {
    this.workspaces = workspaces
  }
  /**
   * Gets the current workspace role
   */
  public get role() {
    return this.auth.currentWorkspace?.role?.name
  }

  /**
   * Initializes the component
   */
  ngOnInit() {
    if (this.auth.role === 'Admin' && this.router.url === '/workspaces/list') {
      this.auth.currentWorkspace = null
    }
    this.editForm = this.fb.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      slug: ['', Validators.required],
      financialDashboardAccessKey: [1],
      marketingDashboardAccessKey: [1]
    })
  }

  /**
   * Creates an edit form group instance of the workspace
   * @param workspace Workspace
   * @returns void
   */
  edit(workspace: any) {
    if (this.workspaceId === workspace['id']) {
      this.workspaceId = null
      return
    }
    this.workspaceId = workspace['id']
    this.workspace = workspace
    this.editForm.patchValue(workspace)
  }

  /**
   * Saves the current workspace
   * @param workspace any
   */
  save(workspace: any) {
    this.workspaceService
      .updateWorkspace(this.changedFields(this.editForm.value))
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          this.clearForm()
          setTimeout(() => {
            document.getElementById(`${this.workspaceId}`).scrollIntoView()
            this.workspaceId = null
          }, 300)

          this.toastr.success('Workspace Updated!', 'Your edits have been saved')
        },
        (error) => {
          if (error.status >= 500) {
            this.toastr.error('Workspace not Updated!', `Error ${error.error}`)
          }
        }
      )
  }

  /**
   * Clears workspace form
   */
  clearForm() {
    this.workspace = null
    this.editForm.patchValue({
      name: '',
      slug: '',
      financialDashboard: 0,
      marketingDashboard: 1
    })
  }

  /**
   * List of changed workspace fields
   */
  changedFields(workspace: any): any {
    const changedEntries = {}
    Object.keys(workspace).forEach((key) => {
      if (this.workspace[key] !== workspace[key]) {
        changedEntries[key] = workspace[key]
      }
    })
    changedEntries['id'] = this.workspace['id']
    return changedEntries
  }
  /**
   * Loads next workspaces
   */
  loadNext() {
    this.headerViewChild.loadNext()
  }
  /**
   * Emits true when no more workspaces
   */
  showNoMore(noMore: boolean) {
    this.noMoreWorkspaces = noMore
  }
}
