import { WorkspacesModule } from '@workspaces/workspaces.module'
import { PaymentsModule } from '@payments/payments.module'
import { RouterModule } from '@angular/router'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { MatIconModule } from '@angular/material/icon'
import {
  NbLayoutModule,
  NbSidebarModule,
  NbMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
  NbChatModule,
  NbStepperModule,
  NbInputModule,
  NbCardModule,
  NbButtonModule,
  NbActionsModule,
  NbUserModule,
  NbCheckboxModule,
  NbRadioModule,
  NbFormFieldModule,
  NbIconModule,
  NbAccordionModule,
  NbSpinnerModule,
  NbContextMenuModule,
  NbThemeModule
} from '@nebular/theme'
import { NbEvaIconsModule } from '@nebular/eva-icons'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ClipboardModule } from 'ngx-clipboard'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { TokenInterceptor } from './services/token.interceptor'
import { DataPrivacyComponent } from './modules/shared/components/data-privacy/data-privacy.component'
import { VideosListComponent } from './modules/shared/components/videos-list/videos-list.component'
import { VideoItemComponent } from './modules/shared/components/videos-list/video-item/video-item.component'
import { NewVersionModalComponent } from '@auth/components/new-version-modal/new-version-modal.component'
import { HeaderComponent } from './modules/shared/header/header.component'
import { AuthModule } from './modules/auth/auth.module'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatMenuModule } from '@angular/material/menu'
import { TermsAndConditionsComponent } from './modules/shared/components/terms-and-conditions/terms-and-conditions.component'
import { PhoneNumberInputDirective } from './directives/phone-number-input.directive'
import { NgxMaskModule } from 'ngx-mask'
import { RoleGuard } from './guards/role.guard'
import { UserDropdownComponent } from './modules/shared/components/user-dropdown/user-dropdown.component'
import { MatBadgeModule } from '@angular/material/badge'
import { CarouselModule } from 'ngx-owl-carousel-o'
import { RunningJobsComponent } from './modules/shared/header/running-jobs/running-jobs.component'
import { DialogOverviewExampleDialog } from './modules/shared/components/dialog/dialog.component'

@NgModule({
  declarations: [
    AppComponent,
    PhoneNumberInputDirective,
    HeaderComponent,
    DataPrivacyComponent,
    TermsAndConditionsComponent,
    VideosListComponent,
    VideoItemComponent,
    NewVersionModalComponent,
    UserDropdownComponent,
    RunningJobsComponent,
    DialogOverviewExampleDialog
  ],

  imports: [
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot({
      duration: 50,
      destroyByClick: true
    }),
    NbThemeModule.forRoot({
      name: 'default'
    }),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY'
    }),
    NbStepperModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbFormFieldModule,
    NbIconModule,
    MatIconModule,
    MatTooltipModule,
    NbAccordionModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    DragDropModule,
    NbSpinnerModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    NbLayoutModule,
    NbEvaIconsModule,
    NbContextMenuModule,
    WorkspacesModule,
    PaymentsModule,
    AuthModule,
    CarouselModule,
    NgxMaskModule.forRoot(),
    MatMenuModule,
    NgxMaskModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxMaskModule.forRoot(),
    MatBadgeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    RoleGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
