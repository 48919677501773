import { FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms'
import { UserRoles } from '@app/modules/auth/enums/user-roles.enum'
/**
 * Defines a workspace assignee information
 */
export interface AssigneeUser {
  /**
   * User id
   */
  id: number
  /**
   * User email
   */
  email: string
}
/**
 * Defines a workspace assignee
 */
export interface Assignee {
  /**
   * Assignation id
   */
  id: number
  /**
   * user id
   */
  user_id: number
  /**
   * workspace id
   */
  workspace_id: number
  /**
   * Whether the user is an owner of the workspace
   */
  is_owner: boolean
  /**
   * user role
   */
  role: string
  /**
   * Workspace assignee information @see AssigneeUser
   */
  user: AssigneeUser
}

/**
 * Defines a factory that's responsible for generating forms, arrays, and validation of multiple assignees
 */
export class AssigneeFactory {
  /**
   * Form builder instance
   */
  static formBuilder: FormBuilder = new FormBuilder()

  /**
   * Generates a form array of multiple assignees
   * @param assignees assignee array
   * @param workspaceId workspace id
   * @returns FormArray
   */
  static generateAssigneesArray(assignees: Assignee[], workspaceId: number): FormArray {
    return AssigneeFactory.formBuilder.array(assignees.map((assignee) => this.generateAssigneeForm(assignee, workspaceId)))
  }
  /**
   * Generates a single assignee form group
   * @param assignee assignee information
   * @param workspaceId workspace id
   * @returns FormGroup
   */
  static generateAssigneeForm(assignee: Assignee = null, workspaceId: Number) {
    return AssigneeFactory.formBuilder.group({
      email: [assignee ? { value: assignee.user.email, disabled: true } : '', [Validators.required, AssigneeFactory.emailValidator]],
      is_owner: [assignee ? assignee.is_owner : false],
      role: [assignee ? assignee.role : UserRoles.COLLABORATOR],
      id: [assignee ? assignee.id : null],
      user_id: [assignee ? assignee.user_id : null],
      workspace_id: [workspaceId]
    })
  }
  /**
   * validates an assignee email based on a regex
   * @param control Abstract control
   * @returns error or null
   */
  static emailValidator(control: AbstractControl): { [key: string]: any } | null {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !regex.test(control.value) ? { incorrectEmail: { value: control.value } } : null
  }

  /**
   * Validates whether an assignee email is duplicated in the assignee form array list
   * @param control abstract control
   * @returns error or null
   */
  static duplicateValidator(control: AbstractControl): { [key: string]: any } | null {
    let duplicate = ''
    control.value.users.some((element) => {
      if (control.value.users.filter((o) => o.email === element.email).length > 1) {
        duplicate = element.email
        return true
      }
    })
    return duplicate ? { duplicateEmail: duplicate } : null
  }

  /**
   * Determins whether an assignee email is disabled
   * @param assignee assignee information
   * @param currentEmail current user email
   * @param assignerRole assigner role
   * @returns boolean
   */
  private static disableEdit(assignee: Assignee, currentEmail: string, assignerRole: UserRoles | string) {
    return (
      assignee.user.email === currentEmail ||
      (assignerRole === UserRoles.COLLABORATOR ? assignee.role !== UserRoles.VIEWER : !['owner', UserRoles.ADMIN, UserRoles.COLLABORATOR].includes(assignerRole))
    )
  }
}
