<div class="dialog-class">
  <div class="info-logo">
    <div class="logo" *ngIf="!data?.hideIcon">
      <img src="assets/images/alert.svg" alt="" />
    </div>
    <div class="info">
      <h3>{{ data?.headerText }}</h3>
      <p>{{ data?.messageText }}</p>
    </div>
  </div>
  <div class="buttons">
    <button [ngClass]="data?.cancelButtonClass? data?.cancelButtonClass: 'cancel'" (click)="dialogRef.close()">{{ data?.cancelButtonText }}</button>
    <button  [class]="data.confirmButtonClass" (click)="proceed('confirm')">{{ data?.confirmButtonText }}</button>
    <button *ngIf="data?.confirmTwoButtonText" [class]="data.ConfirmButtonTwoClass" class="darker-red" (click)="proceed('confirmTwo')">{{ data?.confirmTwoButtonText }}</button>
  </div>
</div>
