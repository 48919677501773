import { Component, OnInit, OnDestroy } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { AuthService } from '@auth/services/auth.service'
import { Router } from '@angular/router'
import { WorkspaceService } from '@workspaces/services/workspace.service'
import { ToastrService } from '@app/services/toastr.service'
import { forkJoin, Observable, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { ValidateFullName } from '@app/validators/validate-full-name'
import { CountryValidator } from '@micetribe/forms/validators/country.validator'
import { RequiredPhoneValidator } from '@micetribe/forms/validators/required-phone.validator'
import { PhoneValidator } from '@micetribe/forms/validators/phone.validator'
import { country, RepositoriesService } from '@app/services/repositories.service'

/**
 * Component that is displayed after signing up to the system
 * It collects workspace details and creates the first workspace for the user
 */
@Component({
  selector: 'ngx-almost-there',
  templateUrl: './almost-there.component.html',
  styleUrls: ['./almost-there.component.scss']
})
export class AlmostThereComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()

  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /** Form group to collect workspace details */
  form: FormGroup
  /** Error due to invalid input */
  error: any
  /** Whether the component is performing an async operation */
  processing = false
  /** Observable of filtered list of countries */
  filteredCountries$: Observable<country[] | []>
  /**
   * Creates the component & injects it's dependencies
   * @param fb FormBuilder
   * @param authService AuthService
   * @param service WorkspaceService
   * @param router Router
   * @param toastrService ToastrService
   * @param repositoriesService RepositoriesService
   */
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private service: WorkspaceService,
    private router: Router,
    private toastrService: ToastrService,
    private repositoriesService: RepositoriesService,
  ) {}

  /** OnInit implementation
   * generates the form group to collect workspace information
   */
  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      slug: ['', [Validators.required, slugValidator]],
      full_name: [this.authService.user.full_name || '', [Validators.required, ValidateFullName]],
      phone: [
        this.authService.user.phone_number || '',
        [Validators.required, RequiredPhoneValidator.validateWith({ defaultCountry: '' }), PhoneValidator.validateWith({ defaultCountry: '' })]
      ],
      country: ['', [Validators.required, CountryValidator.validateWith()]],
      financialDashboardAccessKey: [true, Validators.requiredTrue],
      marketingDashboardAccessKey: [true, Validators.requiredTrue],
      role_id: [1, Validators.required],
      email: [this.authService.user.email]
    })
    this.form.controls['full_name'].valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      const capitalizedFirstLetterRegex = /\b[a-z](?!\s)/g
      const nonLettersRegex = /[^a-zA-Z\u00C0-\u00FF\u0621-\u064A\.\s]/g
      const moreThanOneSpaceRegex = / {2,}/g
      const moreThanOneDotRegex = /\.{2,}/g
      value = value
        .replace(nonLettersRegex, '')
        .replace(moreThanOneSpaceRegex, ' ')
        .replace(moreThanOneDotRegex, '.')
        .replace(capitalizedFirstLetterRegex, (letter) => letter.toUpperCase())
      value = value.startsWith(' ') ? value.trim() : value
      this.form.controls['full_name'].setValue(value, { emitEvent: false })
    })
    this.form.controls['phone'].valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      const multiplePlusSigns = /\+{2,}/
      value = value.trim().replace(multiplePlusSigns, '+')
      this.form.controls['phone'].patchValue(value, { emitEvent: false })
    })
    this.filteredCountries$ = this.form.controls['country'].valueChanges.pipe(
      takeUntil(this._destroy$),
      map((filterString) => this.filter(filterString))
    )
  }

  /**
   * Filter countries list for the given value
   * @param value string
   * @returns
   */
  private filter(value: string) {
    return this.repositoriesService.CountriesTimezonesRepository.filter((o) => o.name.toLowerCase().includes(value.toLowerCase()))
  }

  /**
   * generates workspace slug based on it's name
   * @param name workspace name
   * @deprecated
   */
  generateAcronym(name: string) {
    this.form.controls['slug'].patchValue(this.uniqueId())
  }
  /**
   * creates the workspace
   */
  createWorkspace() {
    this.error = null
    this.processing = true
    const countryIso2 = this.repositoriesService.getCountryByName(this.form.value.country).iso2
    const formattedPhone = this.form.controls['phone'].value.replaceAll(' ', '')
    forkJoin([
      this.service.createWorkspace({ ...this.form.value, phone: formattedPhone }),
      this.authService.updateUser({ full_name: this.form.value.full_name, phone_number: formattedPhone })
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        ([workspace, user]) => {
          // Update country in organtization info
          import('../../../../modules/events/event.module').then(({ EventModule }) => {
            this.authService.updateOrganization({ workspace_id: workspace.id, country: countryIso2 }).subscribe()
            this.toastrService.success('Congratulations!', 'Workspace manager was assigned his new position.')
            this.authService.user = user
            this.processing = false
            this.authService.currentWorkspace = workspace
            if (workspace.role_id === 1) {
              this.authService.currentParentWorkspace = workspace
            } else {
              this.authService.currentParentWorkspace = null
            }
            this.router.navigate(['events', 'list'])
          });
        },
        () => (this.processing = false)
      )
  }

  /** generates a slug from a given label "String"
   * @param  {string} label the given string to generate slug from
   * @returns string
   */
  generateName(label: string): string {
    const matches = label.match(/\b(\w)/g)
    return matches.join('')
  }
  /**
   * Generates a random unique identifier
   * @returns unique identifier
   */
  uniqueId(): string {
    return Math.random().toString(36).substr(2, 7)
  }
}

/**
 * Validates the slug chosen by the user based on a regex
 * @param control Form control
 * @returns error or null
 */
function slugValidator(control: FormControl) {
  const slug = control.value
  const regex = /^[a-zA-Z][a-zA-Z0-9--_]*$/
  if (!slug || regex.test(slug)) return null
  return { control: 'Invalid Acronym' }
}
