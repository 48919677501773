import { Router, ActivatedRoute } from '@angular/router'
import { FormGroup } from '@angular/forms'
import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, OnDestroy } from '@angular/core'
import { format, parse } from 'date-fns'
import { Workspace } from '@workspaces/interfaces/workspace.interface'
import { WorkspaceService } from '@workspaces/services/workspace.service'
import { AuthService } from '@auth/services/auth.service'
import { NbDialogService } from '@nebular/theme'
import { AssignOrganizerComponent } from '@workspaces/components/assign-organizer/assign-organizer.component'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { CloudinaryUploadService } from '@app/services/cloudinary-upload.service'

/**
 * Component that represents a workspace
 */
@Component({
  selector: 'ngx-workspaces-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {
  /**
   * Subject that stops all hot observables
   */
  public _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }

  /**
   * No available actions mode
   */
  @Input()
  noActions = false

  /**
   * Workspace model
   */
  @Input()
  workspace: Workspace

  /**
   * Workspace change event emitter
   */
  @Output()
  workspaceChange: EventEmitter<Workspace> = new EventEmitter<Workspace>()

  /**
   * Workspace id
   */
  @Input()
  workspaceId: number

  /**
   * Display mode
   */
  @Input()
  mode: 'informative' | 'regular' = 'informative'

  /**
   * Show plan
   */
  @Input()
  showPlan = false

  /**
   * Emit workspace events
   */
  @Input()
  emitEvents = true

  /**
   * Represents a workspace
   */
  form: FormGroup

  /**
   * Performing async operation
   */
  processing = false

  /**
   * Organizer mode
   */
  organizer = true

  /**
   * Enterprise mode
   */
  enterprise = false

  /**
   * Editing?
   */
  editing = false

  /**
   * Display fragment to scroll to
   */
  fragment

  /**
   * Default workspace logos
   */
  defaultLogo = 'https://res.cloudinary.com/contactless/image/upload/v1598337942/public/default-event-logo.svg'

  /**
   * Had error fetching the current workspace logo?
   */
  logoHasError = false

  /**
   * Cloudinary upload widget reference
   */
  cloudinaryUploadWidget
  /**
   * Creates the component and injects it's dependencies
   * @param authService AuthService
   * @param dialogService NbDialogService
   * @param router Router
   * @param route ActivatedRoute
   * @param workspaceService WorkspaceService
   * @param cus CloudinaryUploadService
   */
  constructor(
    private authService: AuthService,
    private dialogService: NbDialogService,
    private router: Router,
    private route: ActivatedRoute,
    public workspaceService: WorkspaceService,
    private cus: CloudinaryUploadService
  ) { }
  /**
   * Intializes the component
   */
  ngOnInit(): void {
    this.route.fragment.pipe(takeUntil(this._destroy$)).subscribe((fragment) => {
      this.fragment = fragment
      const selector = '#' + this.fragment
      if (!!fragment) {
        document.querySelector(selector)?.scrollIntoView()
      }
    })
  }

  /**
   * Toggles editing mode
   */
  toggleEditing(event?) {
    this.authService.currentWorkspace = this.workspace    
    this.authService.currentParentWorkspace = this.workspace.parent ? this.workspace.parent_workspace : this.workspace
    if (event) {
      event.stopPropagation()
    }
    this.editing = !this.editing
  }

  /**
   * Updates current workspace
   */
  updateWorkspace(value) {
    this.workspace = value
    this.toggleEditing()
  }

  /**
   * Formats workspace creation date
   */
  createdAt() {
    return this.workspace.created_at ? format(parse(this.workspace.created_at, 'yyyy-MM-dd HH:mm:ss', Date.now()), 'dd-MMM-yyyy') : ''
  }

  /**
   * Formats workspace update date
   */
  updatedAt() {
    return this.workspace.last_use_diff_to_human ? format(parse(this.workspace.last_use_diff_to_human, 'yyyy-MM-dd HH:mm:ss', Date.now()), 'dd-MMM-yyyy') : ''
  }

  /**
   * Opens workspace members dialog
   */
  openAssigneeDialog() {
    this.dialogService.open(AssignOrganizerComponent, {
      context: {
        workspace: this.workspace
      }
    })
  }

  /**
   * Gets workspace role
   */
  get role() {
    return this.workspace?.parent && this.workspace.parent_workspace?.id ? 'Sub Entity' : 'Main Entity'
  }

  /**
   * Navigates to workspace events timeline
   */
  navigateEvents() {
    if (!this.emitEvents) {
      return
    }
    this.authService.currentWorkspace = this.workspace
    if (this.workspace.role_id === 1) {
      this.authService.currentParentWorkspace = this.workspace
    } else if (this.workspace.role_id === 2) {
      this.authService.currentParentWorkspace = this.workspace.parent_workspace
    } else {
      this.authService.currentParentWorkspace = null
    }
    this.router.navigate(['events', 'list'])
  }
  /**
   * Scrolls to certain fragment on the document
   * @param fragment string
   * @param event MouseEvent
   */
  scrollToParent(fragment, event) {
    event.stopPropagation()

    if (!!fragment.slug) {
      this.router.navigateByUrl(`/workspaces/list#${fragment.slug}`)
    }
  }

  /**
   * Is the current user an admin?
   */
  get isAdmin(): boolean {
    return this.authService.role === 'Admin'
  }

  /**
   * Opens a dialog based on plan and role
   * @param dialog TemplateRef
   * @param event MouseEvent
   */
  open(dialog: TemplateRef<any>, event) {
    event.stopPropagation()
    this.authService.currentWorkspace = this.workspace
    this.authService.currentParentWorkspace = this.workspace.parent_workspace ?? this.workspace
    if (this.authService.role === 'Admin' || this.authService.plan === 'Basic' || this.authService.plan === 'Pro' || this.authService.role === 'Sub Entity') {
      this.openAssigneeDialog()
    } else {
      this.dialogService.open(dialog)
    }
  }
  /**
   * Returns workspace logo, based on several factors and optimizes it using cloudinary
   */
  get logo() {
    return (this.logoHasError ? this.defaultLogo : !!this.workspace.logo ? this.workspace.logo : this.defaultLogo).replace(
      '/upload/',
      '/upload/b_rgb:ffffff,bo_1px_solid_rgb:ffffff,c_pad,f_png,h_77,r_3,w_77/'
    )
  }

  /**
   * Opens the cloudinary upload widget
   * @param event MouseEvent
   */
  uploadLogo(event) {
    event.stopPropagation()
    if (!this.cloudinaryUploadWidget) {
      this.cus.bootDetails(this)
    }
    if (!this.cloudinaryUploadWidget.isShowing()) {
      this.cloudinaryUploadWidget.open()
    }
  }
}
