import { Component, OnDestroy, OnInit } from '@angular/core'
import { AuthService } from '@auth/services/auth.service'
import { Router, ActivatedRoute } from '@angular/router'
import { ToastrService } from '@app/services/toastr.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

/**
 * Confirms a user email address based on the token in the email sent to his inbox
 */
@Component({
  selector: 'ngx-confirm-email-alert',
  templateUrl: './confirmation-email.component.html',
  styleUrls: ['./confirmation-email.component.scss']
})
export class ConfirmEmailAlertComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * Different status for the email confirmation process
   */
  state: 'invalidToken' | 'validating' | 'unauthorized' | 'loading' = 'loading'
  /**
   * Creates the component & injects it's dependencies
   * @param authService AuthService
   * @param router Router
   * @param route ActivatedRoute
   * @param toastr ToastrService
   */
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) {}

  /**
   * OnInit implementation, looks for the current token and tries to confirm it, if there's no token the state becomes unauthorized
   */
  ngOnInit(): void {
    if (this.route.snapshot.queryParams.token) {
      this.confirm()
    } else {
      this.state = 'unauthorized'
    }
  }

  /**
   * confirms an email addres by a token, then redirects the user to it's workspace list - or displays an error message and redirects to workspace list
   */
  confirm() {
    this.authService
      .validateEmailConfirmation(this.route.snapshot.queryParams.token)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          this.authService.user = {
            ...this.authService.user,
            confirmed: true
          }
          this.router.navigateByUrl('workspaces/list')
        },
        (error) => {
          this.toastr.error(error.message, '')
          this.state = 'invalidToken'
          setTimeout(() => {
            this.router.navigateByUrl('workspaces/list')
          }, 3000)
        }
      )
  }
  /**
   * Resends a confirmation email to the user with a new token - then redirects to workspace selection
   * @see WorkspaceSetComponent
   * @deprecated
   */
  resendConfirmation() {
    this.authService
      .confirmRegisteredEmail(this.authService.user.email)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          this.toastr.success('Email verification sent to your email address', '')
          this.router.navigateByUrl('workspaces/set')
        },
        (error) => {}
      )
  }
}
