import { Injectable } from '@angular/core'
import { NbToastrService, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme'

/**
 * Displays a message in a toastr, utilizes NbToastrService
 */
@Injectable({ providedIn: 'root' })
export class ToastrService {
  /**
   * Duration of the toastr
   */
  displayDuration = 5000

  /**
   * Availabl toastr types
   */
  types: NbComponentStatus[] = ['primary', 'success', 'info', 'warning', 'danger']

  /** Creates service & injects it's dependencies */
  constructor(private service: NbToastrService) {}

  /**
   * Displays a success toastr
   * @param title Toastr title
   * @param body Toastr body
   */
  public success(title: string, body: string) {
    const config = {
      status: this.types[1],
      destroyByClick: true,
      duration: this.displayDuration,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true
    }
    const titleContent = title ? `${title}` : ''

    this.service.show(body, titleContent, config)
  }

  /**
   * Displays an error toastr
   * @param title Toastr title
   * @param body Toastr body
   */
  public error(title: string, body: string) {
    const config = {
      status: this.types[4],
      destroyByClick: true,
      duration: this.displayDuration,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true
    }
    const titleContent = title ? `${title}` : ''

    this.service.show(body, titleContent, config)
  }

  /**
   * Displays an info toastr
   * @param title Toastr title
   * @param body Toastr body
   */
  public info(title: string, body: string) {
    const config = {
      status: this.types[2],
      destroyByClick: true,
      duration: this.displayDuration,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true
    }
    const titleContent = title ? `${title}` : ''

    this.service.show(body, titleContent, config)
  }

  /**
   * Displays a notification toastr
   * @param title Toastr title
   * @param body Toastr body
   */
  public notification(title: string, body: string) {
    const config = {
      status: this.types[0],
      destroyByClick: true,
      duration: this.displayDuration,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true
    }
    const titleContent = title ? `${title}` : ''
    this.service.show(body, titleContent, config)
  }

  /**
   * Displays a warning toastr
   * @param title Toastr title
   * @param body Toastr body
   */
  public warning(title: string, body: string, duration = 3000) {
    const config = {
      status: this.types[3],
      destroyByClick: true,
      duration: duration,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.BOTTOM_RIGHT,
      preventDuplicates: true
    }
    const titleContent = title ? `${title}` : ''
    this.service.show(body, titleContent, config)
  }
}
