import { Router } from '@angular/router'
import { AuthService, LegalInfo } from '@auth/services/auth.service'
import { Component, OnInit, TemplateRef, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core'
import { WorkspaceService } from '@workspaces/services/workspace.service'
import { Workspace } from '@workspaces/interfaces/workspace.interface'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { ToastrService } from '@app/services/toastr.service'
import { NbDialogService, NbDialogRef } from '@nebular/theme'
import { format, parse, isValid } from 'date-fns'
import { map, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { PaymentType } from '@workspaces/models/payment-type'
import { RepositoriesService } from '@app/services/repositories.service'
import { BillingService } from '@app/modules/payments/services/billing.service'
import { HubspotService } from '@app/services/hubspot.service'
import { UserRoles } from '@app/modules/auth/enums/user-roles.enum'
import { DialogData } from '@app/modules/shared/components/dialog/dialog.interface'
import { DialogOverviewExampleDialog } from '@app/modules/shared/components/dialog/dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { InvitationComponent } from '@app/modules/invite/components/invitation/invitation.component'
import { EventService } from '@app/modules/events/services/event.service'
import { ServerRepresentedEvent } from '@app/modules/events/models/event.model'

/**
 * Represents a component that handles workspace creation
 */
@Component({
  selector: 'ngx-workspaces-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /**
   * Dialog template ref
   */
  @ViewChild('dialog') dialog: TemplateRef<any>
  /**
   * Workspace form group
   */
  form: FormGroup
  /**
   * Performing async operation?
   */
  processing = false
  /**
   * Edit mode?
   */
  @Input()
  edit = false
  /**
   * Workspace model
   */
  @Input()
  workspace: Workspace = null
  /**
   * Workspace updated event emitter
   */
  @Output()
  updated: EventEmitter<Workspace> = new EventEmitter<Workspace>()
  /**
   * Dialog dismissed
   */
  @Output()
  dismiss: EventEmitter<any> = new EventEmitter<any>()
  /**
   * Created workspace details
   */
  createdWorkspace: Workspace = null
  /**
   * Dialog data, email/ slug
   */
  dialogData = {
    email: '',
    slug: ''
  }
  /**
   * Available supported payment types
   */
  availableGateways: Partial<PaymentType>[] = []
  /**
   * The current set payment gateway
   */
  gateway: string
  originalGateway: string;
  /**
   * Paid events
   */
  paidEvents: Partial<ServerRepresentedEvent>[];
  /**
   * List of available entity types selection
   */
  entityTypes: { value: number; name: string }[] = [
    { name: 'Individual /sole proprietorship.', value: 1 },
    { name: 'Company', value: 2 },
    { name: 'Non profit type', value: 3 },
    { name: 'Government entity type', value: 4 }
  ]
  /**
   * List of stripe countries
   */
  countries: any[]
  /**
   * Is it a stripe country
   */
  isCountryValid = true
  /**
   * Is the country among the list
   */
  existCountryCode: string
  /**
   * Organization details form group
   */
  organizationForm: FormGroup
  /**
   * FormControl for autocomplete
   */
  myControl = new FormControl()
  /**
   * Organization legal info model
   */
  legalInfo: LegalInfo = null
  /**
   * Whether the info are submittable
   */
  hasValidLegalInfo = false
  /**
   * Is the component ready
   */
  ready: boolean
  /**
   * Creates the component and injects it's dependencies
   * @param workspacesService WorkspaceService
   * @param fb FormBuilder
   * @param toastrService ToastrService
   * @param dialogService NbDialogService
   * @param auth AuthService
   * @param router RouterService
   * @param repositoriesService RepositoriesService
   * @param paymentService BillingService
   * @param hubspot HubspotService
   */
  constructor(
    private workspacesService: WorkspaceService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private dialogService: NbDialogService,
    public auth: AuthService,
    private router: Router,
    private repositoriesService: RepositoriesService,
    private paymentService: BillingService,
    private hubspot: HubspotService,
    private matDialog: MatDialog,
    private eventService: EventService
  ) { }

  /**
   * Initializes the component
   */
  ngOnInit() {
    this.ready = false
    let workspace = this.isMainEntity() ? this.workspace : this.workspace?.parent_workspace
    if (this.workspace && !this.auth.currentWorkspace) {
      this.auth.currentWorkspace = this.workspace
      if (this.workspace.role_id === 1) {
        this.auth.currentParentWorkspace = this.workspace
      } else {
        this.auth.currentParentWorkspace = null
      }
    }
    this.form = this.fb.group({
      id: !this.workspace ? [null] : [this.workspace.id, Validators.required],
      name: [!this.workspace ? '' : this.workspace.name, Validators.required],
      slug: [!this.workspace ? '' : this.workspace.slug, Validators.required],
      role_id: [this.decideOnRoleId()],
      parent:
        this.auth.role === 'Admin'
          ? null
          : !!this.auth.currentWorkspace && this.auth.currentWorkspace.id != this.workspace?.id
            ? [this.auth.currentWorkspace.id, Validators.required]
            : [!this.workspace ? null : this.workspace.parent],
      max_events_num: [null],
      email: ['', this.workspace ? [] : [Validators.required, Validators.email]],
      financialDashboardAccessKey: [true],
      marketingDashboardAccessKey: [true],
      pro: [!this.workspace ? false : this.workspace.plan_id === 2 ? true : false],
      plan_expiry_date: [workspace && workspace.plan_expiry_date ? parse(workspace.plan_expiry_date.replace('T', ' ').split('.')[0], 'yyyy-MM-dd HH:mm:ss', Date.now()) : ''],
      contact_email: [this.workspace?.contact_email ?? ''],
      contact_number: [this.workspace?.contact_number ?? ''],
      options: [!this.workspace ? {} : { ...this.workspace.options }]
    })
    this.gateway = this.workspace?.options?.payments?.gateway
    if (!this.gateway) this.gateway = ''
    this.originalGateway = this.workspace?.options?.payments?.gateway

    this.getAvalaibalePaymentGetways()

    this.countries = this.repositoriesService.CountriesTimezonesRepository
    try {
      this.auth.getOrganizationInfo(workspace).subscribe((res: LegalInfo) => {
        this.legalInfo = res
        this.validateLegalInfo()
        this.organizationForm = this.fb.group({
          workspace_id: [res.workspace_id ?? workspace.id],
          country: [res.country ?? '', [Validators.required]],
          company_type: [res.company_type ?? null],
          company_name: [res.company_name ?? ''],
          company_email: [res.company_email ?? '', [Validators.email]],
          company_phone: [res.company_phone ?? ''],
          company_address: [res.company_address ?? '']
        })
        this.isCountryValid = !!this.repositoriesService.validStripeCountries.find((o) => o.code === res.country)
        this.ready = true
      })
    } catch {
      this.organizationForm = this.fb.group({
        workspace_id: [null],
        country: ['', [Validators.required]],
        company_type: [null],
        company_name: [''],
        company_email: ['', [Validators.email]],
        company_phone: [''],
        company_address: ['']
      })
      this.ready = true
    }
  }

  getAvalaibalePaymentGetways() {
    this.paymentService.getAvalaibalePaymentGetways().subscribe((o: PaymentType[]) => {
      const gateways: PaymentType[] = o.sort((a: PaymentType, b: PaymentType) => (a.label > b.label ? 1 : -1))
      this.availableGateways = gateways
      this.availableGateways.unshift({ name: '', label: 'None' })
      if (this.hasStripeConnect) {
        const index = this.availableGateways.findIndex((a: PaymentType) => a.name === 'stripe')
        this.availableGateways.splice(index + 1, 0, {
          name: 'stripe-connect',
          label: `Stripe Connect - ${this.isMainEntity() ? this.workspace.account_id : this.workspace.parent_workspace.account_id}`
        })
      }
    })
  }

  /** Return roleId based on role type and current workspace */
  decideOnRoleId(): number {
    if (this.auth.role === 'Admin') return 1
    return this.auth.currentWorkspace ? 2 : !this.workspace ? 1 : this.workspace.role_id
  }

  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }

  /**
   * If the legal infor has a country selected
   */
  get hasCountry() {
    return !!this.legalInfo?.country
  }

  /**
   * Creates the workspace
   */
  create() {
    if (this.form.valid) {
      this.processing = true
      const value = this.form.value
      value.plan_id = value.pro ? 2 : 1
      if (value.plan_id !== 2) {
        delete value.plan_expiry_date
      } else {
        value.plan_expiry_date = isValid(value.plan_expiry_date) ? format(value.plan_expiry_date, 'yyyy-MM-dd') : null
      }
      this.dialogData.slug = value.slug
      this.dialogData.email = value.email

      if (this.edit) {
        delete value.slug
        value.enable_payment = (!!this.workspace.account_id && !this.gateway) || (!!this.gateway && this.gateway[0] != '!')
        if (this.gateway) {
          value.options.payments ? (value.options.payments['gateway'] = this.gateway) : (value.options['payments'] = { gateway: this.gateway })
        } else delete value.options.payments
        this.workspacesService
          .updateWorkspace(value)
          .pipe(takeUntil(this._destroy$))
          .subscribe(
            (workspace: Workspace) => {
              const gatewayName = this.availableGateways.find((o) => o.name === this.gateway)?.label
              this.gateway[0] == '!'
                ? this.toastrService.warning(`Warning!`, `${gatewayName} is not configured yet.`)
                : this.toastrService.success('Congratulations!', 'Your workspace has been updated')
              this.updated.emit(workspace)
              this.saveLegalInfo()
              this.processing = false
            },
            () => {
              this.processing = false
            }
          )
      } else {
        this.workspacesService
          .createWorkspace(value)
          .pipe(takeUntil(this._destroy$))
          .subscribe(
            (workspace: Workspace) => {
              // Workspace is created-  Assign our manager
              this.toastrService.success('Congratulations!', 'Your workspace has been created')
              this.dismiss.emit()
              this.createdWorkspace = workspace
              this.organizationForm.controls['workspace_id'].patchValue(workspace.parent)
              this.saveLegalInfo()
              this.form.reset()
              this.toastrService.success('Congratulations!', 'Workspace manager was assigned his new position.')
              this.open(this.dialog)
              this.processing = false
            },
            () => (this.processing = false)
          )
      }
    }
  }

  /**
   * Opens creation status dialog
   * @param dialog TemplateRef
   */
  open(dialog: TemplateRef<any>) {
    const dialogRef: NbDialogRef<any> = this.dialogService.open(dialog)
    dialogRef.onClose.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.auth.currentWorkspace = this.createdWorkspace
      if (this.edit) {
        return
      }
      this.router.navigate(['events', 'list'])
    })
  }

  /**
   * Closes the component by emitting an event
   */
  close(event) {
    this.dismiss.emit()
  }

  /**
   * Available number of workspaces for the current user
   * @deprecated
   */
  get availableWorkspacesCount(): number {
    return this.auth.currentWorkspace.plan.max_workspaces - this.auth.currentWorkspace.organizers_count - 1
  }

  /**
   * Navigates to archived events list
   */
  goToArchiveList() {
    this.auth.currentWorkspace = this.workspace
    this.router.navigate(['events', 'archive', true])
  }

  /**
   * Validates wrokspace legal information
   */
  validateLegalInfo() {
    this.hasValidLegalInfo = !!this.legalInfo && !!this.legalInfo.company_email && !!this.legalInfo.company_name && !!this.legalInfo.company_type && !!this.legalInfo.country
  }

  /**
   * Redirects the user to stripe to complete his stripe connect
   */
  redirectToStripe(event) {
    event.preventDefault()
    this.paymentService
      .getStripeLink()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res: any) => {
        window.open(res.url, '_blank')
      })
  }

  /**
   * Sends an email to MICEtribe for a user that's not part of the countries supported by stripe connect
   */
  requestActivation() {
    const emailBody =
      'Hi MICEtribe team ' +
      escape('\n') +
      'I would like to activate payment collection for my workspace ' +
      (this.auth.currentParentWorkspace?.name || this.auth.currentWorkspace.name)
    const url = `mailto:help@contactless.io?subject=Request Activation&body=${emailBody}`
    window.location.href = url
  }

  requestOtherPaymentProvider() {
    this.validateLegalInfo()
    if (this.hasValidLegalInfo) {
      this.hubspot.openTicket(this.auth.currentWorkspace.id).subscribe(
        () => {
          this.toastrService.success(
            'A new payment methode was requested',
            'Your request has been received, please allow us up to 72 hours to review your request and enable payment collection for you.'
          )
        },
        (err) => {
          this.toastrService.error('Error', err.message)
        }
      )
    } else {
      this.toastrService.error('Error', 'Please fill and save all the organization information fields before proceeding.')
    }
  }

  /**
   * If the workspace is connected to a stripe account
   */
  get hasStripeConnect() {
    const workspace = this.isMainEntity() ? this.workspace : this.workspace.parent_workspace
    return !!workspace?.account_id
  }

  /**
   * Updates the workspace legal info
   */
  saveLegalInfo() {
    this.ready = false

    const formValue: LegalInfo = Object.assign({}, this.organizationForm.value)
    Object.entries(formValue).forEach((o) => {
      if (this.organizationForm.controls[o[0]].pristine && o[0] != 'workspace_id') {
        delete formValue[o[0]]
      } else formValue[o[0]] = typeof o[1] === 'number' ? o[1] : o[1]?.trim() ? o[1] : null
    })

    this.auth.updateOrganization(formValue).subscribe(
      () => {
        this.toastrService.success('Success', 'Updated organization information')
        this.ready = true
      },
      () => {
        this.toastrService.error('Failed to update organization information', '')
        this.ready = true
      }
    )
  }

  isMainEntity() {
    return !this.workspace?.parent_workspace
  }

  deleteWorkSpace() {
    const dialogData: DialogData = {
      headerText: `Are you sure you want to delete ${this.workspace.name} workspace?`,
      messageText: "Please note that by deleting this workspace all of it's events and persona will be lost",
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete',
      confirmButtonClass: 'delete'
    }
    const dialogRef = this.matDialog.open(DialogOverviewExampleDialog, {
      minWidth: '544px',
      panelClass: 'matdialiog',
      data: dialogData
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.workspacesService.deleteWorkspace(this.workspace.id).subscribe((res) => {
          this.toastrService.info('Workspace deleted', '')
          this.workspacesService.spliceWorkspace$.next(this.workspace.id)
        })
      }
    })
  }


  /**
 * Formats a date object to a representable string
 * @param date Date
 * @returns string
 */
  formatDate(date) {
    return date ? format(parse(date, 'yyyy-MM-dd HH:mm:ss', Date.now()), 'dd MMM yyyy') : ''
  }
  changePaymentGateway(event) {
    this.eventService.getWorkspacePaidEvents(this.auth.currentWorkspace.id.toString()).pipe(
      map((events) => {
        return events
      }),
      map((events) =>
        events.map((event) => {
          return {
            name: event.name,
            slug: '',
            logo: event.logo,
            cover_page: event.cover_page,
            status: '',
            social_media: [],
            id: event.id,
            checkins: [],
            forms: [],
            public_start_date: this.formatDate(event.public_start_date),
            public_end_date: this.formatDate(event.public_end_date)
          }
        })
      )
    )
      .subscribe({
        next: (result: any) => {
          this.paidEvents = result
          if (!this.paidEvents.length) return
          this.gateway = this.originalGateway
          this.dialogService.open(InvitationComponent, {
            autoFocus: false,
            context: {
              invitationInfo: {
                eventSlug: '',
                formSlug: '',
                message: '',
                fromEventSlug: '',
                fromFormSlug: '',
                action: '',
                ids: [],
                emails: [],
                selectedTemplate: 1,
                isInvitationModal: false
              },
              paidEvents: this.paidEvents
            }
          })

        },
      })
  }
}
