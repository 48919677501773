import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { BillingPageComponent } from './components/billing-page/billing-page.component'

const routes: Routes = [
  {
    path: '',
    component: BillingPageComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentsRoutingModule {}
