import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ServerRepresentedEvent } from '@events/models/event.model'

/**
 * Event invitation event selection list
 */
@Component({
  selector: 'ngx-invite-to-event',
  templateUrl: './invite-to-event.component.html',
  styleUrls: ['./invite-to-event.component.scss']
})
export class InviteToEventComponent {
  /**
   * Events list
   */
  @Input()
  events: Partial<ServerRepresentedEvent>[] = []
  /**
   * Emits the selected event
   */
  @Output()
  public eventSelected = new EventEmitter<Partial<ServerRepresentedEvent>>()
}
