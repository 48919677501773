import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '@auth/services/auth.service'

/**
 * Allows/denies access to unauthenticated routes
 */
@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  /**
   * Allows user access or denies it and redirects the user to it's current page
   * @param next next route
   * @param state current route state
   * @returns boolean
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url.includes('confirm-email')) {
      return true
    }
    const user = this.authService.user
    if (!!user) {
      this.router.navigate([''])
    }
    return true
  }
  /**
   * Creates the guard and injects it's dependencies
   * @param router Router
   * @param authService AuthService
   */
  constructor(private router: Router, private authService: AuthService) {}
}
