<div class="container-fluid d-flex justify-content-center align-items-start application-page">
  <div class="login-content">
    <mat-card [nbSpinner]="processing" [ngClass]="{ extended: !(!!registered || registered === null) }">
      <mat-card-content class="d-flex flex-column">
        <img class="header-logo" role="img" src="https://library.micetribe.com/images/micetribe-textless-logo.svg" />
        <div class="header-text">{{ dualMode ? 'SIGN IN / UP' : !!registered ? 'SIGN IN' : 'SIGN UP' }}</div>
        <form id="loginSignup" [formGroup]="form" (ngSubmit)="submit()" class="d-flex flex-column justify-content-center flex-grow-1">
          <div class="elements">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email" autofocus />
            </mat-form-field>
            <div class="error-box" *ngIf="form.get('email').hasError('email') || (form.controls['email'].touched && form.controls['email'].errors)">
              <div class="icon"></div>
              <div class="msg">
                <mat-error *ngIf="form.get('email').hasError('email')">
                  {{ form.get('email').getError('email') }}
                </mat-error>
                <mat-error *ngIf="form.get('email').hasError('required')"> Fill in your email address to sign in/ sign up </mat-error>
              </div>
            </div>
          </div>

          <div class="elements">
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput type="password" formControlName="password" />
            </mat-form-field>
            <div class="error-box" *ngIf="form.get('password').hasError('password') || (form.controls['password'].touched && form.controls['password'].errors)">
              <div class="icon"></div>
              <div class="msg">
                <mat-error *ngIf="form.get('password').invalid && !form.get('password').hasError('required')"> Password must be from 8 to 20 characters </mat-error>
                <mat-error *ngIf="form.get('password').hasError('required')"> Fill in your password </mat-error>
              </div>
            </div>
          </div>

          <div class="elements" *ngIf="!dualMode && !registered" [@fadeInOnEnter] [@fadeOutOnLeave]>
            <mat-form-field appearance="outline">
              <mat-label>Confirm your password</mat-label>
              <input matInput type="password" formControlName="password_confirmation" autocomplete="new-password" />
            </mat-form-field>
            <div
              class="error-box"
              *ngIf="
                (form.controls['password_confirmation'].touched && !form.controls['password_confirmation'].dirty) ||
                form.get('password_confirmation').hasError('minLength') ||
                (!registered && form.controls['password_confirmation'].dirty && form.controls['password'].value !== form.controls['password_confirmation'].value)
              "
            >
              <div class="icon"></div>
              <div class="msg">
                <mat-error *ngIf="form.get('password_confirmation').hasError('required')"> Fill in your password </mat-error>
                <mat-error *ngIf="form.get('password_confirmation').invalid && !form.get('password_confirmation').hasError('required')">
                  Password must be from 8 to 20 characters
                </mat-error>
                <mat-error
                  *ngIf="
                    !registered &&
                    form.controls['password_confirmation'].dirty &&
                    form.controls['password'].value !== form.controls['password_confirmation'].value &&
                    !form.get('password_confirmation').invalid &&
                    !form.get('password_confirmation').hasError('required')
                  "
                >
                  Password and its confirmation don't match
                </mat-error>
              </div>
            </div>
          </div>

          <button *ngIf="!isLoading" class="login-btn" matButton mat-raised-button color="primary">
            {{ !form.get('email').valid ? 'PROCEED' : registered ? 'Sign in' : 'Sign up' }}
          </button>

          <button *ngIf="isLoading" class="login-btn" matButton mat-raised-button color="primary" disabled>
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
          <div *ngIf="!registered" class="text-center agree-msg">
            By clicking sign up I agree to MICEtribe <br /><a href="https://micetribe.com/terms-conditions" target="_blank" class="open-terms">terms and conditions</a>
          </div>
          <a (click)="navigateToRestPass()" class="mr-0 pt-2 forgot-text" *ngIf="registered">Forgot your password?</a>
        </form>
        <div class="continue-with">
          <div class="divider">
            <div class="right"></div>
            <div class="div-text">OR</div>
            <div class="left"></div>
          </div>
          <button class="google" (click)="loginWithGoogle()" type="button" role="button">
            <span class="icon">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.64 9.20468C17.64 8.5665 17.5827 7.95286 17.4764 7.36377H9V10.8451H13.8436C13.635 11.9701 13.0009 12.9233 12.0477 13.5615V15.8197H14.9564C16.6582 14.2529 17.64 11.9456 17.64 9.20468Z"
                  fill="#4285F4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.99976 18C11.4298 18 13.467 17.1941 14.9561 15.8195L12.0475 13.5613C11.2416 14.1013 10.2107 14.4204 8.99976 14.4204C6.65567 14.4204 4.67158 12.8372 3.96385 10.71H0.957031V13.0418C2.43794 15.9831 5.48158 18 8.99976 18Z"
                  fill="#34A853"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.96409 10.7098C3.78409 10.1698 3.68182 9.59301 3.68182 8.99983C3.68182 8.40664 3.78409 7.82983 3.96409 7.28983V4.95801H0.957273C0.347727 6.17301 0 7.54755 0 8.99983C0 10.4521 0.347727 11.8266 0.957273 13.0416L3.96409 10.7098Z"
                  fill="#FBBC05"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z"
                  fill="#EA4335"
                />
              </svg>
            </span>
            <span class="text">Continue with Google</span>
          </button>
          <!-- <button class="facebook" (click)="loginWithFacebook()" type="button" role="button">
                        <span class="icon">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M17.0059 0H0.993479C0.445169 0 0 0.44428 0 0.993479V17.0059C0 17.5551 0.445169 18 0.993479 18H9.61411V11.0293H7.26852V8.31239H9.61411V6.30883C9.61411 3.98429 11.0335 2.71784 13.1076 2.71784C14.102 2.71784 14.9547 2.79223 15.2033 2.82454V5.2543L13.7647 5.25489C12.6369 5.25489 12.4194 5.79105 12.4194 6.57736V8.3115H15.11L14.7581 11.0282H12.4191V17.9991H17.0056C17.5545 17.9991 18 17.5536 18 17.0059V0.992887C17.9997 0.44428 17.5548 0 17.0059 0Z"
                                    fill="white" />
                            </svg>
                        </span>
                        <span class="text"> Continue with Facebook </span>
                    </button> -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

