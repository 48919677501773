import { Component, Input, Output, EventEmitter } from '@angular/core'
import { WorkspaceService } from '@workspaces/services/workspace.service'
import { Workspace } from '@workspaces/interfaces/workspace.interface'
import { Observable, of } from 'rxjs'

/**
 * Select a workspace as the current workspace
 */
@Component({
  selector: 'ngx-workspaces-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  /**
   * Workspace label
   */
  @Input()
  label = ''

  /**
   * Workspace label alignments
   */
  @Input()
  labelAlignment: 'left' | 'right' | 'center' = 'center'

  /**
   * Emits whether a workspace is selected
   */
  @Output()
  selectionChange: EventEmitter<Workspace> = new EventEmitter<Workspace>()

  /**
   * Emits to load more workspaces
   */
  @Output()
  loadMore: EventEmitter<any> = new EventEmitter<any>()
  /**
   * show noMoreWorkspaces text
   */
  @Input() noMoreWorkspaces: boolean = false
  /**
   * List of all workspaces available to be selected
   */
  @Input()
  items: Workspace[] = null

  /**
   * Workspace display mode
   */
  @Input()
  mode: 'select' | 'cards' = 'select'

  /**
   * Observable of available workspaces
   */
  _workspaces$: Observable<Workspace[]>

  /**
   * Gets an observable of available workspaces
   */
  get workspaces$() {
    return !!this.items ? of(this.items) : this.workspaces.getWorkspaces()
  }

  /**
   * Creates the component & injects it's dependencies
   * @param workspaces WorkspaceService
   */
  constructor(private workspaces: WorkspaceService) {}

  /**
   * Emits a workspace selection change
   * @param workspace Workspace
   * @returns void
   */
  select = (workspace: Workspace) => this.selectionChange.emit(workspace)
  /**
   * Loads next page of workspaces
   * @returns void
   */
  loadNext() {
    this.loadMore.emit()
  }
}
