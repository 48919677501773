import { Injectable } from '@angular/core'
/**
 * Handles shared operations between multiple components
 */
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  /**
   * Current zone object in venue capacity dashboard
   */
  currentZone
  /**
   * List of available venue capacity dashboard zones
   */
  zones = []
  /**
   * Current venue capacity dashboard event id
   */
  eventId
  /**
   * Current activated route value
   */
  routeValue
  /**
   * Global flags to show/hide help center and tutorial navigation
   */
  globalFlages = {
    showHelpCenterNav: false,
    showTutorialNav: false
  }

  /**
   * Sets the global flags to show/hide help center and tutorial navigation
   * @param showHelpCenterNav boolean
   * @param showTutorialNav boolean
   */
  setGlobalFalges(showHelpCenterNav, showTutorialNav) {
    this.globalFlages.showHelpCenterNav = showHelpCenterNav
    this.globalFlages.showTutorialNav = showTutorialNav
  }
}
