<div class="subscription container-fluid" *ngIf="customer">
  <div class="title">Subscription</div>
  <div class="plan">
    <div class="top-tag">
      <span>Current plan</span>
    </div>
    <div class="content row" *ngIf="!basicPlan">
      <div class="icon">
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M69.974 23.2098C69.9673 22.9698 69.8973 22.7431 69.834 22.5131C69.784 22.3198 69.7607 22.1231 69.6773 21.9464C69.6007 21.7731 69.4707 21.6331 69.3573 21.4731C69.2173 21.2698 69.0907 21.0664 68.9107 20.8998C68.8807 20.8698 68.8673 20.8298 68.834 20.8031C68.714 20.6998 68.5673 20.6631 68.4373 20.5798C68.2373 20.4498 68.0407 20.3164 67.814 20.2298C67.5907 20.1498 67.3707 20.1298 67.1407 20.0964C66.9807 20.0731 66.8373 19.9998 66.6673 19.9998H50.0007C48.1573 19.9998 46.6673 21.4931 46.6673 23.3331C46.6673 25.1731 48.1573 26.6664 50.0007 26.6664H59.4207L45.9673 42.3598L31.714 33.8098C30.304 32.9564 28.4907 33.2698 27.4373 34.5331L10.7707 54.5331C9.59401 55.9464 9.78401 58.0498 11.1973 59.2264C11.824 59.7464 12.5773 59.9998 13.3307 59.9998C14.2873 59.9998 15.234 59.5931 15.894 58.7998L30.734 40.9931L44.9507 49.5264C46.3473 50.3631 48.1407 50.0698 49.1973 48.8364L63.334 32.3431V39.9998C63.334 41.8398 64.824 43.3331 66.6673 43.3331C68.5107 43.3331 70.0007 41.8398 70.0007 39.9998V23.3331C70.0007 23.2898 69.9773 23.2531 69.974 23.2098Z"
            fill="#222B45"
          />
          <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="19" width="62" height="41">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M69.974 23.2098C69.9673 22.9698 69.8973 22.7431 69.834 22.5131C69.784 22.3198 69.7607 22.1231 69.6773 21.9464C69.6007 21.7731 69.4707 21.6331 69.3573 21.4731C69.2173 21.2698 69.0907 21.0664 68.9107 20.8998C68.8807 20.8698 68.8673 20.8298 68.834 20.8031C68.714 20.6998 68.5673 20.6631 68.4373 20.5798C68.2373 20.4498 68.0407 20.3164 67.814 20.2298C67.5907 20.1498 67.3707 20.1298 67.1407 20.0964C66.9807 20.0731 66.8373 19.9998 66.6673 19.9998H50.0007C48.1573 19.9998 46.6673 21.4931 46.6673 23.3331C46.6673 25.1731 48.1573 26.6664 50.0007 26.6664H59.4207L45.9673 42.3598L31.714 33.8098C30.304 32.9564 28.4907 33.2698 27.4373 34.5331L10.7707 54.5331C9.59401 55.9464 9.78401 58.0498 11.1973 59.2264C11.824 59.7464 12.5773 59.9998 13.3307 59.9998C14.2873 59.9998 15.234 59.5931 15.894 58.7998L30.734 40.9931L44.9507 49.5264C46.3473 50.3631 48.1407 50.0698 49.1973 48.8364L63.334 32.3431V39.9998C63.334 41.8398 64.824 43.3331 66.6673 43.3331C68.5107 43.3331 70.0007 41.8398 70.0007 39.9998V23.3331C70.0007 23.2898 69.9773 23.2531 69.974 23.2098Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)"></g>
        </svg>
      </div>
      <div class="text">Pro Plan</div>
      <div class="balance-count row">
        <div class="numbers">${{ planDetails?.plan.amount || 0 }}</div>
        <div class="time">
          <span>{{
            planDetails ? 'Every ' + (planDetails.plan.interval_count > 1 ? planDetails.plan.interval_count + ' ' : '') + planDetails.plan.interval : 'Manually upgraded'
          }}</span>
        </div>
      </div>
      <div class="expiration-date" *ngIf="planExpiryDate">
        <span class="expire-title">Expiration Date</span>
        <span class="dates">{{ planExpiryDate }}</span>
      </div>
      <button *ngIf="canEditSubscription()" (click)="manageSubscription()">Manage Subscription</button>
    </div>
    <div class="content row" *ngIf="basicPlan">
      <div class="icon">
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M47.7637 66.6684C47.5403 66.6684 47.3103 66.6584 47.0803 66.6351C44.147 66.3451 41.847 64.2751 41.2137 61.3584L32.2703 20.0517L23.0603 41.3251C22.5303 42.5451 21.327 43.3351 20.0003 43.3351H10.0003C8.15699 43.3351 6.66699 41.8417 6.66699 40.0017C6.66699 38.1584 8.15699 36.6684 10.0003 36.6684H17.8137L26.167 17.3717C27.3503 14.6517 29.957 13.0684 32.9203 13.3651C35.8537 13.6551 38.1537 15.7251 38.787 18.6417L47.7303 59.9484L56.9403 38.6751C57.4703 37.4551 58.6703 36.6684 60.0003 36.6684H70.0003C71.8437 36.6684 73.3337 38.1584 73.3337 40.0017C73.3337 41.8417 71.8437 43.3351 70.0003 43.3351H62.187L53.8337 62.6284C52.7437 65.1384 50.407 66.6684 47.7637 66.6684Z"
            fill="#2E3A59"
          />
          <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="13" width="68" height="54">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M47.7637 66.6684C47.5403 66.6684 47.3103 66.6584 47.0803 66.6351C44.147 66.3451 41.847 64.2751 41.2137 61.3584L32.2703 20.0517L23.0603 41.3251C22.5303 42.5451 21.327 43.3351 20.0003 43.3351H10.0003C8.15699 43.3351 6.66699 41.8417 6.66699 40.0017C6.66699 38.1584 8.15699 36.6684 10.0003 36.6684H17.8137L26.167 17.3717C27.3503 14.6517 29.957 13.0684 32.9203 13.3651C35.8537 13.6551 38.1537 15.7251 38.787 18.6417L47.7303 59.9484L56.9403 38.6751C57.4703 37.4551 58.6703 36.6684 60.0003 36.6684H70.0003C71.8437 36.6684 73.3337 38.1584 73.3337 40.0017C73.3337 41.8417 71.8437 43.3351 70.0003 43.3351H62.187L53.8337 62.6284C52.7437 65.1384 50.407 66.6684 47.7637 66.6684Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)"></g>
        </svg>
      </div>
      <div class="text basic-plan-text">Basic Plan</div>
      <div class="free-account">Free</div>
      <button *ngIf="canEditSubscription()" (click)="upgradeToPro()">Upgrade to Pro</button>
    </div>
  </div>
</div>
