import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '@environments/environment'
import { AuthService } from '@auth/services/auth.service'
import { map } from 'rxjs/operators'
import { Subject } from 'rxjs'
/**
 * Handles billing details for a customer (parent workspace)
 */
@Injectable({
  providedIn: 'root'
})
export class BillingService {
  /**
   * Subject that determines whether the billing details should be reloaded
   */
  public reloadBillingDetails$: Subject<boolean> = new Subject<boolean>()
  /**
   * Creates the service & injects it's dependencies
   * @param http HttpClient
   * @param auth AuthService
   */
  constructor(private http: HttpClient, private auth: AuthService) {}
  /**
   * Gets the current workspace id or parent id
   */
  get workspaceId() {
    return !!this.auth.currentWorkspace.parent ? this.auth.currentWorkspace.parent_workspace.id : this.auth.currentWorkspace.id
  }
  /**
   * Get the current workspace object
   */
  public get workspace() {
    if (!this.auth.currentWorkspace) {
      this.auth.currentWorkspace = this.auth.currentParentWorkspace
    }
    return !!this.auth.currentWorkspace?.parent ? this.auth.currentWorkspace?.parent_workspace : this.auth?.currentWorkspace
  }
  /**
   * Gets the customer billing details
   * @returns Observable
   */
  public customer() {
    return this.http.get(`${environment.planningApi}customers?workspace_id=${this.workspaceId}`)
  }
  /**
   * Gets the available payment methods for a workspace
   * @returns Observable
   */
  public paymentMethods() {
    return this.http.get(`${environment.planningApi}paymentMethods?workspace_id=${this.workspaceId}`)
  }

  /**
   * Topup the wallet with specified payment method and amount
   * @param amount number
   * @param paymentMethodId string
   * @returns Observable
   */
  public topup(amount: number, paymentMethodId: string) {
    //   return this.http.post(`${environment.planningApi}sessions/paymentIntent/confirmed`, {
    return this.http.post(`${environment.planningApi}sessions/topUp`, {
      workspace_id: this.workspaceId,
      amount: amount,
      payment_method_id: paymentMethodId
    })
  }
  /**
   * Gets a list of customer's transactions history
   * @returns Observable
   */
  public transactions(lastTransactionId: string = undefined) {
    const uri = lastTransactionId
      ? `${environment.planningApi}customers/transactions?workspace_id=${this.workspaceId}&last_transaction_id=${lastTransactionId}`
      : `${environment.planningApi}customers/transactions?workspace_id=${this.workspaceId}`
    return this.http.get(uri).pipe(
      map((result: any) => {
        result.data = result.data.map((item) => {
          return {
            debit: Math.abs(item.amount < 0 ? item.amount : 0),
            credit: Math.abs(item.amount > 0 ? item.amount : 0),
            description: item.description,
            date: item.created,
            url: item.receipt_url,
            balance: item.ending_balance
          }
        })
        return result
      })
    )
  }
  /**
   * Deletes a payment method from a customer
   * @param paymentMethodId string
   * @returns Observable
   */
  public deletePaymentMethod(paymentMethodId) {
    return this.http.delete(`${environment.planningApi}paymentMethods?workspace_id=${this.workspaceId}&payment_method_id=${paymentMethodId}`)
  }
  /**
   * Creates a payment intent for the specified amount for a customer
   * @param amount number
   * @returns Observable
   */
  public createPaymentIntent(amount) {
    return this.http.post(`${environment.planningApi}sessions/paymentIntent`, {
      amount: amount,
      workspace_id: this.workspaceId
    })
  }
  /**
   * Creates a setup intent for a customer
   * @returns Observable
   */
  public createSetupIntent() {
    return this.http.post(`${environment.planningApi}sessions/setupIntent`, {
      workspace_id: this.workspaceId
    })
  }
  /**
   * Sets the default payment method for a customer
   * @param paymentMethodId string
   * @returns Observable
   */
  public setDefaultPaymentMethod(paymentMethodId) {
    return this.http.patch(`${environment.planningApi}paymentMethods/setDefault`, {
      payment_method_id: paymentMethodId,
      workspace_id: this.workspaceId
    })
  }
  /**
   * Gets a list of the available currencies in the system
   * @returns Observable
   */
  public getCurrencyList(workspaceId: number) {
    return this.http.get(`${environment.planningApi}currencies/available?workspace_id=${workspaceId}`)
  }
  /**
   * Gets a link for stripe connect to redirect the customer to
   * @returns Observable
   */
  public getStripeLink() {
    return this.http.post(`${environment.planningApi}sessions/connect`, {
      workspace_id: this.auth.currentWorkspace.id,
      refresh_url: `${environment.baseUrl}app-settings/organization`,
      return_url: `${environment.baseUrl}app-settings/organization`
    })
  }

  /**
   * Get available Payment Getways
   * @returns Observable
   */
  getAvalaibalePaymentGetways() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Token: this.auth.user.token,
      AppId: '1'
    })
    return this.http.get(`${environment.planningApi}gateways`, { headers })
  }
}
