import { Component, OnInit } from '@angular/core'
@Component({
  selector: 'notfound-page',
  styleUrls: ['./not-found.component.scss'],
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  chatSupport() {
    const hubSpotIframe: HTMLIFrameElement = document.querySelector('#hubspot-messages-iframe-container > iframe')
    ;(hubSpotIframe.contentWindow.document.querySelector('body > div.widget > div:nth-child(1) > div > span > div > button') as HTMLElement).click()
  }
}
