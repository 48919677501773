import { Injectable, OnDestroy } from '@angular/core'
import { WorkspaceService } from '@app/modules/workspaces/services/workspace.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { UserRoles } from '../enums/user-roles.enum'
import { AuthService } from './auth.service'

const ROLES_CAN_ADD = {
  accountant: [],
  viewer: [],
  collaborator: [UserRoles.COLLABORATOR, UserRoles.VIEWER, UserRoles.ACCOUNTANT],
  admin: [UserRoles.ADMIN, UserRoles.COLLABORATOR, UserRoles.VIEWER, UserRoles.ACCOUNTANT],
  owner: [UserRoles.ADMIN, UserRoles.COLLABORATOR, UserRoles.VIEWER, UserRoles.ACCOUNTANT]
}
const ROLES_CAN_DELETE = {
  accountant: [],
  viewer: [],
  collaborator: [UserRoles.COLLABORATOR, UserRoles.VIEWER, UserRoles.ACCOUNTANT],
  admin: [UserRoles.COLLABORATOR, UserRoles.VIEWER, UserRoles.ACCOUNTANT],
  owner: [UserRoles.ADMIN, UserRoles.COLLABORATOR, UserRoles.VIEWER, UserRoles.ACCOUNTANT]
}

const ROLES_CAN_CHANGE = {
  accountant: [],
  viewer: [],
  collaborator: [UserRoles.COLLABORATOR, UserRoles.VIEWER, UserRoles.ACCOUNTANT],
  admin: [UserRoles.COLLABORATOR, UserRoles.VIEWER, UserRoles.ACCOUNTANT],
  owner: [UserRoles.ADMIN, UserRoles.COLLABORATOR, UserRoles.VIEWER, UserRoles.ACCOUNTANT]
}
@Injectable({
  providedIn: 'root'
})
export class WorkspaceRoleService implements OnDestroy {
  private _currentUserId: number
  private _role: UserRoles = UserRoles.VIEWER
  private _role$: Subject<string> = new Subject()
  private _destroy$: Subject<void> = new Subject()

  constructor(private auth: AuthService, private workspace: WorkspaceService) {
    this._currentUserId = this.auth.user.id
    this.auth.userChange.pipe(takeUntil(this._destroy$)).subscribe((user) => {
      if (!user) return
      this._currentUserId = user?.id
      this.updateUserRole()
    })
    this.auth.currentWorkspaceChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.updateUserRole()
    })
  }

  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }

  /**
   * Checks if user is superadmin
   * @returns boolean
   */
  isSuperAdmin(): boolean {
    return this.auth.user?.is_admin
  }

  /**
   * Gets the current user role on the workspace
   * @returns Subject<string>
   */
  getUserRole(): Subject<string> {
    return this._role$
  }

  /**
   * Updates the user role based on the current workspace
   */
  private updateUserRole() {
    if (this.isSuperAdmin()) {
      this._role = UserRoles.ADMIN
      this._role$.next(this._role)
      return
    }
    if (this.auth.currentWorkspace?.id) {
      setTimeout(() => {
        const users = this.auth.currentWorkspace.workspace_users
        const user = users?.find((user) => user?.user_id === this._currentUserId)
        this._role = user?.is_owner ? 'owner' : user?.role || null
        this._role$.next(this._role)
        if (this.auth.user?.is_admin) {
          this.auth.userRoleInCurrentWorksapce.next('admin')
        } else if (this.auth.currentWorkspace) {
          const workspaceUser = users?.find((workspaceUser) => workspaceUser.user_id === this.auth.user.id)
          this.auth.userRoleInCurrentWorksapce.next(workspaceUser?.role || null)
        } else {
          this.auth.userRoleInCurrentWorksapce.next(null)
        }
      }, 1000)
    }
  }
  /**
   * Return the current user role based on the provided workspace users list
   * @param workspaceUsers any[]
   * @returns string
   */
  getUserRoleFromWorkspaceUsers(workspaceUsers: any[]): string {
    if (this.isSuperAdmin()) return UserRoles.ADMIN
    const user = workspaceUsers.find((user) => user.user_id === this._currentUserId)
    return user.is_owner ? 'owner' : user.role
  }

  /**
   * Checks if user can edit an event
   * @returns boolean
   */
  canEditEvent(): boolean {
    return ![UserRoles.VIEWER, UserRoles.ACCOUNTANT].includes(this._role)
  }

  /**
   * Checks if user can create an event
   * @returns boolean
   */
  canCreateEvent(): boolean {
    return ![UserRoles.VIEWER, UserRoles.ACCOUNTANT].includes(this._role)
  }

  /**
   * Checks if user can add a user to a workspace
   * @returns boolean
   */
  canAddUsers(role: string = this._role): boolean {
    return ['owner', UserRoles.ADMIN, UserRoles.COLLABORATOR].includes(role)
  }

  /**
   * List of roles that the user can assign to other users
   * @param role Current user role
   * @returns boolean
   */
  rolesListToAdd(role: string = this._role): string[] {
    return ROLES_CAN_ADD[role]
  }

  /**
   * List of roles that the user can assign to other users
   * @param role Current user role
   * @returns boolean
   */
  rolesListToDelete(role: string = this._role): string[] {
    return ROLES_CAN_DELETE[role]
  }

  /**
   * List of roles that the user can change for other users
   * @param role Current user role
   * @returns boolean
   */
  rolesListToChange(role: string = this._role): string[] {
    return ROLES_CAN_CHANGE[role]
  }

  /**
   * Checks if user can view the database
   * @returns boolean
   */
  canViewDatabase(): boolean {
    return ![UserRoles.ACCOUNTANT].includes(this._role)
  }

  /**
   * Checks if user can edit the database
   * @returns boolean
   */
  canEditDatabase(): boolean {
    return ![UserRoles.ACCOUNTANT].includes(this._role)
  }

  /**
   * Checks if user can export an event's database
   * @returns boolean
   */
  canExportDatabase(): boolean {
    return ['owner', UserRoles.ADMIN].includes(this._role)
  }

  /**
   * Checks if user can export master database
   * @returns boolean
   */
  canExportMasterDatabase(): boolean {
    return ['owner', UserRoles.ADMIN].includes(this._role)
  }

  /**
   * Checks if user can add creditcard
   * @returns boolean
   */
  canAddCreditcard(): boolean {
    return ['owner', UserRoles.ADMIN, UserRoles.ACCOUNTANT, UserRoles.COLLABORATOR].includes(this._role)
  }

  /**
   * Checks if user can remove creditcard
   * @returns boolean
   */
  canRemoveCreditcard(): boolean {
    return ['owner', UserRoles.ADMIN, UserRoles.ACCOUNTANT].includes(this._role)
  }

  /**
   * Checks if user can edit subscription
   * @returns boolean
   */
  canEditSubscription(): boolean {
    return ['owner', UserRoles.ADMIN, UserRoles.ACCOUNTANT].includes(this._role)
  }
}
