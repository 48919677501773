import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { RtsMessage } from '../interfaces/rts.interface'

@Injectable()
export class RealTimeService {
  private ws: WebSocket
  public messages$: Subject<RtsMessage[]> | Subject<RtsMessage> | any
  public connected$: Subject<boolean>

  constructor() {
    this.messages$ = new Subject()
    this.connected$ = new Subject()
  }
  /**
   * Open a new websocket
   * @param connectionURI Websocket Endpoint
   */
  open(connectionURI: string) {
    if (this.ws?.url === connectionURI && this.ws.readyState <= 1) {
      return
    }
    if (this.ws) this.ws.close()
    this.connectWS(connectionURI)
  }
  /**
   * Check the connection status
   * @returns the websocket status
   */
  status() {
    return this.ws.readyState
  }
  /**
   * Refresh an established connection
   */
  refresh() {
    this.close()
    if (this.ws?.url) {
      this.messages$.next([])
      this.connectWS(this.ws?.url)
    }
  }
  /**
   * Close the current websocket
   */
  close() {
    this.ws?.close()
  }

  /**
   * Create a websocket with the provided uri
   * @param uri Websocket Endpoint
   */
  private connectWS(uri) {
    this.ws = new WebSocket(uri)
    // Update connection status
    this.ws.onopen = () => this.connected$.next(true)
    this.ws.onclose = () => this.connected$.next(false)
    // Update message
    this.ws.onmessage = (workspace) => {
      const message: RtsMessage = JSON.parse(workspace.data)
      this.checkAndPushMessage(message)
    }
  }
  /**
   * Checks if a message is duplicated and
   * updates the subject accordingly
   * @param message WS message to be checked
   */
  private checkAndPushMessage(message: RtsMessage) {
    if (message.updateType !== 'workspace') return
    this.messages$.next(message)
  }
}
