<div class="container-fluid d-flex justify-content-center confirm-email-page mt-5">
  <div class="page-content">
    <mat-card [nbSpinner]="processing" class="">
      <mat-card-title>
        <img src="https://res.cloudinary.com/contactless/image/upload/v1615103784/uploaded_public_files/zwsqiuhsrpbleaveu5dd.png" />
      </mat-card-title>
      <mat-card-content>
        <form *ngIf="!emailSent" [formGroup]="form" (ngSubmit)="send(form)" class="d-flex flex-column">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" autofocus />
            <mat-error *ngIf="form.get('email').hasError('email')">
              {{ form.get('email').getError('email') }}
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('required')"> Fill in your email address to reset your password </mat-error>
          </mat-form-field>
          <button nbButton fullWidth status="primary" class="my-2" type="submit">send</button>

          <a (click)="navigateToLogin()" class="mr-0 pt-2 forgot-text">Back to login</a>
        </form>
        <div class="confirmation" *ngIf="emailSent">
          <div class="icon">
            <svg width="224" height="177" viewBox="0 0 224 177" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M47.8382 151.104H6.86943C3.07654 151.104 0 148.05 0 144.286C0 140.521 3.07654 137.467 6.86943 137.467H47.8382C51.6311 137.467 54.7076 140.521 54.7076 144.286C54.7076 148.059 51.6311 151.104 47.8382 151.104Z"
                fill="url(#paint0_linear)"
              />
              <path
                d="M223.706 84.0161V83.9978C224.119 81.6644 224.091 79.3127 223.66 77.0338V77.0065L223.65 76.9427L223.641 76.9062L223.568 76.569C223.54 76.4231 223.503 76.2773 223.476 76.1314L223.467 76.095L223.458 76.0403V76.0129L223.448 75.9947C222.98 74.117 222.236 72.3032 221.235 70.6349L221.226 70.6167C219.371 67.5358 216.652 64.9198 213.181 63.197L90.1477 2.05301C86.667 0.321132 82.911 -0.271338 79.3018 0.111492H79.2834C77.263 0.330255 75.2794 0.849827 73.4242 1.66106H73.4058L72.8181 1.94362L72.7905 1.95273L72.7538 1.97096L72.7446 1.98008C70.6232 3.0101 68.6762 4.42299 67.014 6.17309L67.0048 6.18221C65.7466 7.51301 64.663 9.04445 63.7997 10.7579L25.3112 87.0415C20.4622 96.6488 24.3929 108.38 34.0725 113.193L157.096 174.327C166.776 179.14 178.595 175.239 183.444 165.631L221.923 89.3479C222.796 87.6251 223.384 85.8301 223.706 84.0161ZM207.506 87.4981L173.931 154.039L155.38 98.6917L207.506 87.4981ZM112.235 102.52C112.915 103.96 114.035 105.063 115.385 105.737C116.745 106.412 118.342 106.649 119.913 106.303L141.89 101.581L161.974 161.505L43.7887 102.776L103.152 83.1783L112.235 102.52ZM204.604 74.1798L122.373 91.8358L86.5389 15.4962L204.604 74.1798ZM97.3112 70.7433L39.2153 89.9307L73.975 21.0303L97.3112 70.7433Z"
                fill="url(#paint1_linear)"
              />
              <path
                d="M81.6152 177H6.86943C3.07654 177 0 173.946 0 170.182C0 166.417 3.07654 163.364 6.86943 163.364H81.6152C85.4081 163.364 88.4847 166.417 88.4847 170.182C88.4755 173.946 85.4081 177 81.6152 177Z"
                fill="url(#paint2_linear)"
              />
              <defs>
                <linearGradient id="paint0_linear" x1="0" y1="88.5" x2="217.966" y2="189.963" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#2086B1" />
                  <stop offset="1" stop-color="#1F49DF" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="0" y1="88.5" x2="217.966" y2="189.963" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#2086B1" />
                  <stop offset="1" stop-color="#1F49DF" />
                </linearGradient>
                <linearGradient id="paint2_linear" x1="0" y1="88.5" x2="217.966" y2="189.963" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#2086B1" />
                  <stop offset="1" stop-color="#1F49DF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="details">
            <div class="text">Password reset link is sent to</div>
            <div class="email">{{ form.value.email }}</div>
            <div class="end-text">Please check your inbox</div>
            <a (click)="navigateToLogin()" class="mr-0 pt-2 forgot-text">Back to login</a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
