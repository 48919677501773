import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
@Component({
  selector: 'mt-fullname',
  templateUrl: './fullname.component.html',
  styleUrls: ['./fullname.component.scss']
})
export class FullnameComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject()
  @Input()
  public label: string
  @Input()
  public control: FormControl
  constructor() {}
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((currentValue: string) => {
      const capitalizedFirstLetterRegex = /\b[a-z](?!\s)/g
      const nonLettersRegex = /[^a-zA-Z\u00C0-\u00FF\u0621-\u064A\.\s]/g
      const moreThanOneSpaceRegex = / {2,}/g
      const moreThanOneDotRegex = /\.{2,}/g
      currentValue = currentValue
        .replace(nonLettersRegex, '')
        .replace(moreThanOneSpaceRegex, ' ')
        .replace(moreThanOneDotRegex, '.')
        .replace(capitalizedFirstLetterRegex, (letter) => letter.toUpperCase())
      currentValue = currentValue.startsWith(' ') ? currentValue.trim() : currentValue
      this.control.setValue(currentValue, { emitEvent: false })
    })
  }
  formatBlur() {
    this.control.setValue(this.control.value.trim())
  }

  /**
   * Get the error message from the control
   */
  get errorMessage() {
    let error = ''
    if (!this.control.errors) {
      return error
    }
    Object.keys(this.control.errors)
      .filter((k) => this.control.errors.hasOwnProperty(k))
      .map((k) => (this.control.errors[k] === true ? k : this.control.errors[k]))
      .forEach((k) => (error += k))
    return error
  }
}
