import { Component } from '@angular/core'
/**
 * Represents the data privacy dialog
 */
@Component({
  selector: 'ngx-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.scss']
})
export class DataPrivacyComponent {}
