import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BillingPageComponent } from './components/billing-page/billing-page.component'
import { WalletComponent } from './components/wallet/wallet.component'
import { SubscriptionPlanComponent } from './components/subscription-plan/subscription-plan.component'
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component'
import { TransactionsComponent } from './components/transactions/transactions.component'
import { PaymentsRoutingModule } from './payments-routing.module'
import {
  NbIconModule,
  NbCardModule,
  NbInputModule,
  NbButtonModule,
  NbActionsModule,
  NbFormFieldModule,
  NbTooltipModule,
  NbContextMenuModule,
  NbPopoverModule,
  NbSpinnerModule,
  NbListModule
} from '@nebular/theme'
import { MethodComponent } from './components/payment-methods/components/method/method.component'
import { TopUpModalComponent } from './components/top-up-modal/top-up-modal.component'
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [BillingPageComponent, WalletComponent, SubscriptionPlanComponent, PaymentMethodsComponent, TransactionsComponent, MethodComponent, TopUpModalComponent],
  imports: [
    CommonModule,
    PaymentsRoutingModule,
    NbActionsModule,
    NbFormFieldModule,
    NbTooltipModule,
    NbContextMenuModule,
    FormsModule,
    NbPopoverModule,
    NbSpinnerModule,
    NbButtonModule,
    NbInputModule,
    NbIconModule,
    NbCardModule,
    FormsModule,
    ReactiveFormsModule,
    NbListModule
  ]
})
export class PaymentsModule {}
