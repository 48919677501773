<mat-tab-group mat-stretch-tabs class="tab-group" (selectedTabChange)="onTabChange($event)">
  <mat-tab label="Template 1">
    <div *ngIf="invitationInfo.selectedTemplate === 1">
      <ngx-first-email-template [workspaceName]="workspaceName" [invitationInfo]="invitationInfo" [event]="event" [eventTimings]="eventTimings"></ngx-first-email-template>
    </div>
  </mat-tab>
  <mat-tab label="Template 2">
    <div *ngIf="invitationInfo.selectedTemplate === 2">
      <ngx-second-email-template [workspaceName]="workspaceName" [invitationInfo]="invitationInfo" [event]="event" [eventTimings]="eventTimings"></ngx-second-email-template>
    </div>
  </mat-tab>
  <mat-tab label="Template 3">
    <div *ngIf="invitationInfo.selectedTemplate === 3">
      <ngx-invite-to-message
        [invitationInfo]="invitationInfo"
        [workspaceLogo]="workspaceLogo"
        [workspaceName]="workspaceName"
        [event]="event"
        [eventTimings]="eventTimings"
      ></ngx-invite-to-message>
    </div>
  </mat-tab>
</mat-tab-group>
