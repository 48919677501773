import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, ViewChild } from '@angular/core'
import { ISocial } from '../../../model/social-media.model'
import { Clipboard } from '@angular/cdk/clipboard'
import { environment } from '@environments/environment'
import { AuthService } from '@app/modules/auth/services/auth.service'
@Component({
  selector: 'ngx-landing-share',
  templateUrl: './landing-share.component.html',
  styleUrls: ['./landing-share.component.scss']
})
export class LandingShareComponent implements OnDestroy, AfterViewInit {
  @Input() eventSlug: string = ''
  @Input() formSlug: string = ''

  socials: ISocial[] = [
    // Details for each social media platform
    { name: 'Telegram', icon: 'https://library.micetribe.com/icons/telegram.svg', id: 'telegram' },
    { name: 'WhatsApp', icon: 'https://library.micetribe.com/icons/whatsapp.svg', id: 'whatsapp' },
    { name: 'X', icon: 'https://library.micetribe.com/icons/twitter(x).svg', id: 'twitter' },
    { name: 'Linkedin', icon: 'https://library.micetribe.com/icons/linkedin.svg', id: 'linkedin' },
    { name: 'Facebook', icon: 'https://library.micetribe.com/icons/facebook.svg', id: 'facebook' }
  ]
  formLinkStatus: string
  mirrorLinkStatus: string[] = []
  isCopying: boolean = false
  showIcons: boolean = false
  // QR code details
  qrCodeTagId = 'qrcode'
  format = 'svg'
  qrBackgroundColor = '#fff'
  private _intersectionObserver: IntersectionObserver

  // Reference to the share button element
  @ViewChild('menuId') menuElement: ElementRef
  // Listener for clicks outside of the share button, to close the social icons
  @HostListener('document:touchmove')
  closeShareDialog() {
    this.showIcons = false
  }
  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.menuElement?.nativeElement.contains(event.target)) {
      this.showIcons = false
    }
  }
  constructor(private clipboard: Clipboard, private auth: AuthService) {}

  ngAfterViewInit(): void {
    let options = {
      rootMargin: '0px',
      threshold: 1.0
    }
    this._intersectionObserver = new IntersectionObserver(() => (this.showIcons = false), options)
    if (document.querySelector('ngx-landing-previewer')) {
      this._intersectionObserver.observe(document.querySelector('ngx-landing-previewer'))
    }
  }

  ngOnDestroy(): void {
    this._intersectionObserver.disconnect()
  }

  /**
   * @param event Mouse event
   */
  showShareIcons(event: MouseEvent) {
    if (event.target === this.menuElement.nativeElement || event.target instanceof HTMLImageElement || event.target instanceof HTMLSpanElement) {
      this.showIcons = !this.showIcons // Toggle the showIcons property
    }
    event.stopPropagation()
  }

  /**
   * Copy code and toggle status display
   * @param value string
   * @param index number
   * @returns void
   */
  copyCode(value?: string, index?: number): void {
    if (this.isCopying) return
    this.isCopying = true
    this.formLinkStatus = 'Copied to Clipboard'
    this.clipboard.copy(this.formLink)
    setTimeout(() => {
      this.formLinkStatus = undefined
      this.isCopying = false
      window.open(this.formLink, '_blank')
    }, 500)
  }

  /**
   * Get form link
   * @returns string
   */
  get formLink(): string {
    return `${environment.landingPageUrl}workspaces/${this.auth.currentWorkspace.slug}/events/${this.eventSlug}/forms/${this.formSlug}?focus=false`
  }
}
