<div class="container-fluid workspace-item">
  <div class="text-hint text-center my-5" *ngIf="mode === 'informative' && workspace">
    <h6 class="text-hint">Your event is hosted under</h6>
    <br />
    <h2 class="my-2">{{ workspace['name'] }}</h2>
    <br />
    <h5 class="text-hint">
      <strong>{{ workspace['slug'] }}</strong>
    </h5>
  </div>
  <div *ngIf="mode === 'regular' && workspace">
    <nb-card [attr.id]="workspace['slug']">
      <nb-card-header>
        <div class="workspace-header" (click)="navigateEvents()">
          <div class="title d-flex flex-row">
            <div class="d-flex workspace-logo" (click)="uploadLogo($event)">
              <img class="" [src]="logo" alt="" (error)="logoHasError = true" />
            </div>
            <div class="d-flex flex-column flex-grow-1">
              <h2>
                <ngx-plan-badge *ngIf="isAdmin || showPlan" [workspace]="workspace"></ngx-plan-badge>
                {{ workspace.name }}
              </h2>
              <span class="acronym">Acronym: {{ workspace.slug }}</span>
              <h6 class="slug" *ngIf="workspace['parent'] && workspace.parent_workspace">
                <span>Parent: </span><span> {{ workspace['parent_workspace'].name }}</span>
              </h6>
            </div>
            <div class="icons d-flex justify-content-around flex-row">
              <div class="float-right" *ngIf="!noActions">
                <span class="users" (click)="open(dialog, $event)">
                  <mat-icon>groups</mat-icon>
                </span>
                <span class="edit-workspace" (click)="toggleEditing($event)">
                  <mat-icon>settings</mat-icon>
                </span>
              </div>
            </div>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body *ngIf="editing">
        <ngx-workspaces-create [workspace]="workspace" [edit]="true" (updated)="updateWorkspace($event)"> </ngx-workspaces-create>
      </nb-card-body>
      <nb-card-footer (click)="navigateEvents()">
        <div class="container-fluid">
          <div class="row workspace-body">
            <div class="col-12 role-title">
              <button nbButton shape="round" size="medium" status="info" [ngClass]="{ organizer: role === 'Sub Entity' }">
                {{ role }}
              </button>
            </div>
          </div>
          <div class="row workspace-body">
            <div class="col-6">
              <div class="workspaces-limit" *ngIf="!!enterprise">
                <span class="icon">
                  <mat-icon>cases</mat-icon>
                </span>
                <span>{{ workspace.available_workspaces_count }}</span>

                <span>workspaces</span>
              </div>
              <div class="events-limit">
                <span class="icon">
                  <mat-icon>calendar_today</mat-icon>
                </span>
                <span>{{ workspace.events_count }}</span>
                <span *ngIf="workspace.max_events_num && workspace.max_events_num && isAdmin">/</span>
                <span *ngIf="workspace.max_events_num && isAdmin">{{ workspace.max_events_num ? workspace.max_events_num : '0' }}</span>
                <span>events</span>
              </div>
            </div>

            <div class="col-6">
              <div class="important-dates float-right">
                <div class="creation-date">
                  <span class="title">Last used: </span>
                  <span class="date">{{ ' ' + workspace.last_use_diff_to_human }}</span>
                </div>
                <div class="used-date">
                  <span class="title">Created at:</span>
                  <span class="date">{{ ' ' + createdAt() }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  </div>
</div>
<ng-template #dialog let-data let-ref="dialogRef">
  <ngx-workspaces-invoice-plans [createWorkspace]="false" [assignUser]="true" (close)="ref.close()"></ngx-workspaces-invoice-plans>
</ng-template>
