<mat-form-field appearance="outline" class="country-field" [ngClass]="{ empty: !control.value }">
  <mat-label [innerHtml]="label"></mat-label>
  <img matPrefix class="flag align-top" aria-hidden [src]="flag" />
  <input matInput type="text" [formControl]="control" autocomplete="disabled" [matAutocomplete]="auto" #country />

  <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true">
    <mat-option *ngFor="let option of countries | async" [value]="option.name">
      <img class="flag" aria-hidden [src]="option.flag" height="15" />
      <span>{{ option.name }}</span>
    </mat-option>
  </mat-autocomplete>
  <mat-error>
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
