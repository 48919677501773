import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { forkJoin, Subject } from 'rxjs'
import { BillingService } from '@payments/services/billing.service'
import { takeUntil } from 'rxjs/operators'
/**
 * Workspace billing main component
 */
@Component({
  selector: 'ngx-billing-page',
  templateUrl: './billing-page.component.html',
  styleUrls: ['./billing-page.component.scss']
})
export class BillingPageComponent implements OnInit, OnDestroy {
  /**
   * Is the component loaded
   */
  public loaded = false
  /**
   * Customer model
   */
  public customer = null
  /**
   * Payment methods list
   */
  public paymentMethods = null
  /**
   * Workspace name
   */
  public workspaceName = null
  /**
   * Destroy subject
   */
  private _destroy$ = new Subject()
  /**
   * Creates the component and injects it's dependencies
   * @param router Router
   * @param service BillingService
   */
  constructor(private router: Router, private service: BillingService) {}

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.initialize()
    this.service.reloadBillingDetails$.pipe(takeUntil(this._destroy$)).subscribe((reload) => {
      this.loaded = false
      this.initialize()
    })
  }

  /**
   * On destroy implementation
   */
  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  /**
   * Gets the customer, payment methods and transactions
   */
  initialize() {
    setTimeout(() => {
      forkJoin([this.service.customer(), this.service.paymentMethods()])
        .pipe(takeUntil(this._destroy$))
        .subscribe((result) => {
          this.customer = result[0]
          this.paymentMethods = result[1]
          this.loaded = true
        })
    }, 3000)
    this.workspaceName = this.service.workspace.name
    this.loadStripe()
  }

  private loadStripe() {
    if (document.querySelector('script[data-payment="stripe"]')) return
    const script = document.createElement('script')
    script.setAttribute('src', 'https://js.stripe.com/v3/')
    script.dataset['payment'] = 'stripe'
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  /**
   * Navigates to workspace list page
   */
  navigate() {
    this.router.navigate(['workspaces', 'list'])
  }
}
