<header [ngStyle]="{ height: !isLandingPage ? '264.64px' : '', 'padding-bottom': event?.cover_page? '6.5rem' : '' }">
  <div id="cover_page" class="header-preview d-flex justify-content-between">
    <img class="img-cover" [src]="event?.cover_page" alt="">
    <ngx-landing-timer  *ngIf="!isInvitation" [isLandingPage]="isLandingPage" [startDate]="event.public_start_date" [endDate]="event.public_end_date"></ngx-landing-timer>
    <ngx-landing-share
    *ngIf="isLandingPage" [formSlug]="persona.slug" [eventSlug]="event.slug"></ngx-landing-share> 
    <ngx-event-times *ngIf="!isLandingPage && !isInvitation" [startDate]="event.public_start_date"
      [endDate]="event.public_end_date"></ngx-event-times>
  </div>
  <div id="header-info" class="header-info d-flex" [ngClass]="{ 'align-items-center': isLandingPage, 'align-items-end': isInvitation }">
    <div class="profile-pic-container" *ngIf="event?.logo">
      <img [src]="event.logo" [alt]="event.name" />
    </div>
    <div class="header-description d-flex justify-content-between w-100" [ngClass]="{ 'flex-column': isInvitation }">
      <h2 [title]="event.name" [ngClass]="{ 'mt-4': !isLandingPage, 'mb-0': isInvitation && socials.length > 0 }">{{
        event.name }}</h2>
      <div class="links" *ngIf="(socials.length && isLandingPage) || isInvitation">
        <ul class="d-flex" [ngStyle]="{ margin: isInvitation ? '0' : 'initial' }">
          <ng-container *ngFor="let social of socials">
            <li>
              <a [href]="social.link" target="_blank" class="d-inline-block">
                <img [src]="social.icon" [alt]="social.name" class="w-100 h-100" [title]="social.name" />
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</header>