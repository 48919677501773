<ngx-workspaces-select
  *ngIf="!!workspaces"
  mode="cards"
  label="Which workspace are you working on?"
  labelAlignment="center"
  (selectionChange)="workspace = $event"
  (loadMore)="refresh()"
  [noMoreWorkspaces]="noMoreWorkspaces"
  [items]="workspaces"
></ngx-workspaces-select>
