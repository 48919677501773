import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '@auth/services/auth.service'

/**
 * Allows/ denies access to authenticated routes
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  /**
   * Allows user navigation based on their login state or redirects the user to the login page
   * @param next next route
   * @param state current route state
   * @returns boolean
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (window.location.href.includes('https://plan.micetribe.com/event-landing/event/2424/form/6587')) {
      window.location.href = 'https://app.micetribe.com/register/workspaces/cis/events/cis4638/forms/attendees'
      return
    }

    if (!this.authService.isLoggedIn) {
      this.router.navigate(['auth/login'])
    }
    return true
  }
  /**
   * Creates the guard and injects it's dependencies
   * @param router Router
   * @param authService AuthService
   */
  constructor(private router: Router, private authService: AuthService) {}
}
