<div class="transaction" *ngIf="data && data.data && data.data.length">
  <div class="transaction-table table-bordered">
    <div class="transaction-header row m-0">
      <div class="col-2">Date</div>
      <div class="col-5">Description</div>
      <div class="col-1">Debit</div>
      <div class="col-1">Credit</div>
      <div class="col-1">Balance</div>
      <div class="col-2">Details</div>
    </div>
    <nb-list nbInfiniteList listenWindowScroll [threshold]="100" (bottomThreshold)="loadNext()" class="transaction-body">
      <nb-list-item *ngFor="let transaction of data.data" class="transaction-item">
        <div class="row w-100">
          <div class="col-2">{{ transaction.date }}</div>
          <div class="col-5">{{ transaction.description }}</div>
          <div class="col-1">${{ transaction.debit }}</div>
          <div class="col-1">${{ transaction.credit }}</div>
          <div class="col-1">${{ transaction.balance }}</div>
          <div class="col-2 btn">
            <a [href]="transaction.url" target="_blank" *ngIf="!!transaction.url"> download receipt </a>
            <a (click)="manageSubscribtion()" target="_blank" *ngIf="!transaction.url && canEditSubscription()"> Manage Subscription </a>
          </div>
        </div>
      </nb-list-item>
    </nb-list>
  </div>
</div>
