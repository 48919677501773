import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router'
import { Observable, of } from 'rxjs'
import { AuthService } from '@auth/services/auth.service'
import { WorkspaceRoleService } from '@app/modules/auth/services/workspace-role.service'
import { UserRoles } from '@app/modules/auth/enums/user-roles.enum'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivateChild {
  private _currentRole
  /**
   * Creates the guard and injects it's dependencies
   * @param router Router
   * @param authService AuthService
   * @param workspaceRole WorkspaceRoleService
   */
  constructor(private router: Router, private authService: AuthService, private workspaceRole: WorkspaceRoleService) {
    this.authService.currentWorkspaceChange.subscribe(() => (this._currentRole = null))
  }

  /**
   * Checks if the user has the required role to access the route
   * @param childRoute ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   * @returns Observable<boolean | UrlTree>
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (!this.authService.isLoggedIn) return of(this.router.parseUrl('/auth/login'))
    if (this.authService.user?.is_admin) return of(true)
    if (this._currentRole) return of(this.makeRoutingDecision())

    return this.workspaceRole.getUserRole().pipe(
      map((role) => {
        this._currentRole = role
        return this.makeRoutingDecision()
      })
    )
  }

  /**
   * Makes the routing decision based on the current user role
   * @returns boolean | UrlTree
   */
  private makeRoutingDecision(): boolean | UrlTree {
    return this._currentRole !== UserRoles.ACCOUNTANT ? true : this.router.parseUrl('/billing')
  }
}
