import { Component } from '@angular/core'
import { NbDialogRef } from '@nebular/theme'
import { AuthService } from '@auth/services/auth.service'

/**
 * A dialog that informs the user that we have a newer application system, refreshes the page
 */
@Component({
  selector: 'ngx-new-version-modal',
  templateUrl: './new-version-modal.component.html',
  styleUrls: ['./new-version-modal.component.scss']
})
export class NewVersionModalComponent {
  /**
   * Creates the component and inject it's dependencies
   * @param dialogRef NbDialogRef
   * @param auth AuthService
   */
  constructor(private dialogRef: NbDialogRef<NewVersionModalComponent>, private auth: AuthService) {}

  /**
   * Closes the dialog
   */
  close() {
    this.dialogRef.close()
  }
  /**
   * Closes the dialog & forces the browser to refresh
   */
  hardReloadAndClose() {
    const location = window.location.href
    window.location.href = (location.includes('?version=') ? location.substring(0, location.indexOf('?')) : location) + '?version=' + this.auth._version
    this.dialogRef.close()
  }
}
