import { Component, Input, OnInit } from '@angular/core'
import { InvitationInfo } from '@app/modules/invite/models/invitation-info.interface'
import { environment } from '@environments/environment'

@Component({
  selector: 'ngx-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {
  @Input() height: number = 500
  @Input() showMenuBar: boolean = false
  @Input() border: boolean = false
  @Input() editorPadding: boolean = false
  @Input() placeholder: string = ''
  @Input() whiteBackground: boolean = true
  @Input() template: boolean = false
  @Input() containerHeight: number = 130
  @Input() blackPlaceholder: boolean = false
  isLoaded: boolean = false
  public editorAPIKey: string

  tinyMCEInit: any = {
    inline: true,
    content_style: 'p { margin: 0; } ul { padding: 0 40px; }',
    inline_styles: true,
    height: this.height,
    menubar: this.showMenuBar,
    plugins: [
      'directionality',
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks fullscreen',
      'insertdatetime table paste help wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | ltr rtl | \
        bullist numlist outdent indent | removeformat | help',
    fixed_toolbar_container: '#toolbar',
    paste_block_drop: true,
    placeholder: ''
  }
  /**
   * Invitation info model
   */
  @Input()
  invitationInfo: InvitationInfo

  message: string
  constructor() {}

  ngOnInit(): void {
    this.editorAPIKey = environment.tinyMCEApiKey
    setTimeout(() => {
      this.isLoaded = true
      this.tinyMCEInit.placeholder = this.placeholder
    }, 1)
  }

  /**
   * Updates the message with selected value
   * @param value string
   */
  update(value: string) {
    this.message = value
  }

  /**
   * Update message value and remove non-printable characters on blur
   */
  updateMessage() {
    if (this.message) {
      this.message = this.message.replace(/[\x00-\x09\x0B-\x0C\x0E-\x1F\x7F\xA0]/gmu, '').trim()
    }
    if (this.invitationInfo) this.invitationInfo.message = this.message
  }
}
