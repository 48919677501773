import { Component, Input, OnInit } from '@angular/core'
import { ServerRepresentedEvent } from '@app/modules/events/models/event.model'
import { ISocial } from '../../../model/social-media.model'
import { Persona } from '@app/modules/personas/interfaces/persona.interface'

@Component({
  selector: 'ngx-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements OnInit {
  @Input() event: ServerRepresentedEvent
  @Input() persona: Persona
  @Input() isLandingPage: boolean = true
  @Input() isInvitation: boolean = false
  socials: ISocial[] = []
  linksOrder: { name: string; id: string }[] = [
    { name: 'Facebook', id: '1' },
    { name: 'Linkedin', id: '2' },
    { name: 'Instagram', id: '3' },
    { name: 'Messenger', id: '4' },
    { name: 'X', id: '5' },
    { name: 'Whatsapp', id: '6' },
    { name: 'Youtube', id: '7' },
    { name: 'Website', id: '8' }
  ]

  constructor() { }

  ngOnInit(): void {
    this.socials = Object.entries(this.event.social_media)
      .filter((s: any[]) => !s[0].includes('snapchat'))
      .map(([name, link]) => {
        let linkName = name.replace('Account', '').replace('_account', '')
        linkName = linkName.toLowerCase() === 'twitter' ? 'x' : linkName
        const orderObj = this.linksOrder.find((obj) => obj.name.toLowerCase() === linkName)
        return {
          id: orderObj.id,
          icon: `https://library.micetribe.com/icons/${linkName}-black.svg`,
          name: orderObj.name,
          link
        }
      })
      .sort((a, b) => (a.id > b.id ? 1 : -1))
  }


  /**
   * Gets cover-page photo for landing-header
   * @returns string
   */
  get getCoverPhoto(): string {
    return this.event?.cover_page ? `url(${this.event?.cover_page})` : `url(https://library.micetribe.com/icons/default_cover.svg)`
  }
}
