import { Component, Output, EventEmitter, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core'
import { NbDialogService } from '@nebular/theme'
import { AuthService } from '@auth/services/auth.service'
import { Workspace } from '@workspaces/interfaces/workspace.interface'
import { InvoicePlansComponent } from '@auth/components/invoice-plans/invoice-plans.component'
import { SearchComponent } from '../search/search.component'
import { WorkspaceService } from '../../services/workspace.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

/**
 * Workspace list header
 */
@Component({
  selector: 'ngx-workspaces-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject()

  /**
   * Header component view child
   */
  @ViewChild(SearchComponent)
  private searchViewChild: SearchComponent
  /**
   * User role in current worksapce
   */
  currentUseRoleInCurrentWorksapce = ''
  /**
   * Creates the component and injects it's dependencies
   * @param authService AuthService
   * @param dialogService NbDialogService
   * @param workspaceService WorkspaceService
   */
  constructor(public authService: AuthService, private dialogService: NbDialogService, private workspaceService: WorkspaceService) {}

  ngOnInit(): void {
    this.authService.userRoleInCurrentWorksapce.pipe(takeUntil(this._destroy$)).subscribe((role) => (this.currentUseRoleInCurrentWorksapce = role))
  }
  /**
   * Workspace changed event emitter
   */
  @Output()
  workspacesChanged: EventEmitter<Workspace[]> = new EventEmitter<Workspace[]>()
  /**
   * Indicator to show no more workspaces
   */
  @Output()
  noMoreWorkspaces: EventEmitter<boolean> = new EventEmitter<boolean>()
  /**
   * Workspace role
   */
  role() {
    return this.authService.role
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  /**
   * Emits that workspace list changed
   * @param workspaces Workspace[]
   */
  propagateWorkspaces(workspaces: Workspace[]) {
    this.workspacesChanged.emit(workspaces)
  }

  /**
   * Opens master export dialog
   * @param dialog2 TemplateRef
   */
  openExportModel(dialog2: TemplateRef<any>) {
    this.dialogService.open(dialog2)
  }

  /**
   * Opens a dialog
   * @param dialog any
   */
  open(dialog) {
    if (this.authService.role === 'Admin' || this.authService.plan === 'Pro') {
      this.dialogService.open(dialog)
      return
    }
    this.dialogService.open(InvoicePlansComponent)
  }
  /**
   * Loads next page of workspaces
   * @returns void
   */
  loadNext() {
    this.searchViewChild.refresh()
  }
  /**
   * Emits true when no more workspaces
   */
  showNoMore(noMore: boolean) {
    this.noMoreWorkspaces.emit(noMore)
  }
}
