import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Workspace } from '@workspaces/interfaces/workspace.interface'

/**
 * Component that handles workspace editing
 */
@Component({
  selector: 'ngx-edit-workspace',
  templateUrl: './edit-workspace.component.html',
  styleUrls: ['./edit-workspace.component.scss']
})
export class EditWorkspaceComponent implements OnInit {
  /**
   * Workspace model
   */
  @Input()
  workspace: Workspace = null

  /**
   * Workspace updated event
   */
  @Output()
  workspaceChange: EventEmitter<Workspace> = new EventEmitter<Workspace>()

  /**
   * Form group that represents a workspace
   */
  form: FormGroup

  /**
   * Performing an async operation
   */
  processing = false

  /**
   * Creates the component and injects it's dependencies
   * @param fb FormBuilder
   */
  constructor(private fb: FormBuilder) {}

  /**
   * Initializes the component
   */
  ngOnInit() {
    this.form = this.fb.group({
      id: [this.workspace.id],
      name: [this.workspace.name, Validators.required],
      slug: [{ value: this.workspace.slug, disabled: true }, [Validators.required]],
      financialDashboardAccessKey: [1],
      marketingDashboardAccessKey: [1]
    })
  }

  /**
   * Updates the workspace by emitting an event that a parent component will handle
   */
  update() {
    if (this.form.valid) {
      this.processing = true
      const value = this.form.value
      this.workspaceChange.emit(value)
    }
  }
}
