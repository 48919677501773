import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core'
import { ExportStatus } from '@workspaces/models/export-status.interface'
import { WorkspaceService } from '@workspaces/services/workspace.service'
import { NbDialogRef } from '@nebular/theme'
import { expand, tap, concatMap, takeUntil } from 'rxjs/operators'
import { Subject, timer } from 'rxjs'

/**
 * Performs a master export of an entire workspace
 */
@Component({
  selector: 'ngx-master-export',
  templateUrl: './master-export.component.html',
  styleUrls: ['./master-export.component.scss']
})
export class MasterExportComponent implements OnInit, OnDestroy {
  /** Subject that stops all hot observables */
  private _destroy$ = new Subject()
  /** On destroy implementation */
  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.complete()
  }
  /**
   * Component ready
   */
  ready = false

  /**
   * Performing async operation
   */
  processing = false

  /**
   * Currently exporting
   */
  exporting = false

  /**
   * Export status
   */
  exportStatus: ExportStatus

  /**
   * User closed the dialog
   */
  @Output()
  closeRequested: EventEmitter<void> = new EventEmitter()
  /**
   * Creates the component and injects it's dependencies
   * @param workspaceService WorkspaceService
   * @param ref NbDialogRef
   */
  constructor(private workspaceService: WorkspaceService, protected ref: NbDialogRef<MasterExportComponent>) {}

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.processing = true
    this.workspaceService
      .checkExportProgress()
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (exportStatus: ExportStatus) => {
          this.exportStatus = exportStatus
          if (exportStatus.status === 'running') {
            this.exporting = true
            this.ready = true
            this.processing = false
            this.autoCheckProgress()
          } else {
            this.ready = true
            this.processing = false
          }
        },
        () => {
          this.ready = true
          this.processing = false
        }
      )
  }

  /**
   * Triggers an auto pooling export progress check api
   */
  autoCheckProgress() {
    const exportProgress$ = this.workspaceService.checkExportProgress()
    const exportProgressPool$ = exportProgress$
      .pipe(
        expand((_) =>
          timer(3000).pipe(
            tap(() => (this.processing = true)),
            concatMap(() => exportProgress$)
          )
        )
      )
      .pipe(takeUntil(this._destroy$))
      .subscribe((exportStatus: ExportStatus) => {
        this.exportStatus = exportStatus

        if (exportStatus.status === 'running') {
          this.exporting = true
          this.processing = false
        } else {
          exportProgressPool$.unsubscribe()
          this.processing = false
        }
      })
  }
  /**
   * Closes this dialog
   */
  close() {
    this.ref.close()
  }

  /**
   * Begins exporting the workspace
   */
  export() {
    this.workspaceService
      .export()
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this.autoCheckProgress()
          this.processing = false
        },
        () => (this.processing = false)
      )
  }
}
