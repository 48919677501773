import { Directive, ElementRef, HostListener } from '@angular/core'

/**
 * Directive that allows certain keys to be used as phone numbers
 */
@Directive({
  selector: '[ngxPhoneNumberInput]'
})
export class PhoneNumberInputDirective {
  /**
   * Creates the directed and injects it's dependencies
   * @param el Associated element reference
   */
  constructor(private el: ElementRef) {}

  /**
   * On keydown event handler
   * @param event KeyboardEvent
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    const element = this.el.nativeElement as HTMLInputElement
    const isKeyboardTypes = event.ctrlKey || event.altKey || event.key.length > 1
    const startWithCountryCode = element.value.length === 0 && event.key === '+'
    const onlyNumbers = RegExp('[0-9-]').test(event.key)
    const reachedMaximum = element.value.length <= 15
    if (isKeyboardTypes || ((startWithCountryCode || onlyNumbers) && reachedMaximum)) {
    } else {
      event.preventDefault()
    }
  }
}
